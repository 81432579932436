import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../store/hooks";
import { setModal } from "../../store/slice/modalSlice";
import { modalBaseStyles } from "./RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { notify } from "../../utils/Notify";
import { useReactivateAccount } from "../../api/Auth/Reactivate.api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface Props {
  show: boolean;
  user_name: string;
  onClose: () => void;
}

const ReactivateProfile = ({ show, user_name, onClose }: Props) => {
  const profileStatus = sessionStorage.getItem("isProfileUser");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mutate, isSuccess } = useReactivateAccount();

  const handleOK = () => {
    const formData = new FormData();
    formData.append("username_in", user_name);

    mutate({ body: formData });
  };

  const handleShowLogin = () => {
    dispatch(
      setModal({
        modalType: "SHOW_LOGIN",
        modalProps: {
          open: true,
          isProfileUser: profileStatus,
        },
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      notify("success", "Account Reactivated");
      navigate("/initialSearch");
      handleShowLogin();
    }
  }, [isSuccess, navigate, profileStatus]);

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="ReactivateProfile-Title"
      aria-describedby="ReactivateProfile-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "420px",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            Account is Deactivated. Do you want to Reactivate the Account?
          </Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button variant="contained" color="error" sx={{}} onClick={handleOK}>
            Yes, Reactivate
          </Button>
          <Button variant="outlined" onClick={onClose} sx={{}}>
            No, cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReactivateProfile;
