import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { removeArticleFromFolder } from "../../../api/MyLibrary/MyFolders.api";
import { notify } from "../../../utils/Notify";
import { queryClient } from "../../../App";

interface Props {
  show: boolean;
  article_id: string;
  folderId: number;
}

const FolderArticleDelete = ({ show, article_id, folderId }: Props) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append("articleId", article_id);
    formData.append("folderid", folderId?.toString());

    const res = await removeArticleFromFolder(formData);
    notify("success", res[0][0]);
    queryClient.invalidateQueries(["GetFolderArticles"]);
    dispatch(hideModal());
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="FolderArticleDelete-Title"
      aria-describedby="FolderArticleDelete-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "420px",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            Do you want to delete this article?
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{}}
            onClick={handleDelete}
          >
            Yes Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{}}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FolderArticleDelete;
