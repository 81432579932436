import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import IconButton from "@mui/material/IconButton";
import StarOutlineIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import RSSIcon from "@mui/icons-material/RssFeed";
import TextField from "@mui/material/TextField";
import Text from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { alertStyles as sx } from "./Alerts.style";
import { Button, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { alphabeticalSort } from "../../../utils/helper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAddTOC } from "./AddTOC.hook";

export default function AddTOC() {
  const {
    navigate,
    search,
    tocData,
    hitCount,
    pageSize,
    loading,
    resCount,
    setPage,
    handleOnSearchChange,
    handleFav,
    handleAlert,
    handleSearch,
    handleClear,
    handlePerPage,
  } = useAddTOC();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <TextField
          sx={sx.search}
          value={search}
          onChange={handleOnSearchChange}
          placeholder="Search Journal Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={handleClear}>
                  {search && <CloseIcon fontSize="inherit" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
        />
        <Button
          variant="contained"
          sx={{ height: "56px", width: "130px", flex: "130px 0 1" }}
          onClick={handleSearch}
        >
          Search
        </Button>
        {!loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              ml: "auto",
              my: 2,
            }}
          >
            <Text sx={{ fontSize: "1.1rem" }}>
              Active Alerts: <strong>{resCount?.alert_count}</strong>
            </Text>
            <Text sx={{ fontSize: "1.1rem", mt: 1 }}>
              Favorites: <strong>{resCount?.favorite_set_count}</strong>
            </Text>
          </Box>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alphabeticalSort(tocData, "resource_name")?.map((row: any) => (
              <TableRow
                key={row.resourcemaster_id}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    ...sx.addManageCell,
                  }}
                >
                  <Text
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                        cursor: "pointer",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/BrowseJournalTableSearch/BrowseJournalSearch`,
                        {
                          state: {
                            item: row,
                          },
                        }
                      )
                    }
                  >
                    {row.resource_name}
                  </Text>

                  <Box>
                    <Tooltip
                      title={
                        row.notification_set ? "Modify Alert" : "Set Alert"
                      }
                    >
                      <IconButton
                        onClick={() => handleAlert(row)}
                        aria-label="rss"
                        sx={{
                          color: row.notification_set ? "#2E8B57" : "#6E6E6F",
                        }}
                      >
                        <RSSIcon />
                      </IconButton>
                    </Tooltip>

                    <IconButton
                      onClick={() => handleFav(row, row)}
                      aria-label="favorite"
                      sx={{
                        color: row.favorite_set ? "#FACA15" : "#6E6E6F",
                      }}
                    >
                      {row.favorite_set ? <StarIcon /> : <StarOutlineIcon />}
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={hitCount}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={(_, p) => setPage(p)}
        />
        <Select
          id="pageSize"
          value={pageSize.toString()}
          displayEmpty
          autoWidth
          onChange={handlePerPage}
          sx={{
            height: "32px",
            ml: 2,
          }}
          MenuProps={{
            PaperProps: {
              style: {
                transform: "translateY(-81px)",
              },
            },
          }}
        >
          <MenuItem value={15}>15 / Page</MenuItem>
          <MenuItem value={25}>25 / Page</MenuItem>
          <MenuItem value={50}>50 / Page</MenuItem>
          <MenuItem value={100}>100 / Page</MenuItem>
        </Select>
      </Box>
    </>
  );
}
