import axios from "axios";
import api from "./endpoints";
export async function getAllPackages(customerId: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/get_browse_package_list/get_browse_package_list?${customerId}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}
