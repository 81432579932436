import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Button, Divider } from "@mui/material";
import { Colors } from "../../utils/constants";
import FilterSubject from "../../components/filterPopups/ModalTwoOfAuthor";

import FilterUniversity from "../../components/filterPopups/ModalTwoOfAuthor";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { getParamsByName, goToTop } from "../../utils/helper";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllFilters,
  updateInstitutionFilter,
  updateSubjectFilter,
  updateAuthorSearchResults,
  updateAuthorPage,
} from "../../store/slice/authorFinderSlice";
import { useAppSelector } from "../../store/hooks";
import { updateLoadingState } from "../../store/slice/filterSlice";
import { authorFinderApi } from "../../api/authorFinderApi";
import { queryConstructor } from "../../api/formQuery";
import { getAllSubjects } from "../../api/subject";
import { AuthorFinderObj, ReducerObj } from "../../types/interface";

// Types

type YearType = { val: string; count: string };

interface PropType {
  yearFromData?: YearType[];
  jsonFacets?: any;
  // authors?: any;
}

interface ObjType {
  [key: string]: any;
}

const Filter = (props: PropType) => {
  // Props
  const { yearFromData, jsonFacets } = props;
  const dispatch = useDispatch();
  const searchTerm = getParamsByName("searchterm");

  const selectedFilters = useAppSelector((state) => state.authorReducer);

  const [filterSubjectModalStatus, setFilterSubjectModalStatus] =
    useState(false);
  const [filterYearModalStatus, setFilterYearModalStatus] = useState(false);

  const [filterUnivModalStatus, setFilterUnivModalStatus] = useState(false);

  const [triggerCount, setTriggerCount] = useState<number>(0);

  const [subjects, setSubjects] = useState([]);

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );

  useEffect(() => {
    if (triggerCount) applySearch();
  }, [triggerCount]);

  useEffect(() => {
    updateSubjects();
  }, []);

  async function updateSubjects() {
    try {
      const data = await getAllSubjects();

      let newArr: any = [];
      let subjectFacet = jsonFacets.subjects_name_l3?.buckets;
      for (let obj of subjectFacet) {
        let subjectName: any = {};
        data?.map((subject: any) => {
          subject.subject2_details?.map((e: any) => {
            if (parseInt(e.subject_id2) === obj?.val) {
              subjectName = {
                id: e.subject_id2,
                val: e.subject_name2,
                count: obj?.count,
              };
              newArr.push(subjectName);
            }
          });
        });
      }

      setSubjects(subjectFacet);
    } catch (e) {
      console.log(e);
    }
  }

  // Handle Filter subject modal status changes
  const handleChangeFilterSubject = () => {
    setFilterSubjectModalStatus(false);
  };

  // Handle Filter univ modal status changes
  const handleChangeFilterUniv = () => {
    setFilterUnivModalStatus(false);
  };

  // Get subject values
  const getSubjectTypeValues = (data: string[]) => {
    let subIds = findSubjectIds(data);
    Array.isArray(data) && dispatch(updateSubjectFilter(data));
  };

  // Get univ values
  const getUnivTypeValues = (data: string[]) => {
    dispatch(updateInstitutionFilter(data));
  };

  // function apply filter
  async function applyFilter() {
    if (!searchTerm) return;
    setTriggerCount((prev) => prev + 1);

    goToTop();
  }

  async function applySearch() {
    dispatch(updateLoadingState(true));
    let queryObj: ObjType = {};

    let selectedSubjectFilter: string[] = selectedFilters.subjectFilter.map(
      (subject: any) => subject.replace(/&/g, "%26")
    );

    if (authorReducer.firstname === "") {
      if (selectedFilters.subjectFilter.length) {
        //queryObj.subjects_name_l3 = selectedFilters.subjectFilter;
        queryObj.fq__subjects_name_l3 = selectedSubjectFilter;
      }
      if (selectedFilters.institutions.length) {
        queryObj.author_affiliation = selectedFilters.institutions;
      }
      if (selectedFilters?.lastname) {
        // queryObj.author_firstname_fz = selectedFilters.authorname.replace(
        //   " ",
        //   ""
        // );
        //dispatch(updateAuthorPage(authorReducer.page))
        queryObj.author_lastname = `("${selectedFilters.lastname
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = searchReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_affiliation = selectedFilters.institutions;
        queryObj.page = 1;
        //queryObj.sort = 'author_firstname_fz asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";
        // queryObj.page = authorReducer.page
      }

      let query = await queryConstructor(queryObj);

      const data = await authorFinderApi(query);
      dispatch(updateAuthorSearchResults(data));
      dispatch(updateLoadingState(false));
    } else if (authorReducer.lastname === "") {
      if (selectedFilters.subjectFilter.length) {
        //queryObj.subjects_name_l3 = selectedFilters.subjectFilter;
        queryObj.fq__subjects_name_l3 = selectedSubjectFilter;
      }
      if (selectedFilters.institutions.length) {
        queryObj.author_affiliation = selectedFilters.institutions;
      }
      if (selectedFilters?.firstname) {
        // queryObj.author_firstname_fz = selectedFilters.authorname.replace(
        //   " ",
        //   ""
        // );
        // `("${authorReducer.firstname}")`
        queryObj.author_firstname_fz = `("${selectedFilters.firstname
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = searchReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_affiliation = selectedFilters.institutions;
        queryObj.page = 1;
        //queryObj.sort = 'author_firstname_fz asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";
      }

      let query = await queryConstructor(queryObj);

      const data = await authorFinderApi(query);
      dispatch(updateAuthorSearchResults(data));
      dispatch(updateLoadingState(false));
    } else {
      if (selectedFilters.subjectFilter.length) {
        //queryObj.subjects_name_l3 = selectedFilters.subjectFilter;
        queryObj.fq__subjects_name_l3 = selectedSubjectFilter;
      }
      if (selectedFilters.institutions.length) {
        queryObj.author_affiliation = selectedFilters.institutions;
      }
      if (selectedFilters?.firstname) {
        // queryObj.author_firstname_fz = selectedFilters.authorname.replace(
        //   " ",
        //   ""
        // );
        queryObj.author_firstname_fz = `("${selectedFilters.firstname
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.author_lastname_fz = `("${selectedFilters.lastname
          ?.toString()
          ?.replaceAll('"', "")}")`;
        queryObj.fq__resourcetype_id = searchReducer.dataType;
        queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
        queryObj.author_affiliation = selectedFilters.institutions;
        queryObj.page = 1;
        //queryObj.sort = 'author_firstname_fz asc'
        queryObj.sort = "author_firstname asc, author_lastname asc";
      }

      console.log("queryObj", queryObj);
      let query = await queryConstructor(queryObj);
      console.log("query", query);

      const data = await authorFinderApi(query);
      dispatch(updateAuthorSearchResults(data));
      dispatch(updateLoadingState(false));
    }
  }

  // clear all filters
  function handleClearAllFilters() {
    dispatch(clearAllFilters());
    setTriggerCount((prev) => prev + 1);
  }

  function findSubjectIds(arr: string[]): string[] {
    try {
      let subIds = [];
      let obj: any = {};

      for (obj of subjects) {
        if (arr?.includes(obj?.val)) {
          subIds.push(obj?.id);
        }
      }
      return subIds;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  function findSubjectNames(arr: string[]): string[] {
    try {
      let subNames = [];
      let obj: any = {};

      for (obj of subjects) {
        if (arr?.includes(obj?.id)) {
          subNames.push(obj?.val);
        }
      }
      return subNames;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={[webStyle.flexBox]}>
          <Typography
            sx={{
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
            Filters
          </Typography>
          <Button
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "Helvetica Neue",
              color: "#7852FB",
            }}
            onClick={handleClearAllFilters}
          >
            Clear all
          </Button>
        </Box>

        {Array.isArray(jsonFacets?.subjects_name_l3?.buckets) &&
        jsonFacets?.subjects_name_l3?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"6%"}>
              <Typography
                sx={{
                  fontSize: "16px",
                  alignSelf: "center",
                  fontFamily: "Lora",
                  fontWeight: 600,
                }}
              >
                Subjects ({jsonFacets?.subjects_name_l3?.buckets.length})
              </Typography>
              <Button onClick={() => setFilterSubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider sx={{ marginTop: "5%" }} />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_affiliation?.buckets) &&
        jsonFacets?.author_affiliation?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"6%"}>
              <Typography
                sx={{
                  fontSize: "16px",
                  alignSelf: "center",
                  fontFamily: "Lora",
                  fontWeight: 600,
                }}
              >
                Institutions ({jsonFacets?.author_affiliation?.buckets.length})
              </Typography>
              <Button onClick={() => setFilterUnivModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
          </>
        ) : null}
      </Box>

      <FilterSubject
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSubjectTypeValues}
        filterData={jsonFacets?.subjects_name_l3?.buckets}
        applyFilter={applyFilter}
        placeholderName="subject"
        appliedFilterValues={selectedFilters?.subjectFilter}
        facetKey="subjects_name_l3"
        authorFilterScreen={true}
      />

      <FilterUniversity
        isOpen={filterUnivModalStatus}
        handleClose={handleChangeFilterUniv}
        modalName="Filter Institution"
        getFilterdValues={getUnivTypeValues}
        filterData={jsonFacets?.author_affiliation?.buckets}
        applyFilter={applyFilter}
        placeholderName="institution"
        appliedFilterValues={selectedFilters?.institutions}
        facetKey="author_affiliation"
        authorFilterScreen={true}
      />
    </>
  );
};

export default Filter;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    padding: "4%",

    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
