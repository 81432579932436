import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    isCaptchaVerified: boolean;
}

const initialState:InitialState  = {
    isCaptchaVerified: false
}

const captchaVerification = createSlice({
    name: "captchaVerification",
    initialState,
    reducers: {
        updateCaptchaStatus(state, {payload}) {
            state.isCaptchaVerified = payload
        }
    }
});

export const { updateCaptchaStatus } = captchaVerification.actions;
export default captchaVerification.reducer;
