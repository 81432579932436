import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { Colors } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  filterByCountry,
  filterBYSource,
  filterBySubject,
} from "../../api/browseJournalSearch";
import {
  alertsAndFav,
  browseJournalSearchResult,
} from "../../store/slice/browseJournal";
import {
  resourceSettingAlert,
  searchResourceCoreFacet,
} from "../../api/browseJournal";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../utils/helper";
import useDebounce from "../../hooks/useDebounce";
import { queryConstructor } from "../../api/formQuery";
import {
  updateBrowserPage,
  updateSubject,
} from "../../store/slice/browserFilterSlicePublisher";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { publisherSearchResult } from "../../store/slice/browserPublisher";
interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterData?: any;
  applyFilter?: (e: string[]) => void;
  placeholderName?: string;
  appliedFilterValues?: string[];
  facetKey?: string;
  setFilters: any;
  setIsPublisher?: any;
}

interface SubjectL3 {
  val: string;
  count: number;
  isChecked?: boolean;
}

interface MainObject {
  val: string;
  count: number;
  isSelected?: boolean;
  subjects_name_l3: {
    buckets: SubjectL3[];
  };
}

const ModalBrowsePublisherSubjects = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterData,
    applyFilter,
    placeholderName,
    appliedFilterValues,
    facetKey,
    setFilters,
    setIsPublisher,
  } = props;
  const dispatch = useAppDispatch();
  const [filteredValues, setFilteredvalues] = useState<string[]>(
    Array.isArray(appliedFilterValues) ? appliedFilterValues : []
  );

  console.log("filterData", filterData);

  const [toggleValue, setToggleValue] = useState("relevance");
  //   const [facetData, setFacetData] = useState(filterData);
  const [facetData, setFacetData] = useState<any[]>(filterData || []);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);
  const subscribedSubjects = useAppSelector(
    (state) => state.searchReducer.subjectType
  );
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const [subjectDetails, setSubjectDetails] = useState<MainObject[]>(
    facetData || []
  );

  const updateFilters: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  const l2Subjects = updateFilters?.subject.filter(
    (val: any) => val.isSelected
  );

  const selected_l2_subjects = l2Subjects?.map((val: any) => val.val);

  const l3Subjects: any = updateFilters?.subject
    ?.filter((subject: any) => !subject.isSelected)
    .flatMap((subject: any) => subject.subjects_name_l3.buckets)
    .filter((bucket: any) => bucket.isChecked);

  const selected_l3_subjects = l3Subjects?.map((val: any) => val.val);

  useEffect(() => {
    if (debouncedValue) {
      handleSearch();
    } else {
      setFacetData(filterData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValues();
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);

    setFacetData(filterData);
  }

  const handleSubmit = () => {
    dispatch(updateBrowserPage(1));
    //setIsPublisher(true);
    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();

    // filterBYSource(filteredValues);
    // getAllJournal();

    dispatch(updateSubject(subjectDetails));
  };

  function handleCancel() {
    const updatedData = addKeysToData(
      facetData,
      selected_l2_subjects,
      selected_l3_subjects
    );
    setSubjectDetails(updatedData);
    handleClose();
  }

  function clearAll() {
    // getFilterdValues && getFilterdValues([]);
    // setFilteredvalues([]);

    const updatedData = addKeysToData(facetData, [], []);
    setSubjectDetails(updatedData);
  }

  useEffect(() => {
    if (toggleValue === "alphabetical") {
      handleSort();
    } else {
      setFacetData(filterData);
    }
  }, [toggleValue]);

  function handleSort() {
    let sortedData = [...facetData].sort((a: any, b: any) => {
      let valA = Object.keys(a)[0]?.toUpperCase();
      let valB = Object.keys(b)[0]?.toUpperCase();

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });
    setFacetData(sortedData);
  }

  // Handle search
  async function handleSearch() {
    if (!facetKey) return;
    try {
      let json_facet = {
        [facetKey]: {
          type: "terms",
          field: facetKey,
          limit: 100,
          sort: "index asc",
          prefix: searchTerm.toLocaleLowerCase(),
        },
      };
      let subject = {};
      if (Array.isArray(subscribedSubjects) && subscribedSubjects.length) {
        Object.assign(subject, { fq__subjects_id_l2: subscribedSubjects });
      }
      let queryObj = {
        json_facet: JSON.stringify(json_facet),
      };
      if (Object.keys(subject).includes("fq__subjects_id_l2")) {
        Object.assign(queryObj, subject);
      }
      let query = await queryConstructor(queryObj);
      let response = await searchResourceCoreFacet("?" + query);

      let data = response?.data?.jsonfacets[facetKey].buckets;
      let formArray = [];
      for (let obj of data) {
        let newObj: any = {};
        newObj[obj?.val] = obj?.count;
        formArray.push(newObj);
      }
      setFacetData(formArray);
    } catch (e) {
      console.log(e);
    }
  }

  // Function to add isSelected and isChecked keys
  function addKeysToData(
    data: MainObject[],
    selected_l2_subjects?: any,
    selected_l3_subjects?: any
  ): MainObject[] {
    return data?.map((item) => ({
      ...item,
      isSelected: selected_l2_subjects?.includes(item.val),
      subjects_name_l3: {
        buckets: item.subjects_name_l3?.buckets?.map((bucket) => ({
          ...bucket,
          isChecked:
            selected_l3_subjects?.includes(bucket.val) ||
            selected_l2_subjects?.includes(item.val),
        })),
      },
    }));
  }

  useEffect(() => {
    const updatedData = addKeysToData(
      facetData,
      selected_l2_subjects,
      selected_l3_subjects
    );
    setSubjectDetails(updatedData);
  }, [facetData]);

  const handle_L2_Sub_CheckboxChange = (index: string) => {
    setSubjectDetails((prevCategories) =>
      prevCategories?.map((category) => {
        if (category.val === index) {
          const newIsSelected = !category.isSelected;

          const updatedBuckets = category.subjects_name_l3.buckets.map(
            (subject) => ({
              ...subject,
              isChecked: newIsSelected,
            })
          );

          return {
            ...category,
            isSelected: newIsSelected,
            subjects_name_l3: {
              ...category.subjects_name_l3,
              buckets: updatedBuckets,
            },
          };
        }
        return category;
      })
    );
  };

  const handleL3SubjectChange = (subjectVal: string, parentVal: string) => {
    setSubjectDetails((prevCategories) =>
      prevCategories?.map((category) => {
        if (category.val === parentVal) {
          const updatedBuckets = category?.subjects_name_l3?.buckets?.map(
            (subject) =>
              subject.val === subjectVal
                ? { ...subject, isChecked: !subject.isChecked }
                : subject
          );

          const allChecked = updatedBuckets.every(
            (subject) => subject.isChecked
          );

          return {
            ...category,
            isSelected: allChecked,
            subjects_name_l3: {
              ...category.subjects_name_l3,
              buckets: updatedBuckets,
            },
          };
        }
        return category;
      })
    );
  };

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: 550 }}>
        {/* Search Bar */}
        {/* <TextField
          size="small"
          fullWidth
          placeholder={`Search by ${placeholderName}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        /> */}
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 3 }}
        >
          <Stack direction={"row"} alignItems="center">
            <ToggleButtonGroup
              color="primary"
              exclusive
              aria-label="Platform"
              size="small"
            >
              <ToggleButton
                sx={[
                  toggleValue === "relevance"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "relevance" ? Colors.primary : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
                value={"relevance"}
              >
                Journal Count
              </ToggleButton>
              {/* <ToggleButton
                value="alphabetical"
                sx={[
                  toggleValue === "alphabetical"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "alphabetical"
                          ? Colors.primary
                          : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
              >
                Alphabetical
              </ToggleButton> */}
            </ToggleButtonGroup>
          </Stack>
          <Button onClick={clearAll}>
            Clear All <CloseIcon fontSize="small" />
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ gap: 2, overflowY: "auto" }}
          flexWrap="wrap"
          maxHeight={"60vh"}
        >
          {subjectDetails?.map((data, ind) => {
            const parentVal = data?.val;
            return (
              <div key={data.val}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ gap: 2 }}
                  width="400px"
                >
                  <Stack direction="row" alignItems="center">
                    <Accordion
                      key={data.val}
                      style={{ width: "400px", height: "auto" }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <FormControlLabel
                          key={data.val}
                          control={
                            <Checkbox
                              id={data.val}
                              checked={data.isSelected || false}
                              onChange={() =>
                                handle_L2_Sub_CheckboxChange(data.val)
                              }
                              onClick={(e) => e.stopPropagation()}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {toTitleCase(data.val)} {`(${data.count})`}
                            </Typography>
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        {data?.subjects_name_l3?.buckets?.map((l3Item) => (
                          <div
                            key={l3Item.val}
                            style={{ display: "block", marginLeft: "40px" }}
                          >
                            <FormControlLabel
                              key={l3Item.val}
                              control={
                                <Checkbox
                                  checked={l3Item.isChecked || false}
                                  onChange={() =>
                                    handleL3SubjectChange(l3Item.val, parentVal)
                                  }
                                />
                              }
                              label={
                                <Typography variant="body1">
                                  {`${toTitleCase(l3Item.val)} - (${
                                    l3Item.count
                                  })`}
                                </Typography>
                              }
                            />
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Stack>
              </div>
            );
          })}
          <Stack marginBottom={"100px"}></Stack>
        </Stack>
      </DialogContent>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          gap: 2,
          marginBlock: 2,
          position: "sticky",
          padding: "16px 24px",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ minWidth: 100 }}
        >
          Apply
        </Button>
        <Button
          onClick={handleCancel}
          sx={{ minWidth: 150, coloFr: "#111928" }}
        >
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ModalBrowsePublisherSubjects;

const styles = {
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: "100px",
    borderRadopis: "6px",
  },
  unCheckedStyle: {
    minWidth: "100px",
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
    borderRadopis: "6px",
  },
};
