import { Card, CardContent, Typography } from "@mui/material";

export default function NoRecordsFound() {

    return (
        <Card>
            <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "225px"}}>
                <Typography fontFamily={"Lora"} fontWeight={"600"} fontSize={"20px"}>No Records Found</Typography>
            </CardContent>
        </Card>
    )
}