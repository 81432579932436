import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { Button, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import Captcha from "../captcha";
import { updateCaptchaStatus } from "../../store/slice/captchaVerification";

interface PropType {
  open: boolean;
}

export default function CaptchaModal(props: PropType) {
  const { open } = props;
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const dispatch = useAppDispatch();

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    captchaText: string
  ) {
    if (e.target.value == captchaText) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  }
  function onCaptchaReset() {
    setCaptchaVerified(false);
  }

  function onSubmit() {
    dispatch(hideModal());
    dispatch(updateCaptchaStatus(true))
  }
  return (
    <Dialog open={open}>
      {/* ------------------------Title --------------------------------------- */}

      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
          Captcha Verification
        </Typography>
      </DialogTitle>

      {/*----------------------------- Content------------------------------------- */}
      <DialogContent
        sx={{ width: { sx: "100%", sm: "100%", md: "450px", lg: "450px" } }}
      >
        <Captcha
          onChange={handleChange}
          onCaptchaReset={onCaptchaReset}
          isCaptchaVerified={isCaptchaVerified}
          onSubmit={onSubmit}
        />
        <Button variant="contained" fullWidth sx={{ mt: 2}} onClick={onSubmit} disabled={!isCaptchaVerified}>Verify</Button>
      </DialogContent>
    </Dialog>
  );
}
