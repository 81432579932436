import axios from "axios";
import api from "./endpoints";
export async function getAllPublisher(customerId: any) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/${customerId}&fq__active_flag=true`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function setAlert(endPoint: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/check_resource_alert_setting_status/check_resource_alert_setting_status${endPoint}`
    );
  } catch (err) {
    console.log(err);
  }
}

export async function getJournals(data: any) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/resourcecore${data}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getBooks(data: any) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/bookscore${data}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}
