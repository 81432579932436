import { queryConstructor } from "../../api/formQuery";
import { basicSearch } from "../../api/Search/BasicSearch.api";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import { CustomerDetailsType, SaverArticlesType } from "../../types/interface";
import {
  getOpacData,
  getPersonalLibraryCollection,
} from "../../api/Search/CollectionApi";
import { AppThunk } from "../../store";
import { updateLoadingState } from "../../store/slice/filterSlice";
// import { updateLinkToDisplay, updateSearchResults } from "../../store/slice/searchSlice";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import {
  updateAuthorSearchResults,
  updateAuthorSearchTerm,
  updateAuthorLinkToDisplay,
  updateIsAuthorSettled,
  updateIsAuthorAnalytics,
  clearAuthorSearchTerm,
  updateAuthorSavedArticles,
  updateTempSearchTerm,
  updateAuthorPage,
  updateAuthorRow,
  updateIsInitialResult,
} from "../../store/slice/authorFinderSlice";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { updateSavedArticles } from "../../store/slice/searchSlice";

export const onAuthorResult =
  (
    customQuery?: string,
    userDetail?: CustomerDetailsType | null,
    logSearchHistory = false
  ): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(updateLoadingState(true));
    dispatch(updateIsInitialResult(false));

    const searchReducer = getState().authorReducer;
    const filterReducer = getState().filterReducer;
    const customerData = getState().customer.customerDetails;
    const user_id = getState().login.user_id;

    const searchKeys = Object.keys(searchReducer);
    const filterKeys = Object.keys(filterReducer);

    let sessionKey = sessionStorage.getItem("session_key");
    let userId = sessionStorage.getItem("user_id");
    let informaticscustomerId = sessionStorage.getItem(
      "informaticscustomer_id"
    );

    dispatch(updateIsAuthorSettled(false));

    let searchQueryObj: any = {};
    let queryObj: any = {};
    let dataType: any = {};
    let kohaQueryObj: any = {};
    let fqAuthorparam: any = {} // the serach term and the co-author filter 

    searchQueryObj.customerid = informaticscustomerId;
    searchQueryObj.sessionid = sessionKey;
    searchQueryObj.profileuserid = userId;

    //=============== Search Query ===================================//
    if (searchKeys.includes("searchTerm")) {
      // searchQueryObj.authors_tk = searchReducer?.searchTerm;

      if (filterReducer["author"].length > 0) {
        dispatch(updateAuthorSearchTerm(filterReducer["author"]));

        dispatch(updateAuthorSearchTerm(searchReducer.searchTerm));
      } else {
        searchQueryObj.fq__authors_tk = searchReducer?.searchTerm;
      }
    }
    if (searchKeys.includes("page")) {
      searchQueryObj.page = searchReducer.page;
    }
    if (searchKeys.includes("rows")) {
      searchQueryObj.rows = searchReducer.rows;
    }
    //   if (searchKeys.includes("authorName")) {
    //     searchQueryObj.authors = searchReducer.authorName;
    //   }

    if (searchKeys.includes("dataType")) {
      if (
        searchReducer.dataType.length &&
        !searchReducer.dataType.includes(0)
      ) {
        dataType.fq__resource_type = searchReducer.dataType;
      }
    }

    if (searchReducer.dataType.includes(0)) {
      // dataType.fq__resource_type = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 OR 5)";
      dataType.fq__resource_type = searchReducer.dataType;
    }
    if (searchKeys.includes("subjectType")) {
      if (searchReducer.subjectType.length) {
        dataType.fq__subjects_id_l2 = searchReducer.subjectType;
      }
    }

    if (searchKeys.includes("institutions")) {
      if (searchReducer.institutions.length) {
        dataType.author_address = searchReducer.institutions;
        // dataType.fq__subjects_id_l2 = searchReducer.subjectType;
      }
    }
    if (searchReducer.subjectFilter.length) {
      queryObj.subjects_name_l3 = searchReducer.subjectFilter;
    }
    searchQueryObj.op_mode = "or";


    //================================================================//

    let searchQuery = await queryConstructor(searchQueryObj, "OR");
    // let searchQuery = await queryConstructor(searchQueryObj, "OR", true);
    let dataTypeQuery = await queryConstructor(dataType);

    //=============== Filter Query ===================================//

    if (filterKeys.includes("subject")) {
      queryObj.fq__subjects_name_l3 = filterReducer["subject"];
    }
    if (filterKeys.includes("sourceType")) {
      queryObj.fq__data_type = filterReducer["sourceType"];
    }
    if (filterReducer.author.length) {
      queryObj.fq__authors_tk = filterReducer["author"];
      if(searchReducer.searchTerm.length>0){
        let searchTermData:any= searchReducer.searchTerm;
        let authorQuery = searchTermData.map((term: any) => `"${term}"`).join(" OR ");
        let coAuthorQuery = filterReducer["author"].map(author => `"${author}"`).join(" OR ");
        

        fqAuthorparam.fq__authors_tk = [`${authorQuery}`, `${coAuthorQuery}`]
      }
      else{
          queryObj.fq__authors_tk = filterReducer["author"];
      }
    }
    if (filterKeys.includes("speaker")) {
      queryObj.fq__speakers = filterReducer["speaker"];
    }
    if (filterKeys.includes("research")) {
      queryObj.fq__guide_name_tk = filterReducer["research"];
    }
    if (filterKeys.includes("journal")) {
      queryObj.fq__journal_name = filterReducer["journal"];
    }
    if (filterKeys.includes("countryOfPublication")) {
      queryObj.fq__primary_publisher_country =
        filterReducer["countryOfPublication"];
    }
    if (filterKeys.includes("publisher")) {
      queryObj.fq__publisher_name = filterReducer["publisher"];
    }
    if (filterKeys.includes("institutions")) {
      queryObj.fq__author_address = filterReducer["institutions"];
    }
    if (filterKeys.includes("journalRank")) {
      queryObj.fq__filter_metrix = filterReducer["journalRank"];
    }
    if (filterKeys.includes("sort")) {
      queryObj.sort = filterReducer["sort"];
    }
    if (filterKeys.includes("countryOfPublishingAuthor")) {
      queryObj.fq__author_country_name = filterReducer["countryOfPublishingAuthor"];
    }
    if (filterKeys.includes("sourceIndex")) {
      if (filterReducer["sourceIndex"])
        queryObj.fq__pubmed_article = filterReducer["sourceIndex"];
    }
    if (filterKeys.includes("yearFrom")) {
      if (filterReducer.yearFrom) {
        queryObj.fq__yearfrom = filterReducer.yearFrom;
      }
    }
    if (filterKeys.includes("monthFrom")) {
      if (filterReducer.monthFrom) {
        queryObj.fq__dateofpublication = filterReducer.monthFrom;
      }
    }
    if (filterKeys.includes("dateFrom")) {
      if (filterReducer.dateFrom) {
        queryObj.fq__dateofpublication = filterReducer.dateFrom;
      }
    }
    if (filterKeys.includes("openAccess")) {
      if (filterReducer.openAccess) {
        queryObj.fq__fulltext = filterReducer.openAccess;
      }
    }
    if (filterKeys.includes("retractedArticle")) {
      if (filterReducer.retractedArticle) {
        queryObj.fq__is_retracted = filterReducer.retractedArticle;
      }
    }
    if (filterKeys.includes("datesetAvailability")) {
      if (filterReducer.datesetAvailability) {
        queryObj.fq__has_datasets = filterReducer.datesetAvailability;
      }
    }
    // if (filterReducer.earlyOnline) {
    //     queryObj.fq__early_online = true;
    // }
    // if (filterReducer.myLibraryCollection && userDetail) {
    //     queryObj.fq__acl_group = userDetail?.informaticscustomer_id;
    // }
    // if (filterReducer.consortiaCollection && userDetail) {
    //     queryObj.fq__acl_group = `(${userDetail.informaticscustomer_id} OR ${userDetail.consortia_virtualid})`;
    // }

    if (filterReducer.earlyOnline) {
      queryObj.fq__early_online = true;
    }
    if (filterReducer.myLibraryCollection && customerData) {
      queryObj.fq__acl_group = customerData?.my_library_filter;
    }
    if (filterReducer.consortiaCollection && customerData) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }

    if (filterReducer.onosCollection) {
      queryObj.fq__is_onos_resource = true;
    }

    if (filterReducer.myPersonalLibCollection) {
      queryObj.page = searchReducer.page;
    }


    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if(isOnlyConsortiaAccess) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }

    //================ Count Query ===================================//
    let fullTextQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${informaticscustomerId}))`,
    });
    let allQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
    });

    let authorAndCoauthorQuery = await queryConstructor(fqAuthorparam, "AND",true);

    fullTextQuery += "&" + searchQuery + "&" + authorAndCoauthorQuery;
    allQuery += "&" + searchQuery + "&" + authorAndCoauthorQuery;

    if (customQuery) {
      fullTextQuery += "&" + searchQuery + "&" + customQuery;
      allQuery += "&" + searchQuery + "&" + customQuery;
    }

    if (filterReducer?.libraryOpac) {
      //   let kohaQueryObj = {};

      kohaQueryObj.author_name = searchReducer?.searchTerm;
      kohaQueryObj.customer_id = customerData?.informaticscustomer_id;
      kohaQueryObj.page_no = searchReducer.page;
      kohaQueryObj.per_page = searchReducer.rows;
      // kohaQueryObj.page_no = 1;
      // kohaQueryObj.per_page =  searchReducer.rows;
      kohaQueryObj.year_limit = filterReducer.yearFrom;

      // Object.assign(kohaQueryObj,{author_name: searchReducer?.searchTerm});
      // Object.assign(kohaQueryObj, { customer_id: customerData?.informaticscustomer_id });
      // Object.assign(kohaQueryObj, { page_no: searchReducer.page });
      // Object.assign(kohaQueryObj, { per_page: searchReducer.rows });
      // Object.assign(kohaQueryObj, { year_limit: filterReducer.yearFrom })


      let kohaQuery = await queryConstructor(kohaQueryObj, "OR", true);

      const data: any = await getOpacData(kohaQuery);

      // dispatch(updateAuthorPage(1));
      // dispatch(updateAuthorRow(15));
      dispatch(updateAuthorSearchResults(data?.data));
      dispatch(updateLoadingState(false));

      return data?.data;
    }

    // if (filterReducer?.libraryOpac) return;

    // if library opac is true return
    // To update the full text and all count
    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    //================================================================//

    if (searchReducer.fullText) {
      // queryObj.fq__fulltext = searchReducer.fullText;
      Object.assign(queryObj,{
        "fq__(fulltext": `true OR acl_group=(${informaticscustomerId}))`,
      })
    }

    const filterQuery = await queryConstructor(queryObj);

    let query = searchQuery + "&" + filterQuery + "&" + dataTypeQuery + "&" + authorAndCoauthorQuery;
    if (customQuery) {
      query =
        searchQuery +
        "&" +
        filterQuery +
        "&" +
        dataTypeQuery +
        "&" +
        customQuery;
    }

    if (filterReducer?.myPersonalLibCollection) {
      const data = await getPersonalLibraryCollection(query);
      !!data && dispatch(updateAuthorSearchResults(data));
      !!data && dispatch(updateLoadingState(false));
      dispatch(updateIsAuthorSettled(true));

      return data;
    }

    if (logSearchHistory) {
      // to log search history send true in third argument
      let x = query + "&logsearchhistory=true";
      let y = x.replace(`&fq__(fulltext=true OR acl_group=(${informaticscustomerId}))`, "");
      y += "&datsearchtype_id=3";
      await basicSearch(y);
    }

    const data = await basicSearch(query);

    !!data && dispatch(updateAuthorSearchResults(data));
    !!data && dispatch(updateLoadingState(false));

    let customerId = customerData?.informaticscustomer_id;
    const consortia_virtualid = customerData?.consortia_virtualid;

    let dataArray = data?.docs;
    if (!searchReducer.fullText) {
      let formData = new FormData();

      let x = dataArray.map((obj: any) => {
        let object: any = {
          customer_id: customerId,
          article_id: obj?.article_id,
          consortia_virtualid: consortia_virtualid,
          resource_type: obj?.resource_type,
          access_type: obj?.article_open_status || null,
          is_oa_article: obj?.fulltext || false,
        };

        if (obj?.articledoi) {
          object.article_doi = obj?.articledoi;
        }

        if (obj?.resourceissue_id) {
          object.resourceissue_id = obj?.resourceissue_id;
        }

        return object;
      });

      let formApiData = {
        data: x,
      };

      formData.append("detailed_json", JSON.stringify(formApiData));
      const response = await getBulkLinkRecords(formData);

      if (response?.message === "Ok") {
        dispatch(updateAuthorLinkToDisplay(response?.data));
      }
    }

    // Checking the article is already added ot folder
    const articleData = new FormData();

    const articleIds =
      Array.isArray(dataArray) && dataArray.map((x: any) => x?.article_id);
    articleData.append("profile_id", user_id);
    articleData.append("article_ids", articleIds.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateAuthorSavedArticles(filter_is_saved));

    //    dispatch(updateLoadingState(false));

    return data;
  };
