import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  Icon,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { Colors } from "../../utils/constants";
import useDebounce from "../../hooks/useDebounce";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { queryConstructor } from "../../api/formQuery";
import { basicSearch } from "../../api/Search/BasicSearch.api";
import { usagelogApi, usageReport } from "../../api/usageReportApi";

interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterData?: any;
  applyFilter?: (e: string[]) => void;
  placeholderName?: string;
  appliedFilterValues?: string[];
  facetKey?: string;
}

const ModalTwo = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterData,
    applyFilter,
    placeholderName,
    appliedFilterValues,
    facetKey,
  } = props;
  const dispatch = useAppDispatch();
  const [filteredValues, setFilteredvalues] = useState<string[]>(
    Array.isArray(appliedFilterValues) ? appliedFilterValues : []
  );
  const [toggleValue, setToggleValue] = useState("relevance");
  const [facetData, setFacetData] = useState(filterData);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);

  const profileData = useAppSelector((state: any) => state.login);

  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const searchReducer = useAppSelector((state) => state.searchReducer);

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  useEffect(() => {
    if (debouncedValue) {
      handleSearch();
    } else {
      setFacetData(filterData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValues();
    setToggleValue("relevance");
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);

    setFacetData(filterData);
  }

  const selectedValues = (data: string) => {
    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
      // getFilterdValues && getFilterdValues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
      // getFilterdValues &&
      //   getFilterdValues(filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
      // getFilterdValues && getFilterdValues([...filteredValues, data]);
    }
  };

  const handleSubmit = () => {
    if (
      !filteredValues.length &&
      appliedFilterValues &&
      appliedFilterValues?.length <= 0
    )
      return;
    if (modalName === "Filter Author Country") {
      logBasicSearchUsageData(60);
    } else if (modalName === "Filter Country of Publishing") {
      logBasicSearchUsageData(51);
    } else if (modalName === "Journal") {
      logBasicSearchUsageData(59);
    } else if (modalName == "Subject") {
      logBasicSearchUsageData(56);
    }
    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setToggleValue(value);
    if (value === "alphabetical") {
      handleSort();
    } else {
      setFacetData(filterData);
    }
  };

  function handleCancel() {
    setFilteredvalues([]);
    handleClose();
  }

  function clearAll() {
    // getFilterdValues && getFilterdValues([]);
    setFilteredvalues([]);
  }

  function handleSort() {
    let sortedData = [...facetData].sort((a: any, b: any) => {
      let valA = a.val.toUpperCase().replaceAll(/[\s,]/g, "");
      let valB = b.val.toUpperCase().replaceAll(/[\s,]/g, "");

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });
    setFacetData(sortedData);
  }

  // Handle search
  async function handleSearch() {
    if (typeof facetKey !== "string") return;
    try {
      let json_facet = {
        [facetKey]: {
          type: "terms",
          field: facetKey,
          limit: 100,
          sort: "index asc",
          prefix: searchTerm,
        },
      };
      let query = await queryConstructor({
        json_facet: JSON.stringify(json_facet),
      });

      const advancedSearchQuery = searchReducer.advanceSearchQuery;

      const searchQueryObj = {
        titleKeywordsAbs: searchReducer.searchTerm,
      };

      const isOnlyConsortiaAccess = profileData1?.product_type === "7";
      if (isOnlyConsortiaAccess) {
        Object.assign(searchQueryObj, {
          fq__acl_group: profileData1.consortia_filter,
        });
      }

      const dataTypeObj = {};

      if (searchReducer.subjectType.length) {
        Object.assign(dataTypeObj, {
          fq__subjects_id_l2: searchReducer.subjectType,
        });
      }

      if (searchReducer.dataType.length) {
        Object.assign(dataTypeObj, {
          fq__resource_type: searchReducer.dataType,
        });
      }

      if (searchReducer.fullText) {
        Object.assign(searchQueryObj, {
          "fq__(fulltext": `true OR acl_group=(${profileData1?.informaticscustomer_id}))`,
        });
      }
      const searchQuery = await queryConstructor(searchQueryObj, "AND", true);
      let dataTypeQuery = await queryConstructor(dataTypeObj);

      query += `&${searchQuery}&${dataTypeQuery}`;
      if (advancedSearchQuery) {
        query += `&${advancedSearchQuery}`;
      }

      let response = await basicSearch(query);

      let data = response?.jsonfacets[facetKey].buckets;
      setFacetData(data);
    } catch (e) {
      console.log(e);
    }
  }

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: 906 }}>
        {/* Search Bar */}
        <TextField
          size="small"
          fullWidth
          placeholder={`Search by ${placeholderName}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 3 }}
        >
          <Stack direction={"row"} alignItems="center">
            <ToggleButtonGroup
              onChange={handleChange}
              color="primary"
              exclusive
              aria-label="Platform"
              size="small"
            >
              <ToggleButton
                sx={[
                  toggleValue === "relevance"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "relevance" ? Colors.primary : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
                value={"relevance"}
              >
                {/* Relevance */}
                Document Count
              </ToggleButton>
              <ToggleButton
                value="alphabetical"
                sx={[
                  toggleValue === "alphabetical"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "alphabetical"
                          ? Colors.primary
                          : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
              >
                Alphabetical
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Button onClick={clearAll}>
            Clear All <CloseIcon fontSize="small" />
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ gap: 2, overflowY: "auto" }}
          flexWrap="wrap"
          maxHeight={"60vh"}
        >
          {Array.isArray(facetData) &&
            facetData?.map((data: any) => (
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: 2 }}
                width={"400px"}
              >
                <Stack direction={"row"} alignItems="center">
                  <Checkbox
                    onClick={() => selectedValues(data?.val)}
                    checked={filteredValues?.includes(data?.val)}
                  />
                  <Tooltip title={data?.val}>
                    <Typography
                      sx={{
                        width: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.val
                        .trim()
                        .replace(/^\,+|\,+$/g, "")
                        .trim()}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Typography>({data?.count})</Typography>
              </Stack>
            ))}
        </Stack>
      </DialogContent>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          gap: 2,
          marginBlock: 2,
          position: "sticky",
          padding: "16px 24px",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ minWidth: 100 }}
        >
          Apply
        </Button>
        <Button onClick={handleCancel} sx={{ minWidth: 150, color: "#111928" }}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ModalTwo;

const styles = {
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: "100px",
    borderRadopis: "6px",
  },
  unCheckedStyle: {
    minWidth: "100px",
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
    borderRadopis: "6px",
  },
};
