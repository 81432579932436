import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reportIssueApi } from '../../api/reportIssueApi';
import { reportIssueSuccess } from '../../store/slice/reportIssueSlice';

export interface State extends SnackbarOrigin {
    open: boolean;
}

const ReportIssue = ({ openViewModal, handleCloseViewModal, id, vol, issue, articledoi, resourcemaster_id }: any) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorFlag, setErrorFlag] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);



    const articleID = id;

    // const informaticscustomer_id = sessionStorage.getItem('informaticscustomer_id')
    // const user_id = sessionStorage.getItem('user_id')
    const informaticscustomer_id = sessionStorage.getItem('informaticscustomer_id')
    const user_id = sessionStorage.getItem('user_id')

    const Volume = vol;
    const Issue = issue;
    const url = `https://doi.org/${articledoi}`;
    const resourcemasterId = resourcemaster_id;

    const profileuser_id = user_id;
    const informaticsCustomerID = informaticscustomer_id;

    const [ip, setIP] = useState('');

    const handleClose = (e: any) => {
        handleCloseViewModal(e);
        setEmail("");
        setMessage("");
        setErrorFlag(false);

    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpenSuccessAlert(false)
    };

    const isBook = /^book-\d+$/.test(id);

    const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    //creating function to load ip address from the API
    const getData = async () => {

        const res = await fetch('https://api.ipify.org?format=json')
        // setIP(res.data.IPv4)
        const data = await res.json();
        setIP(data?.ip);

    }

    useEffect(() => {

        if (openViewModal) {

            getData()
        }

    }, [openViewModal])

    const handleReportIssue = async (e: any) => {
        e.preventDefault();
        setErrorFlag(true);

        if (email.length === 0 && message.length === 0) {
            setOpen(true);
            setSnackMessage('Fill mandatory fields ');
            return;
        }
        try {
         
            const reportData = {
                // informaticscustomer_id: 1848,
                informaticscustomer_id: informaticsCustomerID?.toString(),
                // reportedusermaster_id: profileuser_id?.toString(),
                usermaster_id: profileuser_id?.toString(),
                // usermaster_id: 19,
                email_id: email,
                resource_id: resourcemasterId,
                volume: Volume,
                issue: Issue,
                issue_description: message,
                issue_url: url,
                // ip_address: '192.168.1.6',
                url_format_id: 1,
                url_type_id: 1,
                ip_address: ip,
                // url: "",
            }

            if(isBook){
                Object.assign(reportData,{book_id:articleID.replace(/^book-/, '')})
            }
            else{
                Object.assign(reportData,{article_id:articleID})
            }

            const detailed_json = {
                data: [
                   reportData
                ],
            };
            let formData = new FormData();
            formData.append("detailed_json", JSON.stringify(detailed_json))

            const querydata = await reportIssueApi(formData);
    
            if (querydata.message === "Ok") {
                e.preventDefault();
 

                dispatch(reportIssueSuccess(querydata))
                setOpenSuccessAlert(true);
                setSnackMessage('Email sent Successfully');
                // alert('Link sent successfully!')
                // handleCloseViewModal();
                handleClose(e);
                setEmail("");
                setMessage("");


            }

        }
        catch (error) {
            console.error('Error State', error)
        }
    };


    return (
        <>
            {snackMessage && <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnackbar}

                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}>
                    <Alert onClose={handleCloseSnackbar} severity='error' sx={{ width: '100%' }}>
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Stack>}

            {snackMessage && <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={3000} onClose={handleCloseSnackbar}

                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}>
                    <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Stack>}

            <Dialog open={openViewModal}
                // onClose={handleCloseViewModal}
                onClose={(e) => handleClose(e)}
                id={id}>
                <DialogTitle
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography textAlign="left" fontFamily={"Lora"} fontWeight={700} fontSize={28} id="modal-modal-title" variant="h6" component="h2">
                        Report Full Text Link Issue
                    </Typography>

                    <IconButton onClick={handleCloseViewModal}>
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>

                <DialogContent>
                    <Box sx={{ display: "flex" }}>

                        <Typography fontSize={16} fontWeight={500} textAlign="left" color="#212121">
                            Email Address </Typography>
                        <Typography color={"red"}>*</Typography>
                    </Box>

                    <TextField
                        margin="normal"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        sx={{ width: '551px', background: '#fffff', }}
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                    />
                    {!email.trim() && errorFlag && (
                        <Typography sx={Styles.errorMessage}>Email required</Typography>
                    )}
                    {!emailRegex.test(email.trim()) && errorFlag && email.trim() && (
                        <Typography sx={Styles.errorMessage}>Invalid Email</Typography>
                    )}

                    <Box sx={{ display: "flex" }}>

                        <Typography fontSize={16} fontWeight={500} textAlign="left" color="#212121">
                            Message </Typography>
                        <Typography color={"red"}>*</Typography>
                    </Box>

                    <TextField
                        multiline={true}
                        rows={5}
                        name="message"
                        placeholder="Enter Message"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        required
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}
                    />


                    {!message.trim() && errorFlag && (
                        <Typography sx={Styles.errorMessage}>Message required</Typography>
                    )}



                    <DialogActions style={{ justifyContent: "flex-start" }}>
                        <Button sx={{

                            '&:hover': {
                                backgroundColor: '#7852FB',
                            },


                            textAlign: "left", mt: 2,
                            backgroundColor: '#7852FB',
                            color: " #FAF8F5",
                            textTransform: "capitalize", borderRadius: "8px", fontSize: "16px",
                            width: "192px", height: "51px"
                        }}
                            onClick={handleReportIssue}
                        //disabled={email.length === 0 && message.length === 0}

                        >Submit</Button>

                        <Button sx={{
                            textAlign: "left", left: "42px", fontWeight: 500, fontSize: "18px",
                            backgroundColor: '#FFFFFF', color: " #111928",
                            textTransform: "capitalize", borderRadius: "8px",
                            height: "51px",
                        }}
                            // onClick={handleCloseViewModal}
                            onClick={(e) => {
                                handleClose(e);
                            }}
                        >Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReportIssue

const Styles = {
    errorMessage: {
        color: "red",
        fontFamily: 'Poppins',
        textAlign: 'left',
        fontSize: '12px'
    }
}