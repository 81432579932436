import { GetAllSubjects } from "../types/GetAllSubjects.types";
import {
  GetMyPreferenceData,
  GetMyL2L3SubjectsData,
} from "../types/GetMySubjects.types";
import http from "./httpService";

export async function getAllSubjects(): Promise<GetAllSubjects[]> {
  try {
    const { data: response } = await http.get(
      "/api/get_subject_level_details/get_subject_level_details"
    );

    return response.Subjects;
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}

export async function getMyL1L2Subjects(
  userId: string
): Promise<GetMyPreferenceData> {
  try {
    const { data: response } = await http.get(
      "/api/get_all_saved_personalisation_details/get_all_saved_personalisation_details",
      {
        params: {
          profileuserid: userId,
        },
      }
    );

    return response.data[0][0];
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}

export async function getMyL2L3Subjects(
  userId: string
): Promise<GetMyL2L3SubjectsData> {
  try {
    const { data: response } = await http.get(
      "/api/get_personalisation_l2_l3_subject_details/get_personalisation_l2_l3_subject_details",
      {
        params: {
          profileuserid: userId,
        },
      }
    );

    return response.data[0][0];
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}

export async function updateMySubjects(
  userId: string,
  idArray: string[],
  csrf: any
) {
  const formData = new FormData();
  formData.append("profileuserid", userId);
  formData.append("subject_level3_ids", idArray.join(","));
  console.log("formData", formData);
  try {
    const { data: response } = await http.post(
      "/api/Update_personalisation_subjects/Update_personalisation_subjects",
      formData,
      {
        headers: {
          "X-CSRFToken": csrf,
        },
      }
    );

    console.log(response);
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}

export async function updateSourceType(formData: FormData, csrf: any) {
  try {
    const { data: response } = await http.post(
      "/api/Update_personalisation_resourcetype/Update_personalisation_resourcetype?profileuserid=14&resource_type_ids=1,2,3",
      formData,
      {
        headers: {
          "X-CSRFToken": csrf,
        },
      }
    );

    console.log(response);
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}
