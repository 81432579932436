import { setSessionCookie } from "../utils/sessionCookie";
import http from "./httpService";

export const userLogin = ({ email, password, navigate }: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await http.post(
        "/user/login",
        { username: email, password },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.data === "Success") {
        sessionStorage.setItem("session_key", res.data.session_key);

        //Save the user session data in local storage
        sessionStorage.setItem("csrf_token", res.data.csrf_token);
        sessionStorage.setItem("user_id", res.data.user_id);
        // sessionStorage.setItem("informaticscustomer_id", res.data.informaticscustomer_id);
        sessionStorage.setItem("preference", "false");

        sessionStorage.setItem(
          "informaticscustomer_id",
          res.data.informaticscustomer_id
        );

        // Setting the user data in the sessin cookie to make sure the data is available till the browser closes.
          setSessionCookie("session_key", res.data.session_key);
          setSessionCookie("csrf_token", res.data.csrf_token);
          setSessionCookie("user_id", res.data.user_id);
          setSessionCookie("informaticscustomer_id", res.data.informaticscustomer_id);

        resolve(res.data);
        navigate("/initialSearch");

        return res.data;
      } else {
        return console.log("Response Data", res.data.data);
      }
    } catch (error: any) {
      console.log(error.message);
      console.log(error);
      reject(error);
    }
  });
};

export const resetPasswordApi = ({ email }: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await http.post(
        "/user/Forget_password_token_generation",
        { username: email },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      resolve(response.data);

      if (response.data.data === "Success") {
        return response.data.data;
      }
    } catch (err: any) {
      console.log(err.message);
      console.log(err);
      reject(err);
    }
  });
};

export async function profile_ChangePasssword({ user_id, old_password, new_password, re_enter_password }: any) {
  try {
    const response = await http.post(
      "/user/change_password",
      {
        user_id: user_id,
        old_password: old_password,
        new_password: new_password,
        re_enter_password: re_enter_password
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
    return { error: error }
  }
}

export async function profileDetailsAPI({ userid }: any) {
  try {
    console.log("triggered try block");
    const res = await http.get(
      "/User/get_usermaster_details_by_id/get_usermaster_details_by_id?",
      {
        params: {
          profileuserid: userid,
        },
      }
    );
    console.log(
      "Response from profileDetailsAPI",
      res.data?.data[0][0].emailid
    );
    return res;
  } catch (error) {
    console.log("error", error);
  }
}

export async function validateScholarLoginByToken({
  customerid,
  token,
  is_lib_admin,
  navigate,
}: any) {
  return new Promise(async (resolve, reject) => {
    console.log("customer_id", customerid);
    console.log("login_token", token);
     console.log("login_token", is_lib_admin);
    try {
      console.log("triggered try block");
      const res = await http.post(
        "/user/validate_scholar_login_by_token_matching",
        { customerid, token,is_lib_admin},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.data === "Success") {
        sessionStorage.setItem("session_key", res.data.session_key);

        //Save the user session data in local storage
        sessionStorage.setItem("csrf_token", res.data.csrf_token);
        sessionStorage.setItem("user_id", res.data.user_id);
        // sessionStorage.setItem("informaticscustomer_id", res.data.informaticscustomer_id);
        sessionStorage.setItem("preference", "false");

        sessionStorage.setItem(
          "informaticscustomer_id",
          res.data.informaticscustomer_id
        );
        resolve(res.data);
        navigate("/initialSearch");

        return res.data;
      } else {
        return console.log("Response Data", res.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  });
}
