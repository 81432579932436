import { createSlice } from "@reduxjs/toolkit";

export interface reportIssueState {
    isLoading: boolean;
    informaticscustomer_id: string;
    reportedusermaster_id: string;
    useremailid: string;
    article_id: string;
    issuedescription: string;
    ip_address: string;
    message: string;
    error: string;
 
  }

  const initialState: reportIssueState ={
    isLoading: false,
    informaticscustomer_id: "",
    reportedusermaster_id: "",
    useremailid: "",
    article_id: "",
    issuedescription: "",
    ip_address: "",
    message: "",
    error: ""
  }

  export const reportIssueSlice = createSlice({
    name: "reportissue",
    initialState,
    reducers:{

   
    reportIssueSuccess: (state, { payload }) => {
        console.log('Payload report issue data', payload)
        state.isLoading = false;
        state.informaticscustomer_id = payload;
        state.reportedusermaster_id = payload;
        state.useremailid = payload;
        state.article_id = payload;
        state.issuedescription = payload;
        state.ip_address = payload;
    }
    },
  });

  const {reducer, actions} = reportIssueSlice;

  export const {reportIssueSuccess} = actions;

  export default  reducer;