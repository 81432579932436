import api from "./endpoints";
import axios from 'axios';

const logoutUrl = api.baseApi + '/user/logout'

export const userLogout = async () => {
    try {
       
        const res = await axios.get(logoutUrl);
        if (res.data === "Lougout_Success") {
            sessionStorage.removeItem("session_key");
            // sessionStorage.removeItem("session_key");
            // sessionStorage.removeItem("user_id");
            // sessionStorage.removeItem("csrf_token");
            // sessionStorage.removeItem("informaticscustomer_id");
           // sessionStorage.removeItem("session_key");
            sessionStorage.removeItem("user_id");
            sessionStorage.removeItem("csrf_token");
            sessionStorage.removeItem("informaticscustomer_id");
        }

    } catch (error) {
        console.log(error);
    }
}
