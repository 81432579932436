import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { modalBaseStyles } from "./RootModal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ChartData } from "chart.js";
import AnalyticsBarChart from "../chart/AnalyticsBarChart";
import AnalyticsHorizontalChart from "../chart/AnalyticsHorizontalChart";
import AnalyticsPieChart from "../chart/AnalyticsPieChart";
import AnalyticsDonutChart from "../chart/AnalyticsDonutChart";
import { FacetsJSON } from "../../types/FacetsJSON.types";
import { usagelogApi } from "../../api/usageReportApi";

const analyticsDropDownData = [
  "Journal",
  // "Country of Publishing Author",
  // "Country of Publication",
  "Author Country",
  "Publication Country",
  "Institution",
  "Publishers",
  "Subject",
  "Year of Publication",
  "Researcher",
  "Speaker",
  "Research Guides",
];

export type Facet = { val: any; count: number };
export type DoughnutType = ChartData<"doughnut", number[], string>;
export type PieType = ChartData<"pie", number[], string>;
export type BarType = ChartData<"bar", number[], string>;
export type LegendType = {
  name: string;
  color: string;
  count: number;
}[];
interface Props {
  show: boolean;
  title: string;
  chartData: FacetsJSON;
  isAuthorFinder: boolean;
  CoAuthorList?: any;
}

const AnalyticsModal = ({
  show,
  title,
  chartData,
  isAuthorFinder,
  CoAuthorList,
}: Props) => {
  const dispatch = useAppDispatch();

  const [count, setCount] = useState<string>("10");
  const [type, setType] = useState<string>(title);

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCount(event.target.value);
  };

  const handleFilter = (event: SelectChangeEvent) => {
    setType(event.target.value);
    if (event.target.value === "Author" || event.target.value === "Co-Author") {
      logBasicSearchUsageData(66);
    } else if (event.target.value === "Author Country") {
      logBasicSearchUsageData(67);
    } else if (event.target.value === "Publication Country") {
      logBasicSearchUsageData(68);
    } else if (event.target.value === "Institution") {
      logBasicSearchUsageData(69);
    } else if (event.target.value === "Publishers") {
      logBasicSearchUsageData(70);
    } else if (event.target.value === "Subject") {
      logBasicSearchUsageData(71);
    } else if (event.target.value === "Year of Publication") {
      logBasicSearchUsageData(72);
    } else if (event.target.value === "Journal") {
      logBasicSearchUsageData(117);
    } else if (event.target.value === "Researcher") {
      logBasicSearchUsageData(118);
    } else if (event.target.value === "Research Guides") {
      logBasicSearchUsageData(119);
    } else if (event.target.value === "Speaker") {
      logBasicSearchUsageData(120);
    }
  };

  const [finalList, setFinalList] = useState<any>([]);

  const handleArrayList = useCallback(() => {
    const facetData_author = isAuthorFinder
      ? CoAuthorList
      : chartData?.authors_tk?.buckets?.length;
    const facetData_author_address = chartData?.author_address?.buckets?.length;

    const facetData_journal = chartData?.journal_name?.buckets?.length;
    const facetData_countryname =
      chartData?.author_country_name?.buckets?.length;
    const facetData_countryofPublication =
      chartData?.primary_publisher_country?.buckets?.length;
    const facetData_publishers = chartData?.publisher_name?.buckets?.length;
    const facetData_subject = chartData?.subjects_name_l3?.buckets?.length;
    const facetData_yop = chartData?.yearfrom?.buckets?.length;
    const resercherFacet_data = chartData?.researcher_tk?.buckets?.length;
    const speakerrFacet_data = chartData?.speakers?.buckets?.length;
    const guideFacet_data = chartData?.guide_name_tk?.buckets?.length;

    const lengthArray: number[] = [
      facetData_author,
      facetData_journal,
      facetData_countryname,
      facetData_countryofPublication,
      facetData_author_address,
      facetData_publishers,
      facetData_subject,
      facetData_yop,
      resercherFacet_data,
      speakerrFacet_data,
      guideFacet_data,
    ];

    const tempArray = [
      isAuthorFinder ? "Co-Author" : "Author",
      ...analyticsDropDownData,
    ];
    const nameArr = lengthArray;

    type TestArr = {
      count: number;
      title: string;
    };

    const testArr: TestArr[] = nameArr?.map((x, i) => ({
      count: x,
      title: tempArray[i],
    }));

    const filterList = testArr.filter((val) => {
      return val.count !== 0;
    });

    setFinalList(filterList);
  }, [chartData, isAuthorFinder]);

  useEffect(() => {
    handleArrayList();
  }, [handleArrayList]);

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AnalyticsModal-Title"
      aria-describedby="AnalyticsModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          minWidth: "77vw",
          maxWidth: "80vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 700,
              fontSize: "1.75rem",
              width: "40%",
            }}
          >
            {type}
          </Text>
          <Box
            sx={{
              width: "50%",

              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text>Count</Text>
              <Select
                sx={{
                  width: "7vw",
                  ml: 1,
                  borderRadius: "0.5rem",
                  py: 0,
                  height: "42px",
                }}
                value={count}
                onChange={handleChange}
              >
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"20"}>20</MenuItem>
                <MenuItem value={"50"}>50</MenuItem>
                <MenuItem value={"100"}>100</MenuItem>
              </Select>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text>Filter By</Text>
              <Select
                sx={{
                  width: "14vw",
                  ml: 1,
                  borderRadius: "0.5rem",
                  py: 0,
                  height: "42px",
                }}
                value={type}
                onChange={handleFilter}
              >
                {finalList?.map((data: any) => {
                  console.log("Data Map", data);
                  return (
                    <MenuItem
                      sx={{ color: "#212121" }}
                      disableRipple
                      value={data.title}
                    >
                      {data.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>

            <IconButton onClick={() => dispatch(hideModal())}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Chart */}
        {type === "Subject" && (
          <AnalyticsPieChart
            chartData={chartData?.subjects_name_l3?.buckets}
            count={Number(count)}
            type="subject"
          />
        )}

        {type === "Journal" && (
          <AnalyticsPieChart
            chartData={chartData?.journal_name?.buckets}
            count={Number(count)}
            type="journal"
          />
        )}

        {type === "Publishers" && (
          <AnalyticsHorizontalChart
            chartData={chartData?.publisher_name?.buckets}
            count={Number(count)}
            type="publisher"
          />
        )}

        {type === "Institution" && (
          <AnalyticsHorizontalChart
            chartData={chartData?.author_address?.buckets}
            count={Number(count)}
            type="institution"
          />
        )}

        {["Author", "Co-Author"].includes(type) && (
          <AnalyticsHorizontalChart
            chartData={
              isAuthorFinder ? CoAuthorList : chartData?.authors_tk?.buckets
            }
            count={Number(count)}
            type="author"
          />
        )}

        {type === "Researcher" && (
          <AnalyticsHorizontalChart
            chartData={chartData?.researcher_tk?.buckets}
            count={Number(count)}
            type="researcher"
          />
        )}

        {type === "Year of Publication" && (
          <AnalyticsBarChart
            chartData={chartData?.yearfrom?.buckets}
            count={Number(count)}
          />
        )}

        {type === "Author Country" && (
          <AnalyticsDonutChart
            chartData={chartData?.author_country_name?.buckets}
            count={Number(count)}
            type="copa"
          />
        )}

        {type === "Publication Country" && (
          <AnalyticsDonutChart
            chartData={chartData?.primary_publisher_country?.buckets}
            count={Number(count)}
            type="cop"
          />
        )}
        {type === "Speaker" && (
          <AnalyticsHorizontalChart
            chartData={chartData?.speakers?.buckets}
            count={Number(count)}
            type="speaker"
          />
        )}
        {type === "Research Guides" && (
          <AnalyticsHorizontalChart
            chartData={chartData?.guide_name_tk?.buckets}
            count={Number(count)}
            type="research"
          />
        )}
      </Box>
    </Modal>
  );
};

export default AnalyticsModal;
