import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hideModal } from "../../../store/slice/modalSlice";
import { useDispatch } from "react-redux";

interface PropType {
  isOpen: boolean;
}

export default function LoggingInfo(props: PropType) {
  const { isOpen } = props;
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(hideModal())}
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: { xs: "100%", sm: "400px", md: "550px", lg: "900px" },
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            Having trouble logging in?
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent sx={{ mt: "10px" }}>
        <Typography>
          1. If you had already created a user login on the older version i.e.,
          J-GatePlus,
        </Typography>

        <Typography sx={{ marginLeft: "18px" }}>
          please check your registered email account for an email regarding the
          new password for J-Gate Next.
        </Typography>

        <Typography>
          2. If you can't find the email mentioned in point 1, please reset your
          password using the Forgot Password option.
        </Typography>

        <Typography>
          3. New to the J-Gate family? Please contact your librarian for
          accessing J-Gate Next.
        </Typography>

        <Stack direction={"row"} gap={1} mt={"20px"}>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            onClick={() => dispatch(hideModal())}
          >
            OK
          </Button>
          <Button
            sx={{ minWidth: 150, color: "#111928" }}
            onClick={() => dispatch(hideModal())}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
