import { createTheme } from "@mui/material/styles";
import Font from "../assets/fonts/HelveticaNeue-Medium.otf";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#7852fb",
      light: "#DCD2EF",
    },
    secondary: {
      main: "#0E9F6E",
      light: "#DCD2EF",
    },
    green: {
      main: "#0E9F6E",
    },
  },
  typography: {
    fontFamily: "Helvetica Neue",
    button: {
      textTransform: "none",
      letterSpacing: 1,
    },
    h3: {
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Helvetica Neue",
          src: `url(${Font}) format("opentype")`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 0 0 0",
          border: ".2px solid #D1D5DB",
          borderRadius: "12px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
        root: {
          color: "#212121",
          fontWeight: "500",
          lineHeight: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    green: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    green?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export default Theme;
