import { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useGetUserProfile,
  useGetUserTypes,
  useMutateEditUserProfile,
  useMutateGetImageURL,
} from "../../api/Profile.api";
import { useAppSelector } from "../../store/hooks";
import { ProfileInputs } from "./index";

const useProfile = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ProfileInputs>();
  const { user_id, informaticscustomer_id } = useAppSelector(
    (state) => state.login
  );

  const imageUploadHandler = useMutateGetImageURL();
  const editProfileHandler = useMutateEditUserProfile();

  const [isEdit, setIsEdit] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  // const [userType, setUserType] = useState<string[]>([]);
  const [imageURL, setImageURL] = useState<string>("");

  const [openViewModal, setOpenViewModal] = useState<Boolean>(false);

  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => setOpenViewModal(false);

  const { data: types } = useGetUserTypes();
  const { data: me } = useGetUserProfile(user_id);
  

  //Prepopulate Form
  useEffect(() => {
    if (me && types) {
      // const arr = types?.map((x) => Object.keys(x)[0]);
      // setUserType(arr);

      setImageURL(me.profileuserimagepath ?? "");
      setValue("datprofiletype_id", me?.datprofiletype_id ?? "1"); //Default: Student
      setValue("firstname", me?.firstname ?? "");
      setValue("lastname", me?.lastname ?? "");
      setValue("emailid", me?.emailid ?? "");
      setValue("mobile", me?.mobile ?? "");
      setValue("department", me?.department ?? "");
      setValue("areaofinterest", me?.area_of_interest ?? "");
      setValue("libraryidnumber", me?.libraryidnumber ?? "");
      setValue("profile_type_id", me?.datprofiletype_id ?? "1");
      setValue("designation", me?.designation);
    }
  }, [me, setValue, types]);

  useEffect(() => {
    if (imageUploadHandler.isSuccess) {
      setImageURL(imageUploadHandler?.data.data?.access_url); //profileuserimagepath
      console.log("hiii")
    }
  }, [imageUploadHandler.data]);

  const handleEditClick = () => setIsEdit(true);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("blob", e.target.files[0]);

      formData.append("container_name", "profileImage");
      formData.append("customer_id", informaticscustomer_id);
      formData.append("user_id", user_id);

      imageUploadHandler.mutate({ formData });
    }
  };

  const onSubmit: SubmitHandler<ProfileInputs> = (data) => {
    let profileData: any = data;

    profileData["profileuserimagepath"] = imageURL !== "" ? imageURL : "";

    profileData["usermaster_id"] = user_id;
    const apiFormData = {
      data: [data],
    };

    const formData = new FormData();
    formData.append("userdetailsjson", JSON.stringify(apiFormData));
    editProfileHandler.mutate({ formData });

    setIsEdit(false);
  };

  return {
    handleSubmit,
    control,
    errors,
    isEdit,
    isAuthor,
    imageURL,
    setImageURL,
    setIsAuthor,
    openViewModal,
    handleOpenViewModal,
    handleCloseViewModal,
    handleEditClick,
    handleFileChange,
    onSubmit,
  };
};

export default useProfile;
