import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GetSearchAlertData } from "../../../types/GetSearchAlerts.types";
import DeleteSearchModal from "./DeleteSearch.modal";
import { useState } from "react";
import { deleteSavedSearch } from "../../../api/searchHistoryapi";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentAlert: GetSearchAlertData | undefined;
  refetch: any;
  isSearchHishtory?: boolean;
}

const SearchAlertMenu = ({
  open,
  onClose,
  anchor,
  currentAlert,
  refetch,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const deleteAlert = async () => {
    const search_log_ids = currentAlert?.alert_id;
    if (!search_log_ids) return;

    deleteSavedSearch(search_log_ids).then(() => {
      refetch();
      onClose();
      setIsOpen(false);
    });
  };

  const deleteModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "red" }} onClick={deleteModal}>
          Delete
        </MenuItem>
      </Menu>
      <DeleteSearchModal
        show={isOpen}
        alertName={currentAlert?.searchterms?.split("titleKeywordsAbs:")[1]!}
        onClose={() => setIsOpen(!isOpen)}
        deleteAlert={deleteAlert}
      />
    </>
  );
};

export default SearchAlertMenu;
