import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {  useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// import {
//   updateBrowserPage,
//   updateBrowserRow,
//   updateBrowserPage as updateBrowserPagePublisher,
//   updateCurrentUpdate,
//   clearAllFilters, 
//   updatepublishercollection,
// } from "../../store/slice/browserFilterSlicePublisher";
// import { setSearchKeywordPublisher } from "../../store/slice/browserPublisher";
// import { updateknownAlphasearch } from "../../store/slice/browseJournal";
import { MathJax } from "better-react-mathjax";
import { ReducerObj } from "../types/interface";
import { clearAllFilters, updateBrowserPage, updateBrowserRow, updateCurrentUpdate, updatepublishercollection } from "../store/slice/browserFilterSlicePublisher";
import { getAllPublisher } from "../api/browserGetPublisher";
import objectToQueryString from "../screens/browseJournal/objToQuery";
import { publisherSearchResult, setSearchKeywordPublisher } from "../store/slice/browserPublisher";
import { updateknownAlphasearch } from "../store/slice/browseJournal";
import Pagination from "../components/pagination";
import NoResultModal from "../components/modals/NoResultFound";
import { useAppSelector } from "../store/hooks";

function createData(name: string, calories: string) {
  return {
    name,
    calories,

    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

export default function Publisher(props: any, { filters }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let session_key = sessionStorage.getItem("session_key");
  let userId = sessionStorage.getItem("user_id");

  const publisherResults = useSelector(
    (state: any) =>
      state.browserPublisher?.browserResult?.grouped?.publisher_name_tk
  );
  // browserFilterSlicePublisher
  const rows = useSelector(
    (state: any) => state.browserFilterSlicePublisher.rows
  );
  // ngroups

  const currentPage = useSelector(
    (state: any) => state.browserFilterSlicePublisher.page
  );

  const setAlphabet: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher.selectAlphabet
  );
  let count = Math.ceil(publisherResults?.ngroups / rows);

  const [noresultModal, setNoResultModal] = React.useState<any>(false);

  const [journalSearchResults, setJournalSearchResults] = React.useState<any[]>(
    []
  );
  const [message, setMessage] = React.useState({ messages: "", open: false });
  const onlybooks = useAppSelector((state)=>state.browseFilterSlice.onlyBooks);

  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );

  const keyWords = useSelector(
    (state: any) => state.browserPublisher?.searchKeyword
  );

  const independentFilter = useSelector(
    (state: any) => state.browserFilterSlicePublisher.currentUpdate
  );

  const publisherDetails: any = useSelector(
    (state: any) =>
      state.browserPublisher?.browserResult?.grouped?.publisher_name_tk?.groups
  );

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };
  async function closeNoResultModal() {
    dispatch(clearAllFilters());
    // dispatch(updateBrowserPagePublisher(1));
    dispatch(updatepublishercollection(""));
    setNoResultModal(false);
    props.setFilters("");
    props.setMyPersonalLibraryPublisher("");
  }

  const setJournalResults = async () => {
    try {
      let subjects = "";
      let dataTypes = "";

      if (searchReducer.subjectType?.length > 0) {
        searchReducer.subjectType?.map((element: any, index: any) => {
          subjects += `${element} OR `;
        });
        subjects = `(${subjects.slice(0, -3)})`;
      }

      if (searchReducer?.defaultDataType?.length > 0) {
        searchReducer?.defaultDataType?.map((element: any, index: any) => {
          dataTypes += `${element} OR `;
        });
        dataTypes = `(${dataTypes.slice(0, -3)})`;
      }

      let temp: String[] = [];
      let resourceList = "";

      keyWords?.map((element: any) => {
        if (element.searchType === "Starting With") {
          resourceList += `${element.words.replace(/ /g, "\\ ")}* AND `;
        } else if (element.searchType == "Containing") {
          resourceList += `*${element.words.replace(/ /g, "\\ ")}* AND `;
        }
      });

      resourceList = encodeURIComponent(`${resourceList.slice(0, -4)}`);
      let apiJournalData: any = {
        page: currentPage,
        rows: rows,
        profileuserid: userId,
        sessionid: session_key,
        facet_fields:
          "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,publisher_name_initial",
        sort: "publisher_name_tk asc",
        op_mode: "and",
        fl: "publisher_name_tk, primary_publisher_country,publisher_id",
        group_field: "publisher_name_tk",
        group_ngroups: true,
        fq__subjects_id_l2: subjects,
        json_facet: JSON.stringify({
          subjects_name_l2: {
            type: "terms",
            field: "subjects_name_l2",
            limit: 100,
            facet: {
              subjects_name_l3: {
                type: "terms",
                field: "subjects_name_l3",
                limit: 60,
              },
            },
          },
        }),
      };
      let publisherId;
      if (independentFilter)
        Object.assign(apiJournalData, {
          fq__dateofpublication: "[NOW/YEAR TO NOW/YEAR]",
          fq__createddate: "[NOW-180DAYS TO NOW]",
        });
      // if (subjects.length > 2)
      //   Object.assign(apiJournalData, { fq__subjects_id_l2: subjects });
      if (dataTypes.length > 2)
        Object.assign(apiJournalData, { fq__resource_type: dataTypes });
      if (resourceList.length > 2)
        Object.assign(apiJournalData, { publisher_name_tk: resourceList });
      if (setAlphabet !== "")
        Object.assign(apiJournalData, {
          // "(publisher_name_tk": `${setAlphabet}*)`,
          publisher_name_tk: `${setAlphabet}*`,
        });

        if(onlybooks){
          Object.assign(apiJournalData,{fq__has_chapter:onlybooks})
        }
      let response;

      if (props.myPersonalLibraryPublisher) {
        if (props.filters == undefined) {
          response = await getAllPublisher(
            "personalresourcecore?" + objectToQueryString(apiJournalData)
          );
        } else {
          response = await getAllPublisher(
            "personalresourcecore?" +
              objectToQueryString(apiJournalData) +
              props.filters
          );
        }
      } else {
        if (props.filters == undefined) {
          response = await getAllPublisher(
            "bookscore?" + objectToQueryString(apiJournalData)
          );
        } else {
          response = await getAllPublisher(
            "bookscore?" +
              objectToQueryString(apiJournalData) +
              props.filters
          );
        }
      }
      if (
        props.filters &&
        props.filters.includes("&fq__fulltext_ACL=") &&
        response?.data.data.grouped.publisher_name_tk.groups.length === 0
      ) {
        // dispatch(updateLoadingState(false));

        setMessage({
          messages:
            "There are no configured subjects nor subscription information for your library . Please contact your library administrator.",
          open: true,
        });
        return;
      }
      console.log("response.message", response);
      if (
        response?.data.data.grouped.publisher_name_tk.ngroups == 0 ||
        response == undefined
      ) {
        setNoResultModal(true);
      }
      dispatch(publisherSearchResult(response?.data.data));

      let enabledTagsList: any[] = ["ALL", "(0-9)"];
      if (setAlphabet === "") {
        response?.data.data.facets.facet_fields?.publisher_name_initial?.map(
          (ele: any) =>
            Object.keys(ele)[0]?.charAt(0)?.toUpperCase().match(/[a-z]/i)
              ? enabledTagsList.push(
                  Object.keys(ele)[0]?.charAt(0)?.toUpperCase()
                )
              : null
        );
        dispatch(updateknownAlphasearch(enabledTagsList));
      }
      // setApiPage(apipage + 1);
      // setJsonFacet(response?.data.data.facets.facet_fields);
      // apiResults = [...response?.data.data.docs];

      // let apiJson: any = {
      //   usermaster_id: userId,
      //   resourcemaster_ids: [],
      // };

      // if (response?.data.data.docs.length > 0) {
      //   response?.data.data.docs?.map((element: any, index: any) => {
      //     apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.concat(
      //       element.resourcemaster_id
      //     );
      //   });
      // }
      // apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.toString();
      // const alertResponse = await setAlert("?" + objectToQueryString(apiJson));
      // setJournalSearchResults(response?.data.data.docs);
      // console.log("getPublisher", response?.data.data);
    } catch (err) {
      setNoResultModal(true);
    }
  };
  React.useEffect(() => {
    dispatch(updateCurrentUpdate(false));
  }, []);
  React.useEffect(() => {
    setJournalResults();
  }, [
    props.selectedTagPublisher,
    keyWords,
    props.filters,
    currentPage,
    independentFilter,
    rows,
    props.myPersonalLibraryPublisher,
    onlybooks
  ]);

  React.useEffect(() => {
    if (props.publisher) {
      props.setpublisher(false);
      setJournalResults();
    }
    return () => {
      dispatch(setSearchKeywordPublisher([]));
    };
  }, [props.publisher]);

  function handlePageChange(p: any) {
    dispatch(updateBrowserPage(p));
  }
  function getRowsPerPage(value: number) {
    dispatch(updateBrowserRow(value));
  }
  return (
    <Box>
      <TableContainer component={Paper} sx={{ marginTop: "5%" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F9FAFB" }}>
              <TableCell>Publisher Name</TableCell>
              <TableCell>Country</TableCell>

              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {publisherDetails?.map((element: any) => {
              return (
                <TableRow>
                  <TableCell>
                    <MathJax>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: element?.doclist?.docs[0]?.publisher_name_tk,
                        }}
                      />
                    </MathJax>
                  </TableCell>
                  <TableCell>
                    {element?.doclist?.docs[0].primary_publisher_country}
                  </TableCell>

                  <TableCell>
                    <ArrowForwardIosIcon
                      onClick={() =>
                        navigate("/BookSearch/PackageArticle", {
                          state: {
                            publisherId:
                              element?.doclist?.docs[0]?.publisher_id[0],
                            publisherName:
                              element?.doclist?.docs[0]?.publisher_name_tk,
                            publisherFilter: props.filters,
                            packageDetails: element?.doclist?.docs[0],
                            from: "bookpublisher",
                          },
                        })
                      }
                      fontSize="small"
                      style={{ alignSelf: "center" }}
                    ></ArrowForwardIosIcon>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={message.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message.messages}
        </Alert>
      </Snackbar>
      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={count}
          handlePageChange={handlePageChange}
          rowsPerResult={rows}
          getRowsPerPage={getRowsPerPage}
          currentPage={currentPage}
        />
        {/* <Spinner isLoading={isLoading} /> */}
      </Box>
      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />
    </Box>
  );
}

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "0.3%",
    paddingBottom: "0.3%",

    alignContent: "center",
  },
  text: {
    fontSize: "0.875rem",
    alignSelf: "center",
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontSize: "1rem",
    paddingTop: "1%",
    paddingBottom: "1%",
    backgroundColor: "#f5f5f5",
  },
};
