import api from "./endpoints";
import axios from 'axios';

const signupUrl = api.baseApi + '/User/create_informatics_profile_user/create_informatics_profile_user'

const deleteProfileURL = api.baseApi + '/api/delete_profileuser_account/delete_profileuser_account'

export const userSignup = ({ userdetailsjson, password }: any) => {
    return new Promise(async (resolve, reject) => {
        console.log('API FORM DATA', userdetailsjson, password)

        try {
            // const res = await axios.post(signupUrl, { userdetailsjson: userdetailsjson, password: password },
            const res = await axios.post(signupUrl, { userdetailsjson: JSON.stringify(userdetailsjson), password: password },

                {
                    headers: {
                        Accept: 'application/json', 'Content-Type': 'multipart/form-data'
                    },

                })

            console.log('QUERY FORMAT', signupUrl, { userdetailsjson, password })
            console.log("SUCCESS SIGNUP", res.data.data[0][0])

            if (
              res.data.data[0][0].status === "Success" ||
              [
                "User already exists",
                "Account deactivated. Want to reactivate?",
              ].includes(res.data.data[0][0].message)
            ) {
              resolve(res.data);
            }
        } catch (error: any) {
            console.log(error.message)
            console.log(error)
            reject(error);
        }
    })
}



export async function deleteProfileUser(usermaster_id: any, csrf_token: any) {
    console.log('Search Log ID', usermaster_id)

    try {
        const res = await axios.post(deleteProfileURL, { usermaster_id: usermaster_id },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-CSRFToken': csrf_token,
                },
            });


        if (res.data.data[0][0] === 'Success') {
            return res;
        }


    } catch (error) {
        console.log("error", error)
    }

}
