import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
    isLoading: boolean;
    data: string[];
    message: string;
}

const initialState: InitialstateType = {
    isLoading: false,
    data: [],
    message: "",
}


export const searchHistoryAlertSlice = createSlice({
    name: "searchHistoryAlertSlice",
    initialState,
    reducers: {
        searchHistoryAlertResults(state, { payload }) {
            state.data = payload.data;
        },
        searchHistoryAlertSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data[0];
            // console.log('PAYLOAD DATAAAAA', payload.data[0][0])
            state.message = payload.message;
        },
    },
});

export const { searchHistoryAlertResults, searchHistoryAlertSuccess } = searchHistoryAlertSlice.actions;

export default searchHistoryAlertSlice.reducer;

