import axios from "axios";
import { updateLoadingState } from "../store/slice/browseJournal";
import api from "./endpoints";
let userId = sessionStorage.getItem("user_id");
let session_key = sessionStorage.getItem("session_key");

export async function getAllBrowserJournalOnClick() {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=15&resource_name_fz=*&fq__resource_type=( 1 OR 3 )&facet_fields=resource_source_index,primary_publisher_country,subjects_name_l3,resource_name_initial&sort=resource_name_tk asc&op_mode=and`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getBrowserDetailsOnSort(
  val: string,
  getKeywords: string
) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=15&resource_name_fz=${getKeywords}&fq__resource_type=( 1 OR 3 )&facet_fields=resource_source_index,primary_publisher_country,subjects_name_l3,resource_name_initial&sort=resource_name_tk asc&op_mode=and&sort=${val}`
    );
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getAllBrowserJournal(keyWord: string) {
  console.log("uerid", userId);

  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&profileuserid=${userId}&rows=10&sessionid=${session_key}&resource_name_fz=${keyWord}`
    );
    return response.data.data.docs;
  } catch (error) {
    console.log(error);
  }
}

export async function browserJournalSearch(keyWord: string) {
  console.log("uerid", userId);

  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=15&resource_name_fz=${keyWord}&fq__subjects_id_l2=(35 OR 36)&fq__resource_type=1&facet_fields=publisher_name,primary_publisher_country&sort=resource_name_tk asc&op_mode=and&`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function browserJournalContainingSearch(keyWord: string) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=15&(resource_name_fz=(${keyWord}) +OR+resource_shortname_fz:(${keyWord}))&fq__subjects_id_l2=(35%20OR%2036)&fq__resource_type=1&facet_fields=publisher_name,primary_publisher_country&sort=resource_name_tk%20asc&op_mode=and`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function browseJournalStartingWith(keyword: string) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=15&(resource_name_tk=${keyword}\\ of* OR resource_shortname_tk=${keyword}\\ of*)&(resource_name_tk=journal\\ of* OR resource_shortname_tk=journal\\ of*)&fq__subjects_id_l2=(35%20OR%2036)&fq__resource_type=1&facet_fields=publisher_name,primary_publisher_country&sort=resource_name_tk%20asc&op_mode=and&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix`
    );
    console.log("responseApiOfSearch", response);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function resourceSettingAlert(resourceIdList: string[]) {
  console.log("resourceIdList", resourceIdList.toString());
  let id = resourceIdList.toString();
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/check_resource_alert_setting_status/check_resource_alert_setting_status?usermaster_id=${userId}&resourcemaster_ids=${id}`
    );
    return response.data.data[0];
  } catch (err) {
    console.log(err);
  }
}

export async function markFav(
  resourceId: string,
  isFav: boolean,
  loginDetails: any
) {
  console.log("resId", resourceId);

  const apiFormData = {
    usermaster_id: loginDetails.user_id || userId,
    resource_ids: [+resourceId],
    is_favourite: isFav,
  };

  const formData = new FormData();
  formData.append("userjson", JSON.stringify(apiFormData));

  try {
    const response = await axios.post(
      api.baseApi + `/api/mark_resources_favorite/mark_resources_favorite`,
      formData
    );

    return response.data.data[0];
  } catch (err) {
    console.log(err);
  }
}

// SEARCH BY

export async function handleAlphabet(selectedTag: any, getKeywords: string) {
  console.log("selectedTag", selectedTag);
  try {
    let response = await axios.get(
      `${api.baseApi}/api/indexing/solr/resourcecore?page=1&rows=10&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__resource_type=(1 )&((resource_name_tk=${getKeywords}\ of*  OR resource_shortname_tk=${getKeywords}\  of*))&profileuserid=${userId}&sessionid=${session_key}&fq__resource_name_initial=${selectedTag}`
    );

    return response.data.data;
  } catch (err) {
    console.log(err);
  }
}

export async function browserJournalContainingSearchOne(keyWord: string) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/resourcecore${keyWord}`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

// REFURBISHED CODE

export async function getListOfJournal(keyWord: string) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/${keyWord}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function searchResourceCoreFacet(query: string) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/resourcecore${query}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAlertDetails(keyWord: string) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/check_resource_alert_setting_status/check_resource_alert_setting_status${keyWord}`
    );

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getBookAlertDetails(keyWord: string) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/check_sub_resource_alert_status/check_sub_resource_alert_status?usermaster_id=${userId}&resourcemaster_ids=${keyWord}`
    );

    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export async function setFav(keyWord: any) {
  // console.log("keyWordwowowo", keyWord);

  try {
    const response = await axios.get(
      api.baseApi +
        `/api/mark_resources_favorite/mark_resources_favorite${keyWord}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

// BROWSER JOURAL DYNAMIC SEARCH

export async function browserJournalDetailSearch(data: any, dispatch: any) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/articlecore2${data}`
    );

    dispatch(updateLoadingState(false));

    return response;
  } catch (error) {
    error && dispatch(updateLoadingState(false));

    console.log(error);
  }
}

export async function browserJournalGetVolume(data: any) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/resourceissuecore${data}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function browserAutoSuggestion(data: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&sort=resource_name_tk asc&op_mode=and&rows=100&${data}&fl=resource_name&json_facet={"resource_name_tk":{"type":"terms","field":"resource_name_tk","limit":100,"sort":"index asc"}}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function booksAutoSuggestion(data: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/bookscore?page=1&sort=book_name_tk asc&op_mode=and&rows=100&${data}&fl=book_name&json_facet={"book_name_tk":{"type":"terms","field":"book_name_tk","limit":100,"sort":"index asc"}}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

// JOURNAL LOCK
export async function journalLock(data: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/get_resource_master_access_types/get_resource_master_access_types${data}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function publisherAutoSuggestion(data: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?${data}&sessionid=${session_key}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function bookPublisherAutoSuggestion(data: any) {
  try {
    const response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/bookscore?${data}&sessionid=${session_key}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}


export async function searchBookCoreFacet(query: string) {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/bookscore${query}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export const getRefrencesApi =  async (doi:any) => {
  try {
    const response = await axios.get( api.baseApi + `/api/get_reference_data/get_reference_data?article_doi=${doi}`);
    return response?.data;
  } catch (error) {
    console.log(error,'Get Refrences Api -  BrowseJournal.tsx');
  }
}

export const getCiteApi = async (id:any,count:any)=>{
  try {
    const response = await axios.get( api.baseApi + `/api/get_all_cited_records/get_all_cited_records?openalex_id=${id}&cited_count=${count}`);
    return response?.data;
  } catch (error) {
    console.log(error,'Get Cite Api -  BrowseJournal.tsx');
  }
}

export const getSingleArticleDetails =  async (id:any) => {
  try {
    const response = await axios.get(
      api.baseApi + `/api/indexing/solr/articlecore2?fq__article_id=${id}`
    );
    return response;
  } catch (error) {
    console.log(error,'Get Article -  BrowseJournal.tsx');
  }
}