import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isLoading: false,
    isAuth: false,
    data: '',
    message: '',
    error: '',
};

const userType = createSlice({
    name: "usertype",
    initialState,
    reducers: {
        usertypePending: (state) => {
            state.isLoading = true;
        },
        usertypeSuccess: (state, { payload }) => {
            console.log('Payload signup data', payload.data)
            state.isLoading = false;
            state.data = payload.data;
            state.message = payload.message;
        },
        usertypeFail: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload;
            state.error = payload;
            state.message = payload;
        },
    },
});

const { reducer, actions } = userType

export const { usertypePending, usertypeSuccess, usertypeFail } = actions

export default reducer;


