import React, { createContext, useState } from "react";

import type { ThemeData, Theme } from "../types";
import { LIGHT_THEME } from "../utils/constants";

type Props = {
  children: React.ReactNode;
}

const ThemeContext = createContext<ThemeData>({
  theme: LIGHT_THEME,
  updateTheme: () => {},
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight
});

const ThemeProvider: React.FC<Props> = ({ children }) => {

  const [theme, updateTheme] = useState<Theme>(LIGHT_THEME);
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  return(
    <ThemeContext.Provider
      value={{ theme, updateTheme, windowHeight, windowWidth }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

const useTheme = (): ThemeData => {
  return React.useContext(ThemeContext);
}

export {
  ThemeProvider,
  useTheme,
  ThemeContext
}