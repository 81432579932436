import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Control, Controller, UseFormHandleSubmit } from "react-hook-form";
import CloseIcon from "@mui/icons-material/CloseOutlined";

interface SearchInput {
  searchterm: string;
}

interface PropType {
  fieldName: string;
  options: any;
  control: Control<any, any>;
  handleSubmit: UseFormHandleSubmit<SearchInput>;
  handleSearch: any;
  handleAutoCompleteSelect?: any;
  setValue: any;
  fullWidth?: boolean;
  loading?: boolean;
}

export default function AutoCompleteField(props: PropType) {
  const {
    fieldName,
    options,
    control,
    handleSearch,
    handleSubmit,
    handleAutoCompleteSelect,
    setValue,
    fullWidth,
    loading = false,
  } = props;

 const handleOnInputChange = (value:string,reason:string) =>{
    if(reason === "reset" && value !== ""){
      handleAutoCompleteSelect(value);
    }           
 }

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={""}
        rules={{ required: "Try for some appropriate keywords / terms" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            sx={{
              width: !fullWidth
                ? { xs: "90%", sm: "300px", md: "300px", lg: "600px" }
                : "100%",
              position: "relative",
            }}
            freeSolo={true}
            ListboxProps={{ style: { maxHeight: 150 } }}
            id="free-solo-2-demo"
            disableClearable
            onChange={(_, selectedVal, reason) =>{ 
              console.log(reason)
              handleAutoCompleteSelect(selectedVal, reason);
            }}
            onInputChange={(_, value,reason)=>{
              handleOnInputChange(value,reason)
            }}
            options={options || []}
            inputValue={value}
            autoSelect={false}
            filterOptions={(options, state) => {
              if (state.inputValue.length < 3) return [];
              if (state.inputValue.length > 2) {
                return options.filter((item) =>
                  String(item)
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }
              return options;
            }}
            PaperComponent={({ children }) => (
              <Paper
                elevation={8}
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "auto",
                }}
              >
                {children}
              </Paper>
            )}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  required
                  sx={{ backgroundColor: "#fff" }}
                  onChange={onChange}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(handleSearch)();
                    }
                  }}
                  placeholder="Enter your Topic Keywords"
                  fullWidth
                  error={!!error}
                  InputProps={{
                    ...params.InputProps,
                    // type: "search",
                    endAdornment: (
                      <InputAdornment position="end">
                        {!loading && value ? (
                          <IconButton
                            onClick={() => setValue("searchterm", "")}
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                />
                {error ? (
                  <Typography
                    fontSize={12}
                    sx={{ position: "absolute", color: "#e02424" }}
                  >
                    {error.message}
                  </Typography>
                ) : null}
              </>
            )}
          />
        )}
      />
    </>
  );
}
