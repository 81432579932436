import http from "./httpService";
import api from "./endpoints";
import axios from "axios";

let userId = sessionStorage.getItem("user_id");
export async function findInLibraryApi(element: any) {
  try {
    let data = await axios.get(
      api.baseApi +
        `/api/get_find_in_library_address_details/get_find_in_library_address_details?customerId=1877&article_id=21380619`
    );
    console.log("data.data.data", data.data.data);

    return data.data.data;
  } catch (error) {}
}

export async function getFindInLibraryAddessListCall(element: any) {
  try {
    let data = await axios.get(
      `${api.baseApi}/api/get_find_in_library_address_details/get_find_in_library_address_details${element}`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getFindInLibraryArticleId(element: any) {
  try {
    let data = await axios.get(
      api.baseApi +
        `/api/get_find_in_library_member_list/get_find_in_library_member_list${element}`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
}
