import { folderStyles as style } from "./Folders.style";
import { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Stack from "@mui/material/Stack";
import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import FolderDetailCard from "./FolderDetailCard";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { getFolderArticles } from "../../../api/MyLibrary/MyFolders.api";
import {
  GetFolderArticlesData,
  GetMyFoldersData,
} from "../../../types/GetMyFolders.types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import {
  updateMarkedArticleId,
  updateMarkedResult,
  updateSavedArticles,
} from "../../../store/slice/searchSlice";
import { useQuery } from "@tanstack/react-query";
import Pagination from "@mui/material/Pagination";
import { Typography } from "@mui/material";
import { Loader } from "../../../components/spinner";
import { useMyFolder } from "./MyFolder.hook";
import { updateCollectionValue } from "../../../store/slice/filterSlice";

type StateType = {
  folderId: number;
  folderName: string;
  allFolders: GetMyFoldersData[];
};

const MyFolderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    state,
  }: {
    state: StateType;
  } = useLocation();
  const folderId = state.folderId;
  const userId = useAppSelector((state) => state.login.user_id);
  const markedArticlesid = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );

  const [enableSearch, setEnableSearch] = useState("");
  const [search, setSearch] = useState("");
  const [currentFolderName, setCurrentFoldername] = useState("");
  const [articles, setArticles] = useState<GetFolderArticlesData>();
  const [hitCount, setHitCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [markResult, setMarkResult] = useState(false);
  const [markedData, setMarkdeData] = useState<string[]>(markedArticlesid);

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const { handleTableCellClick } = useMyFolder();

  console.log("articles", articles);
  useQuery({
    queryKey: [
      "GetFolderArticles",
      userId,
      folderId,
      page,
      pageSize,
      enableSearch,
    ],
    queryFn: () =>
      getFolderArticles(userId, folderId, page, pageSize, enableSearch, search),
    select: ({ data }): GetFolderArticlesData => data?.data?.data,
    onSuccess(data) {
      setArticles(data);
      const dataArticle = data?.docs?.map((item) => ({
        article_id: item?.article_id,
        is_saved: true,
        folder_id: folderId,
      }));
      dispatch(updateSavedArticles(dataArticle));
      setHitCount(Math.ceil(data.hits / pageSize));
    },
  });

  useEffect(() => {
    setCurrentFoldername(state.folderName);
  }, [state.folderName]);

  // useEffect(() => {
  //   isArticleBookmarked()
  // }, [location?.pathname, currentFolderName])

  // const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateCollectionValue(""));
  }, []);

  useEffect(() => {
    setMarkResult(markedData?.length === articles?.docs?.length);
  }, [articles?.docs?.length, markedData?.length]); //Remove articles?.docs?.length if err occurs

  const handleFolderChange = (folder: GetMyFoldersData) => {
    handleTableCellClick({
      id: folder.userfolder_id,
      name: folder.folder_name,
    });
    setCurrentFoldername(folder.folder_name);
  };

  const handleOnSearchChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => setSearch(currentTarget.value);

  const handleSearch = () => {
    if (!search) {
      setEnableSearch("");
      return;
    }
    setPage(1);
    setEnableSearch(search);
  };

  const handleClear = () => {
    setSearch("");
    setEnableSearch("");
  };

  const handlePerPage = (event: SelectChangeEvent) => {
    setPageSize(Number(event.target.value));
  };

  const selectAllArticles = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const dataArray = articles?.docs;

    if (!Array.isArray(dataArray)) return;

    if (target?.checked) {
      let filteredMarkedIds = [];
      let filteredMarkedData = [];
      let articlesIds = dataArray?.map((obj) => obj?.article_id);

      for (let id of articlesIds) {
        if (!markedArticlesid.includes(id)) filteredMarkedIds.push(id);
      }

      for (let obj of dataArray) {
        if (filteredMarkedIds.includes(obj?.article_id))
          filteredMarkedData.push(obj);
      }

      dispatch(updateMarkedResult([...markedResult, ...filteredMarkedData]));
      dispatch(
        updateMarkedArticleId([...markedArticlesid, ...filteredMarkedIds])
      );
      setMarkdeData(articlesIds);
    } else {
      setMarkdeData([]);
      // curr res ids
      let articlesIds = dataArray?.map((obj) => obj?.article_id);

      // check the current res id in marked articls ids and remove all
      let filteredMarkedIds = [];
      let filteredMarkedData = [];

      for (let id of markedArticlesid) {
        if (!articlesIds.includes(id)) filteredMarkedIds.push(id);
      }

      dispatch(updateMarkedArticleId(filteredMarkedIds));

      // keep the data in mark result that matches  "filteredMarkedIds"
      for (let obj of markedResult) {
        if (filteredMarkedIds.includes(obj?.article_id))
          filteredMarkedData.push(obj);
      }

      dispatch(updateMarkedResult(filteredMarkedData));
    }
  };

  const getMarkedResult = (data: any, id: string) => {
    // Handle id
    if (markedArticlesid.includes(id)) {
      let filterIds = markedArticlesid.filter((data) => data !== id);
      let filterData = markedResult.filter(
        (data: any) => data?.article_id !== id
      );
      dispatch(updateMarkedResult(filterData));
      dispatch(updateMarkedArticleId(filterIds));
      setMarkdeData((prev) => prev.filter((obj) => obj !== id));
    } else if (markedArticlesid?.length) {
      let articlesIds = [...markedArticlesid, id];
      let markedResultData = [...markedResult, data];
      dispatch(updateMarkedResult(markedResultData));
      dispatch(updateMarkedArticleId(articlesIds));
      setMarkdeData((prev) => [...prev, id]);
    } else {
      dispatch(updateMarkedArticleId([id]));
      dispatch(updateMarkedResult([data]));
      setMarkdeData([id]);
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems="center"
        marginBottom={1}
        justifyContent={"space-between"}
      >
        <Text
          sx={{
            fontWeight: 600,
            fontSize: "1.5rem",
            color: "black",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon
            sx={{
              fontSize: 41,
            }}
          />
          {currentFolderName}
        </Text>
        <Text sx={{ ml: 1, textAlign: "right", fontWeight: "bold" }}>
          Total Document Count ({articles?.hits})
        </Text>
      </Stack>

      <Box sx={style.search}>
        <Box sx={style.searchWrapper}>
          <TextField
            sx={style.textfield}
            value={search}
            onChange={handleOnSearchChange}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClear}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <Button
            sx={style.searchButton}
            variant="outlined"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Box>

        <Box>
          <Text>Choose Folder</Text>
          <Select
            sx={style.select}
            value={currentFolderName}
            MenuProps={{
              sx: {
                maxHeight: "28vh",
              },
            }}
          >
            {state.allFolders &&
              state.allFolders?.map((folder, i) => (
                <MenuItem
                  key={i}
                  value={folder.folder_name}
                  onClick={() => handleFolderChange(folder)}
                >
                  {folder.folder_name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          sx={{ ml: "5px" }}
          onChange={selectAllArticles}
          checked={markResult}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          style={{
            alignSelf: "center",
            marginRight: "4%",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!markedData.length) return;
            navigate("/marked-result");
          }}
        >
          {markedData?.length ? `View Marked Results` : "Mark Results"}
          {markedData?.length > 0 ? "(" + markedData?.length + ")" : ""}
        </Typography>
      </Box>

      <Box sx={style.listWrapper}>
        {articles ? (
          Array.isArray(articles.docs) ? (
            articles.docs?.map((article, i) => (
              <FolderDetailCard
                key={i}
                data={article}
                getMarkedResult={getMarkedResult}
                markedArticlesid={markedData}
                folderId={folderId}
                articles={articles.docs}
              />
            ))
          ) : (
            <>No Articles in this Folder</>
          )
        ) : (
          <Loader height={"100%"} width={"100%"} />
        )}
      </Box>
      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={hitCount}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={(_, p) => setPage(p)}
        />
        <Select
          id="pageSize"
          value={pageSize.toString()}
          displayEmpty
          autoWidth
          onChange={handlePerPage}
          sx={{
            height: "32px",
            ml: 2,
          }}
        >
          <MenuItem value={15}>15 / Page</MenuItem>
          <MenuItem value={25}>25 / Page</MenuItem>
          <MenuItem value={50}>50 / Page</MenuItem>
          <MenuItem value={100}>100 / Page</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default MyFolderDetail;
