import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
  browserResult: string[];
  alertFav: string[];
  keyWord: string[];
  containStartingWith: string;
  setAlphabet: string;
  searchKeyword: string[];
  getCardDataDetails: any;
  markedArticleId: string[];
  markedResult: any;
}

const initialState: InitialstateType = {
  browserResult: [],
  alertFav: [],
  getCardDataDetails: [],
  keyWord: [],
  containStartingWith: "Starting With",
  setAlphabet: "",
  searchKeyword: [],
  markedArticleId: [],
  markedResult: [],
};

export const browseJournalSearchSlicePackage = createSlice({
  name: "browseJournalPackage",
  initialState,
  reducers: {
    browseJournalSearchResult(state, { payload }) {
      console.log("payload", payload);

      state.browserResult = payload;
    },
    alertsAndFav(state, { payload }) {
      state.alertFav = payload;
    },
    getCardData(state, { payload }) {
      state.getCardDataDetails = payload;
    },
    getSelectedKeyWord(state, { payload }) {
      state.keyWord = [...state.keyWord, payload];
    },
    getContainOrStartingWIth(state, { payload }) {
      state.containStartingWith = payload;
    },
    setKeyword(state, { payload }) {
      state.setAlphabet = payload;
    },
    setSearchKeyword(state, { payload }) {
      state.searchKeyword = [...state.searchKeyword, payload];
    },
    updateMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    updateMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
  },
});

export const {
  browseJournalSearchResult,
  alertsAndFav,
  getCardData,
  getSelectedKeyWord,
  getContainOrStartingWIth,
  setKeyword,
  setSearchKeyword,
  updateMarkedArticleId,
  updateMarkedResult,
} = browseJournalSearchSlicePackage.actions;

export default browseJournalSearchSlicePackage.reducer;
