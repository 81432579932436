import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { useAppSelector } from "../../../store/hooks";
import { alertStyles as styles } from "../../../screens/MyLibrary/MyAlerts/Alerts.style";
import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Colors } from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { setThesisAlert } from "../../../api/MyLibrary/Thesis.api";
import { usagelogApi } from "../../../api/usageReportApi";
import { notify } from "../../../utils/Notify";

interface Props {
  show: boolean;
  onClose: () => void;
  alertData: any;
  isSavedSearch?: boolean;
  refetch?: any;
  subjectID: any;
  resourceId: any;
  currentAlert: any;
  alertValue: any;
  alertTopicData: any;
  frequencyData: any;

  favSubId?: string;
}

export const frequencyMap = [
  {
    i: "1",
    freq: "Daily",
  },
  {
    i: "2",
    freq: "Weekly",
  },
  {
    i: "3",
    freq: "Bi-Weekly",
  },
  {
    i: "4",
    freq: "Monthly",
  },
  {
    i: "5",
    freq: "Quarterly",
  },
];

const AlertModal = ({
  show,
  onClose,
  refetch,
  subjectID,
  resourceId,
  alertValue,
  alertTopicData,
  frequencyData,
}: Props) => {
  const selector = useAppSelector((state) => state.login);

  const [wasAlertPreviouslySet, setWasAlertPreviouslySet] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isRSS, setRSS] = useState(false);
  const [alertTopic, setAlertTopic] = useState("");
  const [selectedFreq, setSelectedFreq] = useState<string>("2");

  const profileData = useAppSelector((state) => state.login);
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  useEffect(() => {
    setIsEmail(alertValue === 3 || alertValue === 1);
    setRSS(alertValue === 3 || alertValue === 2);
    setWasAlertPreviouslySet(alertValue > 0);
    setAlertTopic(alertTopicData);
    setSelectedFreq(frequencyData ?? "2"); // Default it to weekly=>"2"
  }, [alertValue, alertTopicData, frequencyData]);

  useEffect(() => {
    if (!show) {
      //clear
      setIsEmail(false);
      setRSS(false);
      setAlertTopic("");
      setSelectedFreq("2");
      setWasAlertPreviouslySet(false);
    }
  }, [show]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFreq(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmail(event.target.checked);
  };

  const handleRSS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRSS(event.target.checked);
  };

  const logAlertUsageData = (emailActionID: any, rssActionID: any) => {
    if (isEmail) {
      usagelogApi(
        profileData.user_id,
        profileData?.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        emailActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    if (isRSS) {
      usagelogApi(
        profileData.user_id,
        profileData?.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        rssActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    // isEmail
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData?.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       emailActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
    // isRSS
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData?.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       rssActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
  };

  const handleAdd = async () => {
    let alertType = 0; //No Alert
    if (isEmail && isRSS) {
      alertType = 3;
    } else if (isEmail && !isRSS) {
      alertType = 1;
    } else if (isRSS && !isEmail) {
      alertType = 2;
    }

    if (alertType === 0 && !wasAlertPreviouslySet) {
      notify("warning", "Select at least one option to create an alert");
      return;
    }

    //logAlertUsageData(100, 101);

    try {
      const alertjson = {
        data: [
          {
            usermaster_id: selector.user_id,
            resource_type_id: resourceId,
            alert_topic: alertType === 0 ? null : alertTopic,
            mail_frequency: selectedFreq,
            alert_type_id: alertType,
            l3_subject_id: subjectID,
            fav_subject_id: resourceId,
          },
        ],
      };

      await setThesisAlert({ alertjson }).then(({ data }: any) => {
        if (alertType === 0 && wasAlertPreviouslySet) {
          notify("info", "Alert Removed Successfully");
        } else {
          notify("success", data.data[0][0]);
        }
      });

      setIsEmail(false);
      setRSS(false);
      setAlertTopic("");
      setSelectedFreq("");
      refetch();
    } catch (error) {
      console.log("ERRRRR", error);
    }
    onClose();
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="RSSAlertModal-Title"
      aria-describedby="RSSAlertModal-Description"
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          textAlign="left"
          fontFamily={"Lora"}
          fontWeight={700}
          fontSize={28}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Create New Email/RSS Alert
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box>
          <Text fontSize={16} fontWeight={500} textAlign="left" color="#212121">
            Alert Topic
          </Text>

          <TextField
            margin="normal"
            id="text"
            placeholder="Enter topic name"
            name="name"
            autoComplete="name"
            autoFocus
            value={alertTopic}
            onChange={(e: any) => setAlertTopic(e.target.value)}
            sx={{ width: "100%", background: "#fffff" }}
            fullWidth={true}
          />
        </Box>

        <Box sx={{ width: "100%", mb: 1 }}>
          <Text>Email Alert Frequency</Text>
          <Select
            value={selectedFreq}
            defaultValue={"2"}
            onChange={handleChange}
            displayEmpty
            sx={styles.frequency}
            inputProps={{ "aria-label": "Without label" }}
          >
            {frequencyMap?.map((item) => (
              <MenuItem value={item.i} key={item.i}>
                {item.freq}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <FormGroup sx={{ my: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEmail}
                value={isEmail}
                onChange={handleEmail}
              />
            }
            label="Email Alerts"
          />
          <FormControlLabel
            control={
              <Checkbox checked={isRSS} value={isRSS} onChange={handleRSS} />
            }
            label="RSS Alerts"
          />
        </FormGroup>
      </DialogContent>

      <DialogActions style={{ justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          onClick={handleAdd}
        >
          Save
        </Button>
        <Button sx={{ color: Colors.black }} onClick={() => onClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
