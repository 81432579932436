import { GetCustomerByIDData } from "../../types/GetCustomerByID.types";
import { GetUserProfileData } from "../../types/GetUserProfile.types";
import { CustomerDetailsType } from "../../types/interface";
import { createSlice } from "@reduxjs/toolkit";

export interface InitialstateType {
  customerDetails: GetCustomerByIDData | null;
  personalDetails: GetUserProfileData | null
}

const initialState: InitialstateType = {
  customerDetails: null,
  personalDetails: null
};

export const customerSlice = createSlice({
  name: "customerDetail",
  initialState,
  reducers: {
    updateCustomerDetails(state, { payload }) {
      state.customerDetails = payload;
    },
    clearCustomerDetails(state) {
      state.customerDetails = null;
      state.personalDetails = null;
    },
    updatePersonalDetails(state, { payload }) {
      state.personalDetails = payload;
    }
  },
});

export const { updateCustomerDetails, clearCustomerDetails, updatePersonalDetails } = customerSlice.actions;

export default customerSlice.reducer;
