import { Box, IconButton, TextField } from "@mui/material";
import { createCanvas } from "canvas";
import { useState, useEffect, ChangeEvent, useRef } from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

interface PropType {
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    captchaText: string
  ) => void;
  onCaptchaReset: () => void;
  isCaptchaVerified: boolean;
  onSubmit: (e: any) => void
}

export default function Captcha(props: PropType) {
  const { onChange, onCaptchaReset, isCaptchaVerified, onSubmit } = props;
  const [captchaText, setCaptchaText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    generateCaptcha();
  }, []);

  function generateCaptcha() {
    const canvas = createCanvas(200, 52);
    const ctx = canvas.getContext("2d");
    const numbers = "0123456789";

    let captchaString = "";

    for (let i = 0; i < 6; i++) {
      captchaString += numbers.charAt(
        Math.floor(Math.random() * numbers.length)
      );
    }
    setCaptchaText(captchaString);

    ctx.fillStyle = "#f5f5f5";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.font = "30px Lora";
    ctx.fillStyle = "#333";
    ctx.fillText(captchaString, 30, 40);

    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.strokeStyle = "#E0E0E0";
      ctx.stroke();
    }
    const imageData = canvas.toDataURL();
    setImageUrl(imageData);
  }

  function resetCaptchaString() {
    try {
      onCaptchaReset();
      generateCaptcha();
      if (ref?.current) ref.current.value = "";
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <img src={imageUrl} alt="captcha image" />
      <Box display={"flex"} alignItems={"center"}>
        <IconButton sx={{ p: 0 }} onClick={resetCaptchaString}>
          <RotateLeftIcon />
        </IconButton>
        <TextField
          placeholder="Enter Captcha"
          onChange={(e) => onChange(e, captchaText)}
          inputRef={ref}
          onKeyPress={(e: any) => {
            if(!isCaptchaVerified) return;
            if (e.key === "Enter") {
              onSubmit(e);
            }
          }}
        />
      </Box>
    </Box>
  );
}
