import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
    isLoading: boolean;
    data: string[];
    message: string;
}

const initialState: InitialstateType = {
    isLoading: false,
    data: [],
    message: "",
}


export const deleteSearchHistorySlice = createSlice({
    name: "deleteSearchHistorySlice",
    initialState,
    reducers: {
        deleteSearchHistoryResults(state, { payload }) {
            state.data = payload.data;
        },
        deleteSearchHistorySuccess: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data;
            // console.log('PAYLOAD DATAAAAA', payload.data[0][0])
            state.message = payload.message;
        },
    },
});

export const { deleteSearchHistoryResults, deleteSearchHistorySuccess } = deleteSearchHistorySlice.actions;

export default deleteSearchHistorySlice.reducer;

