export default function highlightKeyword(
  text: string,
  searchTerm: string[]
): string {
  try {
    let highlightedText = "";
    let pharseSearch = [];
    let normalSearch = [];

    const sanitizedSearchTerm = searchTerm.map((str) =>
      str.replace(/\s+/g, " ").trim()
    );

    for (let str of sanitizedSearchTerm) {
      if (str.charAt(0) === `"` && str.slice(-1) === `"`) {
        let keyword = str.replace(/['"]+/g, "");
        pharseSearch.push(keyword);
      } else {
        let keyword = str.split(" ");
        normalSearch.push(...keyword);
      }
    }

    if (pharseSearch.length) {
      highlightedText = text.replace(
        new RegExp(`\\b(${pharseSearch?.join("|")})\\b`, "gi"),
        "<mark>$1</mark>"
      );
    }
    if (normalSearch.length) {
      //Removing stop words from highlighting 
      //Selecting and/or keywords
      let wordsToRemove = ["and", "or"];
      normalSearch = normalSearch.filter(word => !wordsToRemove.includes(word.toLowerCase()));
      // highlighting the rest of the words.
      highlightedText = text.replace(
        new RegExp(`\\b(${normalSearch?.join("|")})\\b`, "gi"),
        "<mark>$1</mark>"
      );
    }

    if (!sanitizedSearchTerm.toString()) return text;

    return highlightedText;
  } catch (err) {
    console.log(err);
    return text;
  }
}

export function keywordHighlight(
  keywordData: any,
  searchTerm: string[],
  article_id: string,
  title: string
): string {
  try {
    let string = "";
    let isArticlePresent = Object.keys(keywordData).includes(article_id);

    if (isArticlePresent && keywordData[article_id.toString()].title_fz) {
      if (keywordData[article_id.toString()].title_fz) {
        if (Array.isArray(keywordData[article_id.toString()].title_fz))
          string = keywordData[article_id.toString()].title_fz[0];
      }
    } else if (string === "") {
      string = highlightKeyword(title, searchTerm);
    } else {
      string = title;
    }
    return string;
  } catch (err) {
    console.log(err);
    return "";
  }
}

// Function used in abstract page to highlight keywords section
export function highlightKeys(text: string, searchTerm: string[]): string {
  try {
    let highlightedText = "";
    let pharseSearch = [];
    let normalSearch = [];

    for (let str of searchTerm) {
      if (str.charAt(0) === `"` && str.slice(-1) === `"`) {
        let keyword = str.replace(/['"]+/g, "");
        pharseSearch.push(keyword);
      } else {
        let keyword = str.split(" ");
        normalSearch.push(...keyword);
      }
    }
    let combinedArr = [...pharseSearch, ...normalSearch];

    if (combinedArr.length) {
      highlightedText = text.replace(
        new RegExp(`\\b(${combinedArr?.join("|")})\\b`, "gi"),
        "<mark>$1</mark>"
      );
    }

    if (!searchTerm.toString()) return text;

    return highlightedText;
  } catch (err) {
    console.log(err);
    return text;
  }
}

// Highlight abstract
export function highlightAbstract(
  keywordData: any,
  searchTerm: string[],
  article_id: string,
  abstract: string
): string {
  try {
    let string = "";
    let isArticlePresent = Object.keys(keywordData).includes(article_id);

    if (isArticlePresent && keywordData[article_id.toString()].abstract) {
      if (keywordData[article_id.toString()].abstract) {
        if (Array.isArray(keywordData[article_id.toString()].abstract))
          string = keywordData[article_id.toString()].abstract[0];
      }
    } else if (string === "") {
      string = highlightKeyword(abstract, searchTerm);
    } else {
      string = abstract;
    }
    return string;
  } catch (err) {
    console.log(err);
    return "";
  }
}
