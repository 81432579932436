import http from "../httpService";

export type SearchContext =
  | "all"
  | "fulltext"
  | "myConsortia"
  | "myLibrary"
  | "myPersonal"
  | null;
type SearchFor = "records" | "count" | null;
type JsonFacets = "true" | "false" | null;
type LogHistory = boolean;

export async function setSearch(
  query: string,
  searchContext?: SearchContext,
  searchFor?: SearchFor,
  jsonFacets?: JsonFacets,
  logHistory?: LogHistory,
  collectionFor?: "all" | "fulltext"
) {
  let sessionKey = sessionStorage.getItem("session_key");
  let userId = sessionStorage.getItem("user_id");
  let informaticscustomerId = sessionStorage.getItem("informaticscustomer_id");

  // Set search contexts
  let search_context = "all"; // Can be all/fulltext/myConsortia/myLibrary/myPersonal
  let search_for = "records"; // Can be count or records
  let json_facets = true; // Boolean
  let collection_for = collectionFor ?? "all";

  if (searchContext) {
    search_context = searchContext;
  }

  if (searchFor) {
    search_for = searchFor;
  }

  if (jsonFacets === "false") {
    json_facets = false;
  }

  let apiUrl = "";
  if(logHistory) {
    apiUrl = `api/set_search/set_search?customerid=${informaticscustomerId}&session_id=${sessionKey}&profileuserid=${userId}&${query}&search_context=${search_context}&search_for=${search_for}&jsonFacets=${json_facets}&logsearchhistory=${true}`;
  } else {
    apiUrl = `api/set_search/set_search?customerid=${informaticscustomerId}&session_id=${sessionKey}&profileuserid=${userId}&${query}&search_context=${search_context}&search_for=${search_for}&jsonFacets=${json_facets}&collection_for=${collection_for}`
  }

  try {
    const { data: response } = await http.get(apiUrl);

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Results: ", error);
    return error;
  }
}
