import {
    IconButton,
    InputAdornment,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import { Controller, Control, FieldValues } from "react-hook-form";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import { useEffect, useRef, useState } from "react";
  
  interface PropType {
    name: string;
    control: Control<FieldValues, any>;
    placeholder?: string;
    type?: string;
    rules?: {};
    fullWidth?: boolean;
    disabled?: boolean;
    inputProps?: any;
    size?: "small" | "medium" | undefined;
    showeyeIcon?: boolean;
    eyeIconStatus?: boolean;
    handleIcon?: () => void;
    onKeyPress?: (e: any) => void;
    inputValue?: any;
  }
  
  const CustomInputSearch = (props: PropType) => {
    const {
      name,
      control,
      placeholder,
      type,
      rules,
      fullWidth,
      disabled,
      inputProps,
      size = undefined,
      showeyeIcon = false,
      eyeIconStatus = false,
      handleIcon,
      onKeyPress,
      inputValue,
    } = props;
    const ref = useRef("");
    const [onChangeRef, setOnChangeRef] = useState(null);
  
    useEffect(() => {
      if (onChangeRef) {
        // @ts-ignore
        onChangeRef({ target: { value: inputValue, name: name } });
      }
    }, [inputValue]);
    return (
      <Box>
        <Controller
          name={name}
          control={control}
          rules={rules}
          // @ts-ignore
          ref={(instance) => {
            ref.current = instance;
          }}
          render={({
            field: { onChange, value },
            formState,
            fieldState: { error },
          }) => {
            // @ts-ignore
            setOnChangeRef(() => onChange);
            return (
              <>
                <TextField
                  // value={value}
                  // @ts-ignore
                  value={inputValue?.length > 0 ? inputValue : value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  placeholder={placeholder && placeholder}
                  type={type && type}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  sx={{
                    backgroundColor: "#FFF",
                    border: error ? "1px solid red" : "inherit",
                  }}
                  inputProps={inputProps}
                  size={size}
                  onKeyPress={onKeyPress}
                  InputProps={{
                    endAdornment: (
                      <>
                        {showeyeIcon && (
                          <InputAdornment position="end">
                            {eyeIconStatus ? (
                              <IconButton onClick={handleIcon && handleIcon}>
                                <Visibility />
                              </IconButton>
                            ) : (
                              <IconButton onClick={handleIcon && handleIcon}>
                                <VisibilityOff />
                              </IconButton>
                            )}
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
                {error ? (
                  <>
                    <Typography
                      sx={{ color: "#F05252", position: "absolute" }}
                      fontSize="12px"
                    >
                      {error.message}
                    </Typography>
                    <Box padding={0.5} />
                  </>
                ) : null}
              </>
            );
          }}
        />
      </Box>
    );
  };
  
  export default CustomInputSearch;
  