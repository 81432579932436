import axios from "axios";
import api from "./endpoints";
import http from "./httpService";

const searchHistoryUrl =
  api.baseApi +
  `/api/get_user_search_history_details/get_user_search_history_details?`;

const filterURL =
  api.baseApi +
  `/api/get_all_saved_search_for_profile/get_all_saved_search_for_profile?`;

const deleteSearchHistoryUrl =
  api.baseApi + `/api/update_search_history_log/update_search_history_log?`;

const saveSearchHistoryUrl =
  api.baseApi + `/api/save_search_history/save_search_history`;

const setSearchHistoryAlertUrl =
  api.baseApi +
  `/api/set_subject_alert_for_profile/set_subject_alert_for_profile`;

const getEmailFrequencyListURL =
  api.baseApi +
  `/api/get_emailnotificationfrequency/get_emailnotificationfrequency`;

export async function searchHistoryApi() {
  try {
    const { data: response } = await http.get(searchHistoryUrl, {
      params: {
        customerId: sessionStorage.getItem("informaticscustomer_id"),
        sessionid: sessionStorage.getItem("session_key"),
        usermasterid: sessionStorage.getItem("user_id"),
      },
    });

    return response.data[0][0];
  } catch (error) {
    console.log("error", error);
  }
}

export async function frequencyAPI(query: any) {
  try {
    console.log("Query Data", query);

    let apiUrl = filterURL + `${query}`;

    console.log("API URL", apiUrl);
    let response = await axios.get(apiUrl);
    console.log(response.status);

    if (response.status == 200 && response.data.data[0][0]) {
      console.log("RESPONSE VALUE", response.data.data[0][0]);
      return response.data;
    }
  } catch (err) {
    return null;
  }
}

export async function deleteSearchHistory(
  search_log_ids: any,
  csrf_token: any
) {
  console.log("Search Log ID", search_log_ids);

  try {
    const res = await axios.post(
      deleteSearchHistoryUrl,
      { search_log_ids: search_log_ids },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrf_token,
        },
      }
    );

    console.log("DELETE RESPONSE", res);

    if (res.data.data[0][0] == "SUCCESS") {
      console.log(res.data.data[0][0]);
      // resolve(res.data);
      return res.data.data[0][0];
    }
  } catch (error) {
    console.log("error", error);
  }
}

export async function savedSearchAlert({
  searchhistoryjson,
  profileuser_id,
}: any) {
  try {
    // const response = await axios.post(saveSearchHistoryUrl, { alertjson: JSON.stringify(alertjson),  csrf_token },
    const response = await axios.post(
      saveSearchHistoryUrl,
      {
        searchhistoryjson: JSON.stringify(searchhistoryjson),
        profileuser_id: profileuser_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("SAVED SEARCH ALERT RESPONSE", response);
    if (response.status == 200) {
      console.log("RESPONSE BEFORE RETURN", response.data);
      return response.data;
    }
  } catch (error) {
    console.log("Errorr", error);
  }
}

export async function setSearchHistoryAlert(
  alertjson: any,
  profileuser_id: any,
  csrf_token: any
) {
  console.log("ALERT JSON", alertjson, profileuser_id);
  try {
    // console.log('URL PARAM',setSearchHistoryAlertUrl,
    // { alertjson: JSON.stringify(alertjson), profileuser_id: profileuser_id });

    // const response = await axios.post(saveSearchHistoryUrl, { alertjson: JSON.stringify(alertjson),  csrf_token },
    const response = await axios.post(
      setSearchHistoryAlertUrl,
      { alertjson: JSON.stringify(alertjson), profileuser_id: profileuser_id },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrf_token,

          // Accept: 'application/json', 'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log("SAVED SEARCH HISTORY ALERT RESPONSE", response);
    if (response.status == 200) {
      console.log("RESPONSE BEFORE RETURN ALERT", response.data);
      return response.data;
    }
  } catch (error) {
    console.log("Errorr", error);
  }
}

export async function deleteSavedSearch(search_log_ids: string | number) {
  try{
    const deleteApiUrl = `/api/delete_saved_search/delete_saved_search?search_log_ids=${search_log_ids}`
    const res = await http.get(deleteApiUrl);
    if (res.data.data[0][0] == "SUCCESS") {
      return res.data.data[0][0];
    }
  } catch(err) {
    console.log(err)
  }
}

export  function downloadSearch (doc:number){
  const customerId= sessionStorage.getItem("informaticscustomer_id");
  const sessionid= sessionStorage.getItem("session_key");
  const usermasterid= sessionStorage.getItem("user_id");
  const win: Window | null = window.open(`${api.baseApi}/api/export_search_history/api/export_search_history?customerId=${customerId}&sessionid=${sessionid}&usermasterid=${usermasterid}&download_type=${doc}`)
}

