import { SxProps } from "@mui/material/styles";

const search: SxProps = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  mb: 1,
};

const searchWrapper: SxProps = {
  width: "50%",
  display: "flex",
  alignItems: "center",
  height: "84%",
};

const textfield: SxProps = {
  width: "77%",
};

const searchButton: SxProps = {
  px: "3rem",
  py: "0.5rem",
  ml: 1,
  height: "6vh",
};

const select: SxProps = {
  width: "28vw",
};

const listWrapper: SxProps = {
  width: "100%",
  p: 1,
};

export const folderStyles = {
  search,
  searchWrapper,
  textfield,
  searchButton,
  select,
  listWrapper,
};
