import { Box, Button } from "@mui/material";
import { Colors } from "../../utils/constants";
// interface PropType {
//     data: string;
//     selected: boolean;
//     handleChange: (e: string) => void;
// }

interface Props {
  selectedTags: boolean[];
  selectOnClick?: any;
}

const AlphabetSearch = ({ selectedTags, selectOnClick }: Props) => {
  const tagData: String[] = [
    "ALL",
    "(0-9)",
    "A-C",
    "D-F",
    "G-I",
    "J-L",
    "M-O",
    "P-R",
    "S-U",
    "V-Z",
  ];

  return (
    <Box style={{ marginTop: "20px" }}>
      {tagData?.map((element, index) => (
        <Button
          key={index}
          onClick={() => selectOnClick(index)}
          sx={{
            // minWidth: "41px",
            minWidth: "36px",
            blockSize: " fit-content",
            fontSize: "12px",
            borderRadius: "8px",
            // display: ' inline-block',
            justifyContent: "space-around",
            //  width: "auto",
            // whiteSpace: 'normal',
            height: "35px",
            background: selectedTags[index] == true ? "#E4DCFE" : "#F3F4F6",
            border:
              selectedTags[index] == true
                ? "1px solid #7852FB"
                : "1px solid #E5E7EB",
            margin: "3.5px",
            alignItems: "center",
            // flexGrow: 2,
            fontFamily: "inter",
          }}
        >
          {element}
        </Button>
      ))}
    </Box>
  );
};

export default AlphabetSearch;
