import React, { useState } from "react";
import { Box, Typography, Drawer, Divider } from "@mui/material";
import { Colors } from "../../utils/constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import AbstractView from "./abstractIndex";

interface P {
  handleDawer: (data: any) => void;
  state: boolean;
  dialogueData: any;
  linkToDisplay?: any;
  authorEmailData: any;
  setTop: any;
  resultFrom?: any;
}

const RightDrawer: React.FC<P> = ({
  handleDawer,
  state,
  dialogueData,
  linkToDisplay,
  authorEmailData,
  setTop,
  resultFrom,
}) => {
  return (
    <React.Fragment>
      <Drawer
        onClose={handleDawer}
        anchor={"right"}
        open={state}
        PaperProps={{
          sx: { width: "65%", bgcolor: "#F9F8F6" },
        }}
      >
        <Box bgcolor={"#FFF"} width={"100%"}>
          <Box
            sx={[
              webStyle.flexBox,
              { width: "95%", alignSelf: "center", padding: "1%", ml: 3 },
            ]}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              {/* Abstract Details */}
              Full Record
            </Typography>
            <IconButton aria-label="delete" size="small" onClick={handleDawer}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>
        <Box sx={{ marginBottom: "80px" }}>
          <AbstractView
            dialogueData={dialogueData && dialogueData}
            screen={false}
            handleDawer={handleDawer}
            linkToDisplay={linkToDisplay}
            authorEmailData={authorEmailData}
            setTop={setTop}
            resultFrom={resultFrom}
          />
        </Box>

        {/* =========POP UP ================ */}
      </Drawer>
    </React.Fragment>
  );
};

export default RightDrawer;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
