import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { deleteProfileUser } from "../../api/userDetailsapi";
import { Colors } from "../../utils/constants";
import { useAppSelector } from "../../store/hooks";
import { notify } from "../../utils/Notify";

export default function DeleteAlertModal({
  openViewModal,
  handleCloseViewModal,
}: any) {
  const userId = useAppSelector((state) => state.login.user_id);
  const csrf_token = sessionStorage.getItem("csrf_token");

  const navigate = useNavigate();

  const handleDelete = async () => {
    const data = await deleteProfileUser(userId, csrf_token);
    if (data?.data.data[0][0] === "Success") {
      sessionStorage.clear();
      sessionStorage.clear();
      notify("success", "Profile deleted Successfully!")
      navigate("/signup");
    }
  };

  return (
    <>
      <Dialog open={openViewModal} onClose={handleCloseViewModal}>
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign="left"
            fontFamily={"Lora"}
            fontWeight={700}
            fontSize={28}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Confirm
          </Typography>

          <IconButton onClick={handleCloseViewModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete the Profile forever?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            sx={{
              "&:hover": {
                backgroundColor: "#7852FB",
              },
              textAlign: "left",
              backgroundColor: "#7852FB",
              color: " #FAF8F5",
              textTransform: "capitalize",
              borderRadius: "4px",
              fontSize: "16px",
              width: "45px",
              height: "35px",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseViewModal}
            sx={{
              "&:hover": {
                backgroundColor: Colors.coolGray300,
              },
              textAlign: "left",
              backgroundColor: Colors.coolGray300,
              color: " #FAF8F5",
              textTransform: "capitalize",
              borderRadius: "4px",
              fontSize: "16px",
              width: "40px",
              height: "35px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
