import axios from "axios";
import api from "./endpoints";

// Interceptor
const baseURL = api.baseApi;
const http = axios.create({});
http.interceptors.request.use((config) => {
  // const token = useGetToken();

  config.baseURL = baseURL;
  // config.headers["Content-Type"] = "application/json";
  // config.withCredentials = true;

  return config;
});

export default http;

// Fetch Hook
// export const useFetchData = <T,>(url: string) => {
//   const [data, setData] = useState<T | null>(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data: response } = await http.get(url);
//         setData(response);
//       } catch (error) {
//         console.error(error);
//       }
//       setLoading(false);
//     };

//     fetchData();
//   }, [url]);

//   return {
//     data,
//     loading,
//   };
// };
