import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Colors } from "../../utils/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { AuthorFinderObj, ReducerObj } from "../../types/interface";
import { updateLoadingState, updateSort } from "../../store/slice/filterSlice";
import { queryConstructor } from "../../api/formQuery";
// import { updateSearchResults } from "../../store/slice/searchSlice";
import { updateAuthorSearchResults } from "../../store/slice/authorFinderSlice";

import Tooltip from "@mui/material/Tooltip";
// import search from "../../screens/Search/onSearch";
// import search from "../../screens/AuthorFinder/onAuthorResult";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { valueExists } from "../../utils/helper";
import { usagelogApi } from "../../api/usageReportApi";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Props {
  disable?: boolean;
  sortData?: any;
}

const SortByRelevenceAuthor = ({ disable, sortData }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = React.useState("Publication Date");
  const [triggerEffect, setTriggerEffect] = React.useState(0);
  const [selectedRankSort, setSort] = React.useState("Date");

  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();

  const searchedValues = useSelector(
    (state: ReducerObj) => state.searchReducer.searchTerm
  );

  const selectedFilters: any = useSelector(
    (state: ReducerObj) => state.filterReducer
  );
  // const searchReducer: any = useSelector(
  //     (state: ReducerObj) => state.searchReducer
  // );

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  React.useEffect(() => {
    if (triggerEffect) onSearch();
  }, [triggerEffect]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  function handleSort(val: string) {
    setTriggerEffect((prev) => prev + 1);
    if (val === "Publication Date") {
      dispatch(updateSort("dateofpublication desc"));
      setSort("Date");
      logBasicSearchUsageData(74);
    } else if (val === "Best Match") {
      dispatch(updateSort("score desc"));
      setSort("Best Match");
      logBasicSearchUsageData(75);
    } else if (val === "sjr_rank") {
      setSort("SJR");
      dispatch(updateSort("sjr_score desc,dateofpublication desc"));
      logBasicSearchUsageData(73);
    } else if (val === "h-index") {
      setSort("H-Index");
      dispatch(updateSort("sjr_hindex desc,dateofpublication desc"));
      logBasicSearchUsageData(73);
    } else if (val === "naas") {
      setSort("NAAS");
      dispatch(updateSort("naas_value desc,dateofpublication desc"));
      logBasicSearchUsageData(73);
    }
    setSelectedSort(val);
    handleClose();
  }

  async function onSearch() {
    dispatch(search());

    // // Make api req
    // dispatch(updateLoadingState(true));
    // // let data = await search(searchReducer, selectedFilters, dispatch);

    // let data = await search(authorReducer, selectedFilters, dispatch);

    // // Dispatch to redux
    // // dispatch(updateSearchResults(data));
    // dispatch(updateAuthorSearchResults(data));
    // dispatch(updateLoadingState(false));
  }

  return (
    <div>
      <Button
        style={{
          textAlign: "left",
          color: Colors.black,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disable}
      >
        <Button
          sx={{
            fontSize: "0.8rem",
            color: disable ? Colors.gray_500 : Colors.black,
            // color: Colors.black,
            minHeight: 0,
            padding: "5px 5px",
          }}
        >
          Sort By
          <span style={{ fontSize: "0.8rem", paddingLeft: "6px" }}>
            {selectedRankSort}
          </span>
        </Button>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            color: selectedSort === "Publication Date" ? Colors.primary : "",
            fontSize: "14px",
          }}
          onClick={() => handleSort("Publication Date")}
          disableRipple
        >
          Publication Date
        </MenuItem>
        <MenuItem
          onClick={() => handleSort("Best Match")}
          disableRipple
          sx={{
            color: selectedSort === "Best Match" ? Colors.primary : "",
            fontSize: "14px",
          }}
        >
          Best Match
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          disableRipple
          sx={{
            opacity: 0.6,
            ":hover": { background: "inherit" },
            cursor: "default",
          }}
        >
          Ranking
        </MenuItem>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // value={publicationValue}
          value={selectedSort}
          // onChange={handleChange}
          sx={{ padding: 1 }}
        >
          {valueExists("SJR", sortData) && (
            <Tooltip title="Scimago Journal Ranking">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="sjr_rank"
                control={<Radio />}
                label="SJR"
                onChange={() => handleSort("sjr_rank")}
              />
            </Tooltip>
          )}
          {valueExists("H-Index", sortData) && (
            <Tooltip title="Hirsch index">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="h-index"
                control={<Radio />}
                label="H-Index"
                onChange={() => handleSort("h-index")}
              />
            </Tooltip>
          )}

          {consortiamaster_id === 1874 && valueExists("NAAS", sortData) && (
            <Tooltip title="National Academy of Agricultural Sciences">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="naas"
                control={<Radio />}
                label="NAAS"
                onChange={() => handleSort("naas")}
              />
            </Tooltip>
          )}
        </RadioGroup>
      </StyledMenu>
    </div>
  );
};

export default SortByRelevenceAuthor;
