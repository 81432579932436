import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useAppDispatch } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";

type ExtFolder = {
  id: string;
  name: string;
  created_date: string;
  no_of_records: any;
};
interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentFolder: ExtFolder;
}

const ExternalFolderMenu = ({
  open,
  onClose,
  anchor,
  currentFolder,
}: Props) => {
  const dispatch = useAppDispatch();

  const renameModal = () => {
    dispatch(
      setModal({
        modalType: "RENAME_FOLDER",
        modalProps: {
          show: true,
          title: "Rename Folder",
          isExternalLinks: true,
          folderId: currentFolder.id,
          foldername: currentFolder.name,
        },
      })
    );
    onClose();
  };

  const deleteModal = () => {
    dispatch(
      setModal({
        modalType: "DELETE_FOLDER",
        modalProps: {
          show: true,
          title: "Delete External Folder",
          folderId: currentFolder.id,
          itemName: currentFolder.name,
          type: "external",
        },
      })
    );
    onClose();
  };

  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem onClick={renameModal}>Rename</MenuItem>
      <Divider />
      <MenuItem sx={{ color: "red" }} onClick={deleteModal}>
        Delete Folder
      </MenuItem>
    </Menu>
  );
};

export default ExternalFolderMenu;
