import React, { useState } from "react";
import {
  GetAddAlertsData,
  GetAddAlertsDoc,
} from "../../../types/GetAddAlerts.types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getAlertSettings,
  getAllBooks,
  getBookAlertSettings,
  updateBooksFavorites,
  updateFavorites,
} from "../../../api/MyLibrary/TOCAlerts.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetAlertSettingsData } from "../../../types/GetAlertSettings.types";
import { useNavigate } from "react-router-dom";
import { setModal } from "../../../store/slice/modalSlice";
import { notify } from "../../../utils/Notify";
import { SelectChangeEvent } from "@mui/material/Select";
import { usagelogApi } from "../../../api/usageReportApi";
import { queryClient } from "../../../App";
import { AxiosResponse } from "axios";
import http from "../../../api/httpService";

type CustomTOCType = GetAddAlertsDoc & {
  notification_set: boolean;
  favorite_set: boolean;
};

export const getResourceCount = (
  profile_id: string
): Promise<AxiosResponse<any>> => {
  return http.get(
    `/api/get_personal_book_count/get_personal_book_count?profile_id=${profile_id}`
  );
};

export const useAddTOC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.login.user_id);
  const userData = useAppSelector((state) => state.customer.customerDetails);
  const [search, setSearch] = useState("");
  const [enableSearch, setEnableSearch] = useState("");
  const [tocData, setTOCData] = useState<CustomTOCType[]>([]);
  const [currentResIDArr, setCurrentResIDArr] = useState<string[]>([]);
  const [hitCount, setHitCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(15);

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const subscribedSubjects = useAppSelector(
    (state) => state.searchReducer.subjectType
  );

  type ResCount = { favorite_set_count: number; alert_count: number };
  const { data: resCount } = useQuery({
    queryKey: ["ResourceCount", userId],
    queryFn: () => getResourceCount(userId),
    select: ({ data }): ResCount => data.data[0][0],
  });
  let collectionData: string;
  if (userData?.product_type === "1" || userData?.product_type === "1,7") {
    collectionData = userData?.my_library_filter;
  } else if (userData?.product_type === "7") {
    collectionData = userData?.consortia_filter;
  }
  const { data: allJournals } = useQuery({
    queryKey: ["GetAllBooks", userId, page, pageSize, enableSearch],
    queryFn: () =>
      getAllBooks(
        userId,
        page,
        pageSize,
        search,
        enableSearch,
        collectionData,
        subscribedSubjects,
      ),
    select: ({ data }): any => data.data,
    onSuccess: (data) => {
      if (data.docs.length === 0) notify("info", "No journals found");

      setCurrentResIDArr(data.docs?.map((item: any) => item.bookmaster_id));
      setHitCount(Math.ceil(data.hits / pageSize));
    },
  });

  useQuery({
    queryKey: ["GetBookAlertSettings", userId, currentResIDArr],
    queryFn: () => getBookAlertSettings(userId, currentResIDArr),
    select: ({ data }): GetAlertSettingsData[] => data.data[0][0],
    enabled: currentResIDArr.length > 0,
    onSuccess: (data) => {
      const modifiedData = allJournals!.docs?.map((item: any, i: any) => ({
        ...item,
        notification_set: data[i].notification_set,
        favorite_set: data[i].favorite_set,
      }));

      setCurrentResIDArr([]);
      setTOCData(modifiedData);
      setLoading(false);
    },
  });

  const updateFavoritesHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => updateBooksFavorites(obj),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["GetAllBooks"],
      });
      queryClient.invalidateQueries({
        queryKey: ["GetBookAlertSettings"],
      });
      queryClient.invalidateQueries({
        queryKey: ["ResourceCount"],
      });
    },
  });

  const handleFav = (curr: any, details?: any) => {
    console.log(curr,details,"datafav")
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.bookmaster_id
      ? parseInt(details?.bookmaster_id)
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      111,
      null,
      null,
      articleArticle_Id,
      articleResourcemaster_id,
      publisherId,
      articleResourceissue_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );

    const data = {
      usermaster_id: userId,
      resource_ids: [parseInt(curr.bookmaster_id)],
      is_favourite: !curr.favorite_set,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateFavoritesHandler.mutate({ formData });
  };

  const handleOnSearchChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => setSearch(currentTarget.value);


  const handleSearch = () => {
    console.log("Search");
    if (!search) {
      setEnableSearch("");
      return;
    }
    setPage(1);
    setEnableSearch(search);
  };

  const handleClear = () => {
    setSearch("");
    setEnableSearch("");
  };

  const handlePerPage = (event: SelectChangeEvent) => {
    setPageSize(Number(event.target.value));
  };

  return {
    navigate,
    search,
    tocData,
    hitCount,
    pageSize,
    loading,
    resCount,
    setPage,
    handleOnSearchChange,
    handleFav,
    handleSearch,
    handleClear,
    handlePerPage,
  };
};
