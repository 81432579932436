import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import AuthorFinderImage from "../../assets/images/AuthorFinder.png";

const AuthorFinderHeader = () => {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={"row"}
      sx={{
        width: "100%",
        // height: "29vh",
        position: "relative",
      }}
    >
      <img
        src={AuthorFinderImage}
        alt="AuthorFinderImage"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "3vh",
          bottom: 0,
          right: 0,
          background:
            "linear-gradient(180deg, rgba(250, 248, 245, 0.0), rgba(250, 248, 245, 0.2), rgba(250, 248, 245, 0.5), rgba(250, 248, 245, 0.8), rgba(250, 248, 245, 1))",
        }}
      />
    </Stack>
  );
};

export default AuthorFinderHeader;
