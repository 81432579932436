import React from "react";

function FeedBcakForm() {
  return (
    <div style={{ height: "95vh" }}>
      <iframe
        title="Your Form"
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUOEFNTEM4WkZTNVJSQVI1SktIQlFXNDg5RS4u"
        width="100%"
        height="100%"
      >
        Loading...
      </iframe>
    </div>
  );
}

export default FeedBcakForm;
