import api from "./endpoints";
import http from "./httpService";

export interface LinkResolverType {
    button_to_display: string[];
    customer_id: string;
    links: string[];
    resourceissue_id: string;
    article_id: string
}

export default async function linkResolver(customerId: string, resourceissue_id: string, article_id: string) {
  try {
    let url = `${api.baseApi}/api/link_resolver_api/link_resolver_api?customer_id=${customerId}&resourceissue_id=${resourceissue_id}&article_id=${article_id}`;
    let res = await http.get(url);
    if(res.data) {
        if(res.data?.message == "Ok") return res?.data?.data
    }
    return res
  } catch (err) {
    console.log(err);
  }
}


export async function getBulkLinkRecords(formData: FormData) {
  try {
    let url = `${api.baseApi}/api/link_resolver_bulk_records/link_resolver_bulk_records`;

    let res = await http.post(url, formData);
    return res.data;
  } catch(err) {
    console.log(err)
  }
}