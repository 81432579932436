import { useState } from 'react';
import ResetPassword from '../components/ResetPassword';
import CheckEmail from '../components/CheckEmail';
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {

    const navigate = useNavigate();

    const [module, setModule] = useState(0);
    return (
        <>
            <div>
                <img src={logo} alt='' className='logo' style={{ cursor: "pointer" }} onClick={() => navigate("/login")} />

                {module === 0 ? <ResetPassword setModule={setModule} /> : null}

                {module === 1 ? <CheckEmail setModule={setModule} /> : null}

            </div>
        </>
    )
}

export default ForgotPassword