import { Box, Button } from "@mui/material";
import { Colors } from "../../utils/constants";
// interface PropType {
//     data: string;
//     selected: boolean;
//     handleChange: (e: string) => void;
// }

interface Props {
    selectedTags: boolean[];
    selectOnClick?: any;
    enabledTags?: any;
}

const AlphabeticalFilterForAuthor = ({ selectedTags, selectOnClick, enabledTags }: Props) => {
    const tagData: String[] = [
        "ALL",
        "(0-9)",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];

    return (
        <Box>
            {tagData?.map((element, index) => (
                <Button
                    key={index}
                    onClick={() => selectOnClick(index)}

                    sx={{
                        minWidth: "36px",
                        blockSize: " fit-content",
                        fontSize: "12px",
                        borderRadius: "8px",
                        justifyContent: "space-around",
                        height: "35px",
                        background: enabledTags ? !enabledTags?.includes(element) ? Colors.coolGray50 :
                            selectedTags[index] == true
                                ? '#E4DCFE'
                                : "#F3F4F6" : selectedTags[index] == true
                            ? '#E4DCFE'
                            : "#F3F4F6",

                        // background: selectedTags[index] == true ? "#E4DCFE" : "#F3F4F6",
                        border: enabledTags ? !enabledTags?.includes(element) ? "1px solid #e5e7eb" :
                            selectedTags[index] == true
                                ? "1px solid #7852FB"
                                : "1px solid #E5E7EB" : selectedTags[index] == true
                            ? '#E4DCFE'
                            : "#F3F4F6",
                        margin: "3.5px",
                        alignItems: "center",
                        // flexGrow: 2,
                        fontFamily: "inter",

                    }}

                    disabled={enabledTags ? !enabledTags?.includes(element) : false}
                >
                    {element}
                </Button>
            ))}
        </Box>
    );

};

export default AlphabeticalFilterForAuthor;



// const webStyle = {
//   disabledButton: {
//     margin: 6,
//     backgroundColor: Colors.coolGray100,
//     fontFamily: 'inter',
//     borderColor: "1px solid #E5E7EB",
//     borderWidth: 1,
//     height: 35,
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginLeft: 2,
//   },
// }