import { AppThunk } from "../../store";
import { updateAuthorAlertSettingData } from "../../store/slice/authorFinderSlice";
import { updateAlertSettingData } from "../../store/slice/searchSlice";
import { getAlertSettings } from "../MyLibrary/TOCAlerts.api";

export const alertTypeDetails =
  (location: any): AppThunk<any> =>
  async (dispatch, getState) => {
    console.log("locationlocation", location.pathname == "/authorfindersearch");
    if (location.pathname == "/authorfindersearch") {
      const searchReducer = getState().authorReducer.authorsearchResult;
      const user_id = getState().login.user_id;
      let dataArray = searchReducer?.docs;

      const resourcemaster_ids =
        Array.isArray(dataArray) &&
        dataArray?.map((x: any) => x?.resourcemaster_id);
      if (Array.isArray(resourcemaster_ids)) {
        const alertSettingData = await getAlertSettings(
          user_id,
          resourcemaster_ids.join(",").replace(/,,/g, ",").split(",")
        );
        let alertDataRes = alertSettingData.data.data[0][0];
        dispatch(updateAuthorAlertSettingData(alertDataRes));
        return alertDataRes;
      }
    } else {
      const searchReducer = getState().searchReducer.searchResult;
      // const searchReducer = getState().authorReducer.authorsearchResult;
      const user_id = getState().login.user_id;
      let dataArray = searchReducer?.docs;

      const resourcemaster_ids =
        Array.isArray(dataArray) &&
        dataArray?.map((x: any) => x?.resourcemaster_id && x?.resourcemaster_id)?.filter((y: any) => y !== undefined);
      if (Array.isArray(resourcemaster_ids)) {
        const alertSettingData = await getAlertSettings(
          user_id,
          resourcemaster_ids
        );
        let alertDataRes = alertSettingData.data.data[0][0];
        dispatch(updateAlertSettingData(alertDataRes));
        return alertDataRes;
      }
    }
  };
