import React, { useState } from "react";
import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { prefernecesStyles as styles } from "./Preferences.styles";
import Tabs from "@mui/material/Tabs";
import { a11yProps, TabPanel } from "../../components/Tabs/TabPanel";
import Tab from "@mui/material/Tab";
import SubjectPreference from "./SubjectPreference";
import { useGetMyPreference } from "../../api/Preferences/Subjects.api";
import { useAppSelector } from "../../store/hooks";
import SourcePreference from "./SourcePreference";
import { Loader } from "../../components/spinner";

const Preferences = () => {
  const [value, setValue] = useState(0);
  const selector = useAppSelector((state) => state.login);

  const { data: l1l2Data } = useGetMyPreference(selector.user_id);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  return (
    <Box sx={styles.root}>
      <Text sx={styles.title}>Preferences</Text>
      <Box sx={styles.body}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Subjects" {...a11yProps(0)} />
            <Tab label="Document Type" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {l1l2Data ? (
            <SubjectPreference l1l2Data={l1l2Data} />
          ) : (
            <Loader height={"100%"} width={"100%"} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {l1l2Data ? (
            <SourcePreference l1l2Data={l1l2Data} />
          ) : (
            <Loader height={"100%"} width={"100%"} />
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Preferences;
