import Link from "@mui/material/Link";
import { styled, SxProps } from "@mui/material/styles";
import { Colors } from "../../utils/constants";

const toolbar: SxProps = {
  display: "flex",
  gap: ".5rem",
  justifyContent: "space-between",
  position: "relative",
};

const brandWrapper: SxProps = {
  paddingLeft: "36px",
  display: { xs: "none", sm: "block" },
};

const consortia: SxProps = {
  fontSize: "19px",
  fontFamily: "Lora",
  fontWeight: 600,
  color: "#5856d6",
};

const name: SxProps = {
  fontFamily: "Lora",
  fontWeight: 600,
};

const iconWrapper: SxProps = {
  display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
  gap: 1.5,
};

const drawerWrapper: SxProps = {
  background: "#F3F4F6",
  height: "100vh",
  zIndex: 10,
  transition: "0.5s",
  mt: "5vh",
};

const drawerItem: SxProps = {
  mb: 2,
  height: "5vh",
  py: 1,
  pl: 2,
  "&:hover": {
    background: "rgba(120, 82, 251, 0.09)",
    borderRight: "2px solid #7852fb",
  },
};

const styledLink: SxProps = {
  color: "#000",
  ":hover": { color: Colors.primary },
  textDecoration: "none",
  fontSize: "18px",
  fontWeight: "500",
};

export const StyledLink = styled(Link)`
  text - decoration: none;
  color: #000;

  &: focus, &: hover, &: visited, &: link, &:active {
    text - decoration: none;
  }
`;

const diplyaName: SxProps = {
  // fontSize: "0.75rem",
  fontSize: "22px",
  color: "#5856d6",
};

const markedView: SxProps = {
  // fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#5856d6",
};

export const navStyles = {
  toolbar,
  brandWrapper,
  consortia,
  name,
  iconWrapper,
  drawerWrapper,
  drawerItem,
  styledLink,
  diplyaName,
  markedView,
};
