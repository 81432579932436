import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Colors } from "../../../utils/constants";

interface Props {
  show: boolean;
  rssURL: string | null;
  alertStatusCode: 0 | 1 | 2 | 3; // 0 wont be applicable in the current scenario
}

const AlertSuccessModal = ({ show, rssURL, alertStatusCode }: Props) => {
  const dispatch = useAppDispatch();
  console.log(alertStatusCode);

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AlertSuccessModal-Title"
      aria-describedby="AlertSuccessModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {alertStatusCode === 1
              ? "Email Alert"
              : alertStatusCode === 2
              ? "RSS Alert"
              : "Email & RSS Alerts"}{" "}
            Set Successfully
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        {rssURL && alertStatusCode > 1 && (
          <>
            <Text>
              Please add the below url in your RSS Reader to receive alerts
            </Text>
            <a
            style={{
              color: Colors.primary,
              marginBlock: 2,
              textOverflow: "ellipsis",
              width: "400px",
              overflow: "hidden",
              cursor: "copy"
            }}
            href={rssURL}
            target="_blank"
            rel="noreferrer"
          >
            {rssURL}
          </a>
          </>
        )}

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={() => dispatch(hideModal())}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertSuccessModal;
