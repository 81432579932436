import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import {
  deleteExternalFolder,
  deleteExternalFolderLink,
} from "../../../api/MyLibrary/Links.api";
import { deleteFolder, useMutateUnshareMyFolder } from "../../../api/MyLibrary/MyFolders.api";
import { queryClient } from "../../../App";
import { notify } from "../../../utils/Notify";

interface Props {
  show: boolean;
  title: string;
  itemName: string;
  folderId?: string;
  email?: string;
  documentId?: string;
  type: "internal" | "external" | "links" | "externalDelete";
}

const DeleteFolderModal = ({
  show,
  title,
  itemName,
  folderId,
  email,
  documentId,
  type,
}: Props) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);
  const unshareFolder = useMutateUnshareMyFolder();

  const deleteFolderHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => deleteFolder(obj),
    onSuccess: () => {
      notify("success", "Deleted Folder Successfully");
      dispatch(hideModal());
      queryClient.invalidateQueries({ queryKey: ["GetMyFolders"] });
    },
  });

  const deleteExternalFolderHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => deleteExternalFolder(obj),
    onSuccess: () => {
      notify("success", "Deleted External Folder Successfully");
      dispatch(hideModal());
      queryClient.invalidateQueries({ queryKey: ["GetExternalFolders"] });
    },
  });

  const deleteExternalFolderLinkHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => deleteExternalFolderLink(obj),
    onSuccess: () => {
      notify("success", "Deleted Link Successfully");
      dispatch(hideModal());
      queryClient.invalidateQueries({ queryKey: ["GetExternalFoldersLinks"] });
    },
  });

  const handleDelete = async () => {
    if (type === "internal") {
      const formData = new FormData();
      if (folderId) formData.append("folderid", folderId);
      formData.append("profileuserid", userId);

      deleteFolderHandler.mutate({ formData: formData });
    } else if (type === "externalDelete") {
      const formData = new FormData();
      if (folderId) formData.append("folder_id_in", folderId);
      formData.append("shared_email_ids", email ? email : "");

      unshareFolder.mutate({ formData: formData });
    } else if (type === "external") {
      const formData = new FormData();
      if (folderId) formData.append("folder_id", folderId);

      deleteExternalFolderHandler.mutate({ formData: formData });
    } else if (type === "links") {
      const formData = new FormData();
      if (documentId) formData.append("document_id", documentId);

      deleteExternalFolderLinkHandler.mutate({ formData: formData });
    }
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="DeleteFolderModal-Title"
      aria-describedby="DeleteFolderModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}&nbsp;-&nbsp;{itemName}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Text sx={{}}>
          Are you sure you want to permanently delete this folder?
        </Text>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteFolderModal;
