import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import { GetPreprintData, GetPreprintRoot } from "../../types/GetAlerts.types";
import {
  GetUserL2L3Subject,
  GetUserL2L3SubjectsRoot,
} from "../../types/GetUserL2L3Subjects";
import http from "../httpService";

export const useGetDatasetSubjects = (userId: string) => {
  const params: any = {};
  params["datatype_id"] = 6; // dataset code: 6
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetDatasetSubjects", userId],
    () =>
      http.get(
        "/api/get_userwise_all_l2_l3_subject_details/get_userwise_all_l2_l3_subject_details",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetUserL2L3SubjectsRoot>
      ): GetUserL2L3Subject[] => {
        return res.data.data.Subjects;
      },
    }
  );
};


//no need of seperate files // rest of apis can be reused
export const useGetPatentsSubjects = (userId: string) => {
  const params: any = {};
  params["datatype_id"] = 25; // patent code: 25
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetPatentSubjects", userId],
    () =>
      http.get(
        "/api/get_userwise_all_l2_l3_subject_details/get_userwise_all_l2_l3_subject_details",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetUserL2L3SubjectsRoot>
      ): GetUserL2L3Subject[] => {
        return res.data.data.Subjects;
      },
    }
  );
};

export const useGetPreprintAlerts = (userId: string) => {
  const params: any = {};
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetPreprintAlerts", userId],
    () =>
      http.get(
        "/api/get_saved_preprint_alerts_for_user/get_saved_preprint_alerts_for_user",
        {
          params,
        }
      ),
    {
      select: (res: AxiosResponse<GetPreprintRoot>): GetPreprintData[] => {
        return res.data.data[0][0];
      },
    }
  );
};

export const useMutateAddPreprintAlerts = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/set_alert_for_preprints/set_alert_for_preprints",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetPreprintAlerts"]);
        console.log("Success, Added preprint alert");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateUpdatePreprintAlerts = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/update_preprint_alert/update_preprint_alert",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetPreprintAlerts"]);
        console.log("Success, Updated preprint alert");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateUpdateDatasetSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/update_datatype_wise_personalisation_subjects/update_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetDatasetSubjects"]);
        console.log("Success, Updated Dataset Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateUpdatePatentSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/update_datatype_wise_personalisation_subjects/update_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetPatentSubjects"]);
        console.log("Success, Updated Patent Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateDeletePreprintAlerts = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_preprint_alert_foruser/remove_preprint_alert_foruser",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetPreprintAlerts"]);
        console.log("Success, Deleted preprint alert");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateDeleteDatasetSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_datatype_wise_personalisation_subjects/remove_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetDatasetSubjects"]);
        console.log("Success, Deleted dataset Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};
