import {
  GetUserProfileData,
  GetUserProfileRoot,
} from "../types/GetUserProfile.types";
import http from "./httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  GetAllUserTypesData,
  GetAllUserTypesRoot,
} from "../types/GetAllUserTypes";
import { queryClient } from "../App";

export const useGetUserProfile = (userId: string, enabled?: boolean) => {
  const params: any = {};
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetUserProfile", userId],
    () =>
      http.get(
        "/User/get_usermaster_details_by_id/get_usermaster_details_by_id",
        {
          params,
        }
      ),
    {
      select: (res: AxiosResponse<GetUserProfileRoot>): GetUserProfileData => {
        return res.data.data[0][0];
      },
      enabled: enabled,
      onSuccess: (data) => sessionStorage.setItem("user_email", data.emailid),
    }
  );
};

export const useGetUserTypes = () =>
  useQuery(
    ["useGetUserTypes"],
    () => http.get("/User/get_usertype_list/get_usertype_list"),
    {
      select: (
        res: AxiosResponse<GetAllUserTypesRoot>
      ): GetAllUserTypesData[] => {
        return res.data.data[0][0];
      },
    }
  );

//DELETE
export async function getUserTypes() {
  try {
    const { data: response } = await http.get(
      `/User/get_usertype_list/get_usertype_list`
    );
    return response.data[0][0];
  } catch (err) {
    console.log(err);
  }
}

export const useMutateGetImageURL = () => {
  return useMutation<
    AxiosResponse<{ status: string; access_url: string }>,
    unknown,
    { formData: FormData }
  >((obj: { formData: FormData }) => http.post("/upload-file/", obj?.formData), {
    onSuccess: () => {
      console.log("Success,Image Uploaded");
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useMutateEditUserProfile = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/User/update_informatics_profile_user/update_informatics_profile_user",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetUserProfile"]);
        console.log("Success, User Updated");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};
