import {
  GetExternalFoldersRoot,
  GetExternalFoldersData,
  GetExternalFolderLinksData,
  GetExternalFolderLinksRoot,
} from "../../types/GetExternalFolder.types";
import http from "../httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import { notify } from "../../utils/Notify";

export const useGetExternalFolders = (userId: string) => {
  const params: any = {};
  if (userId) params["profileuserId"] = userId;

  return useQuery(
    ["GetExternalFolders", userId],
    () =>
      http.get(
        "/api/get_external_folder_list_for_user/get_external_folder_list_for_user",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetExternalFoldersRoot>
      ): GetExternalFoldersData[] => {
        return res.data.data[0][0];
      },
    }
  );
};

export const createExternalFolder = async (obj: { formData: FormData }) =>
  http.post(
    "/api/create_external_folder_for_user/create_external_folder_for_user",
    obj?.formData
  );

export const useGetExternalFoldersLinks = (folderId: string) => {
  const params: any = {};
  if (folderId) params["folder_id"] = folderId;

  return useQuery(
    ["GetExternalFoldersLinks", folderId],
    () =>
      http.get(
        "/api/get_external_library_documents_by_folder_id/get_external_library_documents_by_folder_id",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetExternalFolderLinksRoot>
      ): GetExternalFolderLinksData[] => {
        return res.data.data[0][0];
      },
    }
  );
};

export const useMutateCreateExternalFolderLink = () => {
  return useMutation(
    (obj: { formData: FormData; csrf: any }) =>
      http.post(
        "/api/create_external_library_document/create_external_library_document",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetExternalFoldersLinks"]);
        console.log("Success, External Folder Link created");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateRenameExternalFolder = () => {
  return useMutation(
    (obj: { formData: FormData; csrf: any }) =>
      http.post(
        "/api/rename_external_folder/rename_external_folder",
        obj?.formData
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["GetExternalFolders"]);
        if (data.data.message === "Ok") {
          if (
            data.data.data &&
            Array.isArray(data.data.data) &&
            data.data.data.length
          )
            notify(
              data.data.data[0][0] === "Folder already exists"
                ? "error"
                : "success",
              data.data.data[0][0]
            );
        }
      },
      onError: (err: any) => {
        console.log(err, "err");
        if (err?.response?.data?.message === "Not Ok") {
          notify("error", err.response.data.data[0][0]);
        }
      },
    }
  );
};

export const deleteExternalFolder = async (obj: { formData: FormData }) =>
  http.post(
    "/api/remove_external_library_folder/remove_external_library_folder",
    obj?.formData
  );

export const deleteExternalFolderLink = async (obj: { formData: FormData }) =>
  http.post(
    "/api/remove_external_library_document/remove_external_library_document",
    obj?.formData
  );
