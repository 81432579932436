import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Colors } from "../../utils/constants";

interface P {
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
  text: string[];
}

export default function TabView(props: P) {
  const { handleChange, value, text } = props;

  return (
    <Box ml="2.3%" sx={{ bgcolor: "background.paper" }}>
      <Tabs value={value} onChange={handleChange}>
        {text?.map((val) => {
          return (
            <Tab
              sx={{
                fontSize: 16,
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                color: Colors.gray_600,
              }}
              label={val}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
