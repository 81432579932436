import { createSlice } from "@reduxjs/toolkit";

export interface InitialstateType {
  fullTextQuery: any;
  allTextQuery: any;
}

const initialState: InitialstateType = {
  fullTextQuery: null,
  allTextQuery: null,
};

export const queryForCountSlice = createSlice({
  name: "searchResult",
  initialState,
  reducers: {
    updateQuery(state, { payload }) {
      state.fullTextQuery = payload.fullTextQuery;
      state.allTextQuery = payload.allTextQuery;
    },
    clearQuery(state) {
      state.fullTextQuery = null;
      state.allTextQuery = null;
    }
  },
});

export const { updateQuery, clearQuery } = queryForCountSlice.actions;
export default queryForCountSlice.reducer;
