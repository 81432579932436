import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../utils/constants";
import {
  useGetSharedToUsers,
  useMutateUnshareMyFolder,
} from "../../../api/MyLibrary/MyFolders.api";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  show: boolean;
  title: string;
  foldername: string;
  folderId: string;
}

const UnshareFolderModal = ({ show, title, foldername, folderId }: Props) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);

  const unshareFolder = useMutateUnshareMyFolder();
  const { data: sharedTo } = useGetSharedToUsers(userId, folderId);

  const handleUnshare = async (sharedId: string) => {
    const formData = new FormData();
    formData.append("folder_id_in", folderId);
    formData.append("shared_email_ids", sharedId);
    unshareFolder.mutate({ formData: formData });
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="UnshareFolderModal-Title"
      aria-describedby="UnshareFolderModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}&nbsp;-&nbsp;{foldername}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
                <TableCell>#</TableCell>
                <TableCell>Email Address</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sharedTo &&
                sharedTo?.map((row, i) => (
                  <TableRow hover key={row.userfoldersharedetail_id}>
                    <TableCell>{i + 1}</TableCell>

                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text fontSize={14}>{row["Shared to Email"]}</Text>
                      <IconButton
                        onClick={() => handleUnshare(row["Shared to Email"])}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default UnshareFolderModal;
