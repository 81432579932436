
/*
    Possible values handled using session cookie.
    1) session_key
    2) csrf_token
    3) user_id
    4) informaticscustomer_id
*/


export function setSessionCookie(name:string, value: string) {
    document.cookie = `${name}=${value}`
}

export function getSessionCookieValue(cookieName: string) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === cookieName) {
            return value;
        }
    }
    return null; 
}

export function removeSessionCookie(cookieName: string) {
     document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
     document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/BrowseJournalTableSearch/BrowseJournalSearch;';
     document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/search;';
     document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=basicSearchScreen/;';

    // const paths = ['/', '/search'];
    // const domains = [window.location.hostname, '.' + window.location.hostname];

    // paths.forEach((path) => {
    //     domains.forEach((domain) => {
    //         document.cookie = cookieName + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain};`;
    //         document.cookie = cookieName + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
    //     });
    // });
}