import React, { useState } from "react";
import { Box, Typography, Drawer, Divider, Stack } from "@mui/material";
import { Colors } from "../utils/constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import BookDetails from "./bookDetails";

interface P {
  handleDawer: (data: any) => void;
  state?: boolean;
  dialogueData?: any;
  setTop?: any;
}

const RightDrawerOfBook: React.FC<P> = ({
  handleDawer,
  state,
  dialogueData,
  setTop,
}) => {
  return (
    <React.Fragment>
      <Drawer
        onClose={handleDawer}
        anchor={"right"}
        open={state}
        PaperProps={{
          sx: { width: "50%", bgcolor: "#F9F8F6" },
        }}
      >
        <Box bgcolor={"#FFF"} width={"100%"}>
          <Box
            sx={[
              webStyle.flexBox,
              { width: "95%", alignSelf: "center", padding: "1%", ml: 3 },
            ]}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              {/* Abstract Details */}
              About Book
            </Typography>
            <IconButton aria-label="delete" size="small" onClick={handleDawer}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>

        <Stack sx={{ marginBottom: "30px" }}>
          <BookDetails dialogueData={dialogueData && dialogueData} />
        </Stack>

        {/* =========POP UP ================ */}
      </Drawer>
    </React.Fragment>
  );
};

export default RightDrawerOfBook;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
