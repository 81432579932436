import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface PropsType {
  isOpen: boolean;
  handleClose: () => void;
}

export default function NoResultModal(props: PropsType) {
  const { isOpen, handleClose } = props;

  function PopupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            No Result Found
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <PopupTitle />

      <DialogContent>
        <Typography>There are no results that match your query.</Typography>
      </DialogContent>
    </Dialog>
  );
}
