import http from "../httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import {
  GetUserL2L3SubjectsRoot,
  GetUserL2L3Subject,
} from "../../types/GetUserL2L3Subjects";
import api from "../endpoints";

const setThesisURL = api.baseApi + `/api/set_subject_alert_for_article_level_datatype/set_subject_alert_for_article_level_datatype`


export const useGetThesisSubjects = (userId: string) => {
  const params: any = {};
  params["datatype_id"] = 4; // Thesis Code: 4
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetThesisSubjects", userId],
    () =>
      http.get(
        "/api/get_userwise_all_l2_l3_subject_details/get_userwise_all_l2_l3_subject_details",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetUserL2L3SubjectsRoot>
      ): GetUserL2L3Subject[] => {
        return res.data.data.Subjects;
      },
    }
  );
};

export const useMutateUpdateThesisSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/update_datatype_wise_personalisation_subjects/update_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetThesisSubjects"]);
        console.log("Success, Updated Thesis Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateDeleteThesisSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_datatype_wise_personalisation_subjects/remove_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetThesisSubjects"]);
        console.log("Success, Deleted Thesis Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};



export async function setThesisAlert({ alertjson }: any) {

  console.log('Alert JSON',alertjson)
  try {

    console.log('Try Triggered')
    const res = await axios.post(setThesisURL, { alertjson: JSON.stringify(alertjson) },
    {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }
);

    console.log('URL Query',setThesisURL, { alertjson: JSON.stringify(alertjson) })
    console.log('Thesis Alert RESPONSE', res)

    if(res.data.data[0][0] == "New Alert created successfully!"){
      return res;
    }


  } catch (error) {
    console.log("error", error)

  }
}