import http from "./httpService";
import api from "./endpoints";
import axios from "axios";

let userId = sessionStorage.getItem("user_id");
export async function getDdrRequest(articleId: number) {
  try {
    let data = await axios.get(
      api.baseApi +
        `/api/Get_ddr_request_library_list/Get_ddr_request_library_list?customerId=${userId}&article_id=${articleId}`
    );

    return data;
  } catch (error) {}
}

export async function getUserDetails() {
  try {
    let data = await axios.get(
      api.baseApi +
        `/api/get_ddr_request_form/get_ddr_request_form?UserMasterId=33&customerId=1877&article_id=300000&RequestToCustomerId=1876`
    );
    return data.data.data[0][0];
  } catch (err) {
    console.log(err);
  }
}
let messageRes = "";
// export async function submitFormData(data: any) {
//   console.log("submit form data");

//   const apiFormData = {
//     data: [
//       {
//         requestingcustomer_id: 1650,
//         deliveringcustomer_id: 1653,
//         article_id: 300001,
//         requesteduserid: 39,
//         requesteduseremailid: data.email,
//         requestremarks: data.message,
//         requester_username: data.firstName + " " + data.lastName,
//         requester_department: data.department,
//         requester_rollno: "I607",
//         requester_mobile: data.mobileNum,
//         requester_designation: "dev",
//       },
//     ],
//   };

//   const formData = new FormData();
//   formData.append("detailsjson", JSON.stringify(apiFormData));

//   try {
//     let data = await axios.post(
//       api.baseApi +
//         `/api/consortia_ddr_request_submision/consortia_ddr_request_submision`,
//       formData
//     );
//     messageRes = data.data.message[0][0];
//     console.log("messageRes", messageRes);

//     ddrConfirmation();
//   } catch (err) {
//     console.log(err);
//   }
// }

// export const ddrConfirmation = async () => {
//   try {
//     let response = await axios.get(
//       api.baseApi +
//         `/api/get_ddr_confirmation_details/get_ddr_confirmation_details?ddr_reference_id=307532`
//     );
//   } catch (err) {
//     console.log(err);
//   }
// };

// ==============================
export const getDDRRequestLibraryListCall = async (data: any) => {
  try {
    let response = await axios.get(
      api.baseApi +
        `/api/Get_ddr_request_library_list/Get_ddr_request_library_list${data}`
    );
    // console.log("response", response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getDDRFormDetailsCall = async (data: any) => {
  try {
    let response = await axios.get(
      api.baseApi + `/api/get_ddr_request_form/get_ddr_request_form${data}`
    );
    console.log("response", response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getUserTypesCall = async () => {
  try {
    let response = await axios.get(
      api.baseApi + `/User/get_usertype_list/get_usertype_list`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getSearchResultsApiCall = async (data: any) => {
  try {
    let response = await axios.get(
      api.baseApi + `/api/indexing/solr/articlecore2${data}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const submitDDRCall = async (data: any) => {
  // const headers = {
  //   "X-CSRFToken": sessionStorage.getItem("csrf_token"),
  // };
  try {
    let response = await axios.post(
      api.baseApi +
        `/api/consortia_ddr_request_submision/consortia_ddr_request_submision`,
      data,
      {
        headers: {
          "X-CSRFToken": sessionStorage.getItem("csrf_token"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

// CONDITION RENDERING

export const getDdrStatus = async (data: any) => {
  try {
    let response = await axios.get(
      api.baseApi + `/api/link_resolver_api/link_resolver_api${data}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getDDRConfirmationCall = async (data: any) => {
  console.log("dataofddr", data);

  try {
    let response = await axios.get(
      api.baseApi +
        `/api/get_ddr_confirmation_details/get_ddr_confirmation_details${data}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
