import { Box, Card, CardContent, Typography } from "@mui/material";
import Footer from "../components/footer";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import { usagelogApi } from "../api/usageReportApi";
import { useAppSelector } from "../store/hooks";

export default function ThankYouScreen() {
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const logIPBasedUsageData = (usageActionID: any) => {
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const consortiamaster_id = profileData1?.consortiamaster_id
      ? profileData1?.consortiamaster_id
      : null;
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamaster_id,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const hadleClick = () => {
    logIPBasedUsageData(6);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box m={1}>
        <img src={logo} />
      </Box>

      {/* ---------------------- card -------------------------------------- */}
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card
          sx={{
            height: "200px",
            width: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardContent>
            <Typography fontSize={20}>Thank you for using J-Gate</Typography>
            <Typography sx={{ opacity: 0.8 }}>
              If you wish to login again.{" "}
              <span onClick={hadleClick}>
                <Link to={"/"}>Click here</Link>
              </span>
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* ---------------------- Footer ------------------------------------- */}
      <Box>
        <Footer />
      </Box>
    </Box>
  );
}
