import { ThemeProvider } from "./contexts";
import router from "./routes/Routes";
import Theme from "./utils/theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import RootModal from "./components/modals/RootModal";
import { CssBaseline } from "@mui/material";
import MathJaxContext from "better-react-mathjax/MathJaxContext";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import Spinner from "./components/spinner";
import { ToastContainer } from "react-toastify";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={Theme}>
        <ThemeProvider>
          <CssBaseline />
          <ToastContainer />
          <MathJaxContext>
            <Suspense fallback={<Spinner isLoading={true} />}>
              <RouterProvider router={router} />
            </Suspense>
          </MathJaxContext>
          <RootModal />
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
