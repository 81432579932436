import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { updateBrowserPage as updateBrowserPagePublisher } from "../../store/slice/browserFilterSlicePublisher";
import { useAppSelector } from "../../store/hooks";

interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterValues?: any;
  applyFilter?: (e: string[]) => void;
  appliedFilterValues?: string[];
  setFilters: any;
}

const ModalOneOfBrowsePublisher = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterValues,
    applyFilter,
    appliedFilterValues,
    setFilters,
  } = props;
  const [filteredValues, setFilteredvalues] = useState<string[]>([]);
  // console.log("filteredvalues", filteredValues);
  const dispatch = useDispatch();
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  useEffect(() => {
    setValues();
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);
  }

  const selectedValues = (data: string) => {
    // console.log("working", data);

    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
    }
  };

  const handleSubmit = () => {
    dispatch(updateBrowserPagePublisher(1));
    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };

  function handleCancel() {
    setFilteredvalues([]);
    handleClose();
  }

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: 700 }}>
       
{Array.isArray(filterValues) &&
          filterValues?.map((data: any) => {
            const [key, value] = Object.entries(data)[0];
            if (key === "NAAS" && consortiamaster_id !== 1874) return;
            return (
              <Stack direction={"row"} alignItems="center" sx={{ gap: "40%" }}>
                <Stack direction={"row"} alignItems="center" minWidth={200}>
                  <Checkbox
                    onClick={() => selectedValues(Object.keys(data)[0])}
                    checked={filteredValues?.includes(Object.keys(data)[0])}
                  />
                  <Typography>{key}</Typography>
                </Stack>
                {/* @ts-ignore */}
                <Typography>({Object.values(data)[0]})</Typography>
              </Stack>
            );
          })}




        {/* Buttons */}
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 2 }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ minWidth: 150 }}
          >
            Apply
          </Button>
          <Button
            onClick={handleCancel}
            sx={{ minWidth: 150, color: "#111928" }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ModalOneOfBrowsePublisher;
