import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Typography,
  Button,
  Divider,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import FilterSubjectOfBook from "../../components/filterPopups/ModelOneOfBook";
import FilterPublisherOfBook from "../../components/filterPopups/ModelOneOfBook";

import FilterRanking from "../../components/filterPopups/ModalOneOfBrowserJournal";
import FilterAuthor from "../../components/filterPopups/ModalOneOfBrowserJournal";

import FilterCountry from "../../components/filterPopups/ModalTwoOfBrowseJournal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { getParamsByName, goToTop } from "../../utils/helper";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateSourceType,
  updateSubject,
  updateCountryOfPublication,
  updatePublisher,
  updateJournalRank,
  updateCountryOfPublishingAuthor,
  clearAllFilters,
  updateCurrentUpdate,
  updateOnlyBooks,
} from "../../store/slice/browseFilterSlice";
import { useAppSelector } from "../../store/hooks";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { updateBrowserPage } from "../../store/slice/browseFilterSlice";
import { usagelogApi } from "../../api/usageReportApi";
// Types

type YearType = { val: string; count: string };

interface PropType {
  yearFromData?: YearType[];
  jsonFacets?: any;
}

const SearchFilterComponentOfBook = (props: any) => {
  // Props
  const { yearFromData, jsonFacets, setFilters } = props;

  const dispatch = useDispatch();
  const searchTerm = getParamsByName("searchterm");
  const selectedFilters: any = useSelector(
    (state: any) => state.browseFilterSlice
  );

  // const searchReducer: any = useSelector(
  //   (state: ReducerObj) => state.searchReducer
  // );

  const searchResult = useSelector(
    (state: any) => state.browseJournalSearch?.bookResult?.facets?.facet_fields
  );

  const [sourceTypeModalStatus, setSourceTypeModalStatus] = useState(false);
  const [filterSubjectModalStatus, setFilterSubjectModalStatus] =
    useState(false);

  const [filterAuthorModalStatus, setFilterAuthorModalStatus] = useState(false);
  const [filterSpeakerModalStatus, setFilterSpeakerModalStatus] =
    useState(false);
  const [filterResearchModalStatus, setFilterResearchModalStatus] =
    useState(false);
  const [filterJournalModalStatus, setFilterJournalModalStatus] =
    useState(false);
  const [filterRankingModalStatus, setFilterRankingModalStatus] =
    useState(false);

  const [filterCountryModalStatus, setFilterCountryModalStatus] =
    useState(false);
  const [filterPublisherModalStatus, setFilterPublisherModalStatus] =
    useState(false);
  const [filterUnivModalStatus, setFilterUnivModalStatus] = useState(false);
  const [triggerCount, setTriggerCount] = useState<number>(0);
  const [copaModalStatus, setCopaModalStatus] = useState(false);


  // const openAccess = useAppSelector((state) => state.filterReducer.openAccess);

  const openAccess = useAppSelector(
    (state) => state.browseFilterSlice.currentUpdate
  );



  const onlybooks = useAppSelector((state)=>state.browseFilterSlice.onlyBooks);
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "ONOSCollections"
      ? 5
      : selectedFilters.collectionsValueJournal === "Library OPAC"
      ? 6
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  const logJournalAtoZUsageData = (usageActionID: any) => {
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      null,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  useEffect(() => {
    if (triggerCount) applySearch();
  }, [triggerCount]);

  // Handle source type modal status changes
  // Handle Filter subject modal status changes
  const handleChangeFilterSubject = () => {
    setFilterSubjectModalStatus(false);
  };

  // Handle Filter Author modal status changes
  const handleChangeFilterAuthor = () => {
    setFilterAuthorModalStatus(false);
  };


  // Handle Filter ranking modal status changes
  const handleChangeFilterRanking = () => {
    setFilterRankingModalStatus(false);
  };

  // Handle Filter country modal status changes
  const handleChangeFilterCountry = () => {
    setFilterCountryModalStatus(false);
  };

  // Handle Filter publisher modal status changes
  const handleChangeFilterPublisher = () => {
    setFilterPublisherModalStatus(false);
  };


  // Get Author values
  const getAuthorTypeValues = (data: string[]) => {
    logJournalAtoZUsageData(82);
    dispatch(updateSourceType(data));
  };
  // Get speaker values
  const getSpeakerTypeValues = (data: string[]) => {
    logJournalAtoZUsageData(81);
    dispatch(updateSubject(data));
  };


  // Get ranking values
  const getRankingTypeValues = (data: string[]) => {
    logJournalAtoZUsageData(80);
    dispatch(updateJournalRank(data));
  };

  // Get cop values
  const getCopTypeValues = (data: string[]) => {
    logJournalAtoZUsageData(79);
    dispatch(updateCountryOfPublication(data));
  };

  // Get cop values
  const getCopaTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublishingAuthor(data));
  };

  // Get publisher values
  const getPublisherTypeValues = (data: string[]) => {
    dispatch(updatePublisher(data));
  };

  // function apply filter
  async function applyFilter() {
    // if (!searchTerm) return;
    setTriggerCount((prev) => prev + 1);

    goToTop();
  }

  async function applySearch() {
    // dispatch(updateLoadingState(true));
    // generatedFilterString();
    let returnData = generatedFilterString();
    setFilters(returnData);
    // let data = await search(searchReducer, selectedFilters, dispatch);
    // dispatch(updateSearchResults(data));
    // dispatch(updateLoadingState(false));
  }

  // clear all filters
  function handleClearAllFilters() {
    dispatch(updateOnlyBooks(false));
    dispatch(clearAllFilters());
    setTriggerCount((prev) => prev + 1);
  }



  function handleOpenAccess() {
    dispatch(updateBrowserPage(1));
    dispatch(updateCurrentUpdate(!openAccess));
    logJournalAtoZUsageData(83);
    setTriggerCount((prev) => prev + 1);
  }

  function handleWithChapter(){
   dispatch(updateOnlyBooks(!onlybooks))
  }

  const updateFilters: any = useSelector(
    (state: any) => state.browseFilterSlice
  );

  function generatedFilterString() {
    const ranking = updateFilters?.journalRank?.length
      ? "&" +
        objectToQueryString({
          filter_metrix:
            '("' + updateFilters?.journalRank.join('" OR "') + '")',
        })
      : "";
    const publisherCountry = updateFilters?.countryOfPublication?.length
      ? "&" +
        objectToQueryString({
          primary_publisher_country:
            '("' + updateFilters?.countryOfPublication?.join('" OR "') + '")',
        })
      : "";

    const source_index = updateFilters?.sourceType?.length
      ? "&" +
        objectToQueryString({
          fq__resource_source_index:
            '("' + updateFilters?.sourceType?.join('" OR "') + '")',
        })
      : "";

    const subjects = updateFilters?.subject?.length
      ? "&" +
        objectToQueryString({
          subjects_name_l3: encodeURIComponent(
            '("' + updateFilters?.subject?.join('" OR "') + '")'
          ),
        })
      : "";

    const publisher = updateFilters?.publisher?.length
      ? "&" +
        objectToQueryString({
          publisher_name: encodeURIComponent(
            '("' + updateFilters?.publisher?.join('" OR "') + '")'
          ),
        })
      : "";

    return publisherCountry + source_index + subjects + ranking + publisher;
  }

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={[webStyle.flexBox]}>
          <Typography
            sx={{
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: 700,
              fontSize: "1.125rem",
            }}
          >
            Filters
          </Typography>
          <Button onClick={handleClearAllFilters}>Clear All</Button>
        </Box>

        {Array.isArray(jsonFacets?.data_type?.buckets) &&
        jsonFacets?.data_type?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Document Type
              </Typography>
              <Button onClick={() => setSourceTypeModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Category
          </Typography>
          <Button onClick={() => setFilterCategoryModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {categoryValue.length
            ? categoryValue?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button
                    onClick={() =>
                      removeFilter(data, categoryValue, setCategoryValue)
                    }
                  >
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {searchResult?.subjects_name_l3?.length !== 0 ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Subject
              </Typography>
              <Button onClick={() => setFilterSubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.authors_tk?.buckets) &&
        jsonFacets?.authors_tk?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Author
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.speakers?.buckets) &&
        jsonFacets?.speakers?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Speaker
              </Typography>
              <Button onClick={() => setFilterSpeakerModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.guide_name_tk?.buckets) &&
        jsonFacets?.guide_name_tk?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                {/* Guides (Ph.D Theses) */}
                Research Guides
              </Typography>
              <Button onClick={() => setFilterResearchModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.journal_name?.buckets) &&
        jsonFacets?.journal_name?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Journal
              </Typography>
              <Button onClick={() => setFilterJournalModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {searchResult?.filter_metrix?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Ranking
              </Typography>
              <Button onClick={() => setFilterRankingModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Year(3345)
          </Typography>
          <Button onClick={() => setFilterYearModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {year.length
            ? year?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button onClick={() => removeFilter(data, year, setYear)}>
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {searchResult?.resource_source_index?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Source Index
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {searchResult?.primary_publisher_country?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Publication Country
              </Typography>
              <Button onClick={() => setFilterCountryModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_country_name?.buckets) &&
        jsonFacets?.author_country_name?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                {/* Country of Publishing Author */}
                Author Country
              </Typography>
              <Button onClick={() => setCopaModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {searchResult?.publisher_name?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Publishers
              </Typography>
              <Button onClick={() => setFilterPublisherModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_address?.buckets) &&
        jsonFacets?.author_address?.buckets?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Institution
              </Typography>
              <Button onClick={() => setFilterUnivModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {
         <FormControlLabel
         control={<Checkbox checked={onlybooks}/>}
         onChange={handleWithChapter}
         sx={{ fontFamily: "Lora", fontWeight: "600" }}
         componentsProps={{
           typography: {
             fontFamily: "Lora",
             fontWeight: "600",
             fontSize: "14px",
           },
         }}
         label={
           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
             With Chapters
           </Box>
         }
       />
        }

        {/* <Box>
          <Typography
            mt={2}
            mb={2}
            sx={{
              fontSize: "0.875rem",
              alignSelf: "center",
              fontFamily: "Helvetica Neue",
              fontWeight: "600",
            }}
          >
            Independent Filters (1)
          </Typography>
          <Divider />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={openAccess} />}
              label="By current Update"
              onChange={handleOpenAccess}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Web of Science"
              onChange={handleRetractedArticles}
              sx={{ fontFamily: "Helvetica Neue", fontWeight: "500" }}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="DOAJ"
              onChange={handleDateSet}
              sx={{ fontFamily: "Helvetica Neue", fontWeight: "500" }}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            />
          </FormGroup>
        </Box> */}
      </Box>

      <FilterSubjectOfBook
        setFilters={setFilters}
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSpeakerTypeValues}
        filterData={searchResult?.subjects_name_l3}
        applyFilter={applyFilter}
        placeholderName="Subject"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      />

      <FilterCountry
        setFilters={setFilters}
        isOpen={filterCountryModalStatus}
        handleClose={handleChangeFilterCountry}
        modalName="Filter Publication Country"
        getFilterdValues={getCopTypeValues}
        filterData={searchResult?.primary_publisher_country}
        applyFilter={applyFilter}
        placeholderName="Publication Country"
        appliedFilterValues={selectedFilters?.countryOfPublication}
        facetKey="primary_publisher_country"
      />

      <FilterRanking
        setFilters={setFilters}
        isOpen={filterRankingModalStatus}
        handleClose={handleChangeFilterRanking}
        modalName="Filter Journal Ranking"
        getFilterdValues={getRankingTypeValues}
        filterValues={searchResult?.filter_metrix}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.journalRank}
      />

      <FilterAuthor
        setFilters={setFilters}
        isOpen={filterAuthorModalStatus}
        handleClose={handleChangeFilterAuthor}
        modalName="Filter Source Index"
        getFilterdValues={getAuthorTypeValues}
        filterValues={searchResult?.resource_source_index}
        applyFilter={applyFilter}
        // placeholderName="resource index"
        appliedFilterValues={selectedFilters?.sourceType}
        // facetKey="resource_source_index"
      />

      <FilterPublisherOfBook
        setFilters={setFilters}
        isOpen={filterPublisherModalStatus}
        handleClose={handleChangeFilterPublisher}
        modalName="Filter Publisher"
        getFilterdValues={getPublisherTypeValues}
        filterData={searchResult?.publisher_name}
        applyFilter={applyFilter}
        placeholderName="Publisher"
        appliedFilterValues={selectedFilters?.publisher}
        facetKey="publisher_name"
      />
    </>
  );
};

export default SearchFilterComponentOfBook;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
