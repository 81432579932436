import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
    isLoading: boolean;
    data: string[];
    message: string;
    folder:any
    setFolderReload:number
}

const initialState: InitialstateType = {
    isLoading: false,
    data: [],
    message: "",
    folder:{},
    setFolderReload:0
}


export const saveSearchHistorySlice = createSlice({
    name: "saveSearchHistorySlice",
    initialState,
    reducers: {
        saveSearchHistoryResults(state, { payload }) {
            state.data = payload.data;
        },
        saveSearchHistorySuccess: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data;
            // console.log('PAYLOAD DATAAAAA', payload.data[0][0])
            state.message = payload.message;
        },
        saveFolderOfMyFolder:(state,{payload}) => {
            state.folder = payload
        },
        setFolderReload:(state,{payload}) => {
            state.setFolderReload =  payload + 1
        }
    },
});

export const { saveSearchHistoryResults, saveSearchHistorySuccess,saveFolderOfMyFolder,setFolderReload } = saveSearchHistorySlice.actions;

export default saveSearchHistorySlice.reducer;

