import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { GetExternalFolderLinksData } from "../../../types/GetExternalFolder.types";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentFolder: GetExternalFolderLinksData;
}

const ExternalLinkMenu = ({ open, onClose, anchor, currentFolder }: Props) => {
  const dispatch = useAppDispatch();

  const deleteModal = () => {
    dispatch(
      setModal({
        modalType: "DELETE_FOLDER",
        modalProps: {
          show: true,
          title: "Delete Link",
          documentId: currentFolder.externallibrarydocuments_id,
          itemName: currentFolder.topic_name,
          type: "links",
        },
      })
    );
    onClose();
  };

  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem sx={{ color: "red" }} onClick={deleteModal}>
        Delete
      </MenuItem>
    </Menu>
  );
};

export default ExternalLinkMenu;
