
export function getDate(year: string, month: string, date: string): string | null {
    try {
        let str = "";
        if(month) {
            str += getMonth(Number(month)) + " "
        }
        if(date) {
            str += date + " "
        } 
        if(year) {
            str += year
        }
        return str;
    } catch(err) {
        console.log(err);
        return null
    }
}


export function getMonth(month: number): string {
    try {
        let data = ["", 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if(month < 1 || month > 12) return "";

        return data[month]
    } catch(err) {
        console.log(err);
        return month.toString()
    }
}