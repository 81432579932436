import { createSlice } from "@reduxjs/toolkit";

export interface InitialstateType {
  isLoading: boolean;
  data: string[];
  message: string;
  alertData: any;
  handleHistory: boolean;
}

const initialState: InitialstateType = {
  isLoading: false,
  data: [],
  message: "",
  alertData: [],
  handleHistory: true,
};

export const searchHistorySlice = createSlice({
  name: "searchHistorySlice",
  initialState,
  reducers: {
    updateSearchHistoryResults(state, { payload }) {
      state.data = payload.data;
    },
    searchHistorySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      console.log("PAYLOAD DATAAAAA", payload);
      state.message = payload.message;
    },
    updateAlert: (state, { payload }) => {
      state.alertData = payload;
    },
    updatingHistory: (state, { payload }) => {
      state.handleHistory = payload;
    },
  },
});

export const {
  updateSearchHistoryResults,
  searchHistorySuccess,
  updateAlert,
  updatingHistory,
} = searchHistorySlice.actions;
export default searchHistorySlice.reducer;
