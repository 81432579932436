import { useEffect, useRef, useState } from "react";
import logo from "../assets/img/logo.png";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { Colors, dataTypes } from "../../utils/constants";
import NewIcon from "../../assets/img/New.png";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";

// import search from "../../screens/Search/onSearch";
// import search from "../../screens/AuthorFinder/onAuthorResult";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  updataLibraryOpac,
  updateCollectionValue,
  updateConsortiaCollection,
  updateJgateCollection,
  updateLoadingState,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
// import { updateSearchResults } from "../../store/slice/searchSlice";
import {
  updateAuthorPage,
  updateAuthorSearchResults,
} from "../../store/slice/authorFinderSlice";
import { AuthorFinderObj } from "../../types/interface";
import { getOpacData, personalCoreCount } from "../../api/Search/CollectionApi";
import { showIsProfileToast } from "../../utils/helper";
import { authorSearchForCounts } from "../../api/authorFinderApi";
import { updateQueries } from "../../store/slice/appliedQueries";

//Type
interface PropType {
  jsonFacets?: any;
  data?: any;
  collectionsValue?: string;
  setcollectionsValue?: any;
  allCount?: number | string;
}

const AuthorCollectionsComponent = (props: PropType) => {
  let { jsonFacets, data, collectionsValue, setcollectionsValue, allCount } =
    props;

  const dispatch = useAppDispatch();

  const dataType: any = useAppSelector((state) => state.authorReducer.dataType);

  const [isProfileUser, setIsProfileUser] = useState(false);

  const dataTypeToString =
    Array.isArray(dataType) && dataType?.map((val) => val.toString());

  const queryCount = useAppSelector((state) => state.queryForCount);

  const [count, setCount] = useState({
    jgateCollection: 0,
    personalLibCollection: 0,
    myLibrary: 0,
    consortiaSubscription: 0,
  });

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const filterReducer = useAppSelector((state) => state.filterReducer);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const showJgateCollection = customerDetails?.product_type?.includes("1");

  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  const showConsortiaSubscription =
    customerDetails?.product_type?.includes("7");

  const handleChangeCollections = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateAuthorPage(1));

    if (event.target.value === "MyLibraryCollection") {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "MyPersonalLibraryCollection" &&
      isProfileUser
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "J-GateCollection" ||
      event.target.value === "ConsortiaSubscriptions" ||
      event.target.value === "ONOSCollections" ||
      event.target.value === "Library OPAC"
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    }
    dispatch(updateCollectionValue(event?.target?.value));

    if (event.target.value === "J-GateCollection") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));

      dispatch(
        updateQueries({
          key: "jgateCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ConsortiaSubscriptions") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "consortiaSubscriptions",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "MyLibraryCollection") {
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateMyLibraryCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "myLibraryCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ONOSCollections") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(true));
      dispatch(updatePersonalLibrary(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "onosCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "Library OPAC") {
      fetchOpacData();
    } else if (event.target.value === "MyPersonalLibraryCollection") {
      if (isProfileUser) {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updatePersonalLibrary(true));

        dispatch(
          updateQueries({
            key: "myPersonalLibraryCollection",
            value: true,
          })
        );

        // dispatch(updateJgateCollection(false));
        triggerEvent.current = triggerEvent.current + 1;
      } else {
        console.log("not profiel user");
        showIsProfileToast(
          "Please login as profile user to view this collection"
        );
      }
    }
  };

  async function fetchOpacData() {
    if (!customerDetails?.informaticscustomer_id) return;
    let searchTerm = authorReducer.searchTerm.toString();
    dispatch(updateMyLibraryCollection(false));
    dispatch(updateConsortiaCollection(false));
    dispatch(updateOnosCollection(false));
    dispatch(updatePersonalLibrary(false));
    dispatch(updataLibraryOpac(true));

    dispatch(
      updateQueries({
        key: "opac",
        value: true,
      })
    );

    dispatch(search());
  }

  const triggerEvent = useRef(0);

  useEffect(() => {
    if (triggerEvent.current) applyFilter();
  }, [triggerEvent.current]);

  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  useEffect(() => {
    updateCollectionCount();
  }, [queryCount.allTextQuery, queryCount.fullTextQuery]);

  async function applyFilter() {
    dispatch(search("", customerDetails, true));
  }

  useEffect(() => {
    if (
      !filterReducer.libraryOpac &&
      !filterReducer.myLibraryCollection &&
      !filterReducer.myPersonalLibCollection &&
      !filterReducer.jgateCollection &&
      !filterReducer.onosCollection &&
      !filterReducer.consortiaCollection
    ) {
      if (
        customerDetails?.product_type === "1" ||
        customerDetails?.product_type === "1,7"
      ) {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updateJgateCollection(true));

        dispatch(
          updateQueries({
            key: "jgateCollection",
            value: true,
          })
        );
      } else if (customerDetails?.product_type === "7") {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateJgateCollection(false));
        dispatch(updateConsortiaCollection(true));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));

        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }
  }, []);

  // display the collection based on selected document types

  const showMyLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") ||
      dataTypeToString.includes("2") ||
      dataTypeToString.includes("3"));

  //const showKohaFilter = customerDetails?.other_product_type === "Koha";
  const showKohaFilter = customerDetails?.other_integreted_product?.map(
    (val) => val?.other_product_type === "Koha"
  );

  const showPersonalLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") || dataTypeToString.includes("2"));

  async function updateCollectionCount() {
    try {
      let encodedString = encodeURIComponent(
        `${customerDetails?.informaticscustomer_id} OR ${customerDetails?.consortia_virtualid}`
      );
      let removeConsortiaParam = `&fq__acl_group=(${encodedString})`;

      // &fq__acl_group=1848
      let keywordToRemove = customerDetails?.informaticscustomer_id
        ? `&fq__acl_group=${customerDetails?.my_library_filter}`
        : "";
      const consortiaFilter =
        "&fq__acl_group=" + customerDetails?.consortia_filter;
      const decodedQuery = decodeURIComponent(queryCount.allTextQuery);
      let query = decodedQuery.replace(
        new RegExp("\\b" + keywordToRemove + "\\b", "gi"),
        ""
      );

      query = decodedQuery.replace(keywordToRemove, "");
      let finalQuery = query.replace(removeConsortiaParam, "");
      finalQuery = finalQuery.replace(consortiaFilter, "");

      /**
       *  To get the count of J-gate collection
       */
      let res = await authorSearchForCounts(finalQuery);
      let count = res?.hits;

      if (count) {
        setCount((prev) => ({
          ...prev,
          jgateCollection: count,
        }));
      }
      /**
       *  To get the count of My Personal Library collection
       */
      const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
      if (isProfileUser) {
        let personalCore = await personalCoreCount(query);
        let personalCollectionCount = personalCore?.hits;
        if (personalCollectionCount) {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: personalCollectionCount,
          }));
        } else {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: 0,
          }));
        }
      }

      /**
       *  To get the count of My Library collection
       */
      const myLibFilter =
        "&fq__acl_group=" + customerDetails?.my_library_filter;
      let myLibRes = await authorSearchForCounts(query + myLibFilter);
      let myLibCount = myLibRes?.hits;
      if (myLibCount) {
        setCount((prev) => ({
          ...prev,
          myLibrary: myLibCount,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          myLibrary: 0,
        }));
      }

      /**
       *  To get the count of Consortia subscription collection
       */
      if (customerDetails?.consortia_filter) {
        let consortiaParam = `&fq__acl_group=${customerDetails?.consortia_filter}`;
        let consortiaRes = await authorSearchForCounts(query + consortiaParam);
        let consortiaCount = consortiaRes?.hits;

        if (consortiaCount) {
          setCount((prev) => ({
            ...prev,
            consortiaSubscription: consortiaCount,
          }));
        } else {
          setCount((prev) => ({
            ...prev,
            consortiaSubscription: 0,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  // If thesis doc type selected form the document type filter only show the jgate collection
  const isThesisDocTypeSelected =
    filterReducer.sourceType.length === 1 &&
    (filterReducer.sourceType.includes(dataTypes.dissertationThesis) ||
      filterReducer.sourceType.includes(dataTypes.dataset));

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={{ paddingInline: "7%", paddingBlock: "4%" }}>
          <Typography sx={webStyle.titleHeader} gutterBottom>
            Collections
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={collectionsValue}
            onChange={handleChangeCollections}
          >
            {showJgateCollection && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="J-GateCollection"
                control={<Radio />}
                // label={`J-Gate Collection (${data?.hits ? data?.hits : ""})`}
                label={`J-Gate Collection ${
                  count.jgateCollection ? "(" + count.jgateCollection + ")" : ""
                }`}
              />
            )}
            {!isThesisDocTypeSelected && count?.myLibrary > 0 && showMyLib && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyLibraryCollection"
                control={<Radio />}
                // label="My Library Collection"
                label={`${
                  customerDetails?.customershortname != null
                    ? customerDetails?.customershortname + " " + "Subscribed"
                    : "My Library Collection"
                } ${count.myLibrary ? "(" + count.myLibrary + ")" : ""}`}
              />
            )}
            {showKohaFilter && !isThesisDocTypeSelected && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value={"Library OPAC"}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    Library Catalogue{" "}
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            )}
            {!isThesisDocTypeSelected && showPersonalLib && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyPersonalLibraryCollection"
                control={<Radio />}
                // label="My Personal Library Collection"
                label={`My Personal Library Collection ${
                  count.personalLibCollection
                    ? "(" + count.personalLibCollection + ")"
                    : ""
                } `}
              />
            )}

            {!isThesisDocTypeSelected &&
              showConsortiaSubscription &&
              count.consortiaSubscription && (
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      fontFamily: "Lora",
                      fontWeight: "600",
                    },
                  }}
                  value="ConsortiaSubscriptions"
                  control={<Radio />}
                  label={`Consortia Subscriptions ${
                    count.consortiaSubscription
                      ? "(" + count.consortiaSubscription + ")"
                      : ""
                  }`}
                />
              )}

            {!isThesisDocTypeSelected && customerDetails?.is_enable_onos ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="ONOSCollections"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    ONOS Collections
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            ) : null}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default AuthorCollectionsComponent;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
