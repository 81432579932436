import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import backgroundImage from "../../assets/images/journal_img.png";

interface PropType {
  sjrId: string;
  isOpen: boolean;
  handleClose: () => void;
}
export default function SJRModal(props: PropType) {
  const { sjrId, isOpen, handleClose } = props;
  let imageUrl = `https://www.scimagojr.com/journal_img.php?id=${sjrId}`;
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontFamily: "Lora", fontWeight: "600" }}>
            Scimago Journal Ranking
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "100%",
          minHeight: "200px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src={imageUrl}
            width={"200px"}
            height={"200px"}
            loading="eager"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
