function objectToQueryString(obj: Record<string, any>): string {
  let queryString = "";
  for (let key in obj) {
    if (obj?.hasOwnProperty(key)) {
      queryString += key + "=" + obj[key] + "&";
    }
  }
  // remove the last "&"
  queryString = queryString.slice(0, -1);
  return queryString;
}

export default objectToQueryString;

// Usage
//
//
// let myObject = {
//     page: 1,
//     sessionid: "co4amhw2c43p8rw9f8bbu3hsjjeh9nwg",
//     titleKeywordsAbs: "comp",
// };
// console.log(objectToQueryString(myObject));
//
// Returns
//
// ?key1=val1&key2=val2&key3=val3&key4=val4
