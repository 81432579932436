import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { GetUserL2L3SubjectsRoot, GetUserL2L3Subject } from "../../types/GetUserL2L3Subjects";
import http from "../httpService";
import { queryClient } from "../../App";
import api from "../endpoints";


const setBooksURL = api.baseApi + `/api/set_subject_alert_for_article_level_datatype/set_subject_alert_for_article_level_datatype`

export const useGetBooksSubjects = (userId: string) => {
    const params: any = {};
    params["datatype_id"] = 12; // Books Code: 12
    if (userId) params["profileuserid"] = userId;
  
    return useQuery(
      ["GetBooksSubjects", userId],
      () =>
        http.get(
          "/api/get_userwise_all_l2_l3_subject_details/get_userwise_all_l2_l3_subject_details",
          {
            params,
          }
        ),
      {
        select: (
          res: AxiosResponse<GetUserL2L3SubjectsRoot>
        ): GetUserL2L3Subject[] => {
          return res.data.data.Subjects;
        },
      }
    );
  };
  

export const useMutateUpdateBooksSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/update_datatype_wise_personalisation_subjects/update_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetBooksSubjects"]);
        console.log("Success, Updated Books Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateDeleteBooksSubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_datatype_wise_personalisation_subjects/remove_datatype_wise_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetBooksSubjects"]);
        console.log("Success, Deleted Books Subjects");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};



export async function setBooksAlert({ alertjson }: any) {

  console.log('Alert JSON',alertjson)
  try {

    console.log('Try Triggered')
    const res = await axios.post(setBooksURL, { alertjson: JSON.stringify(alertjson) },
    {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }
);

    console.log('URL Query',setBooksURL, { alertjson: JSON.stringify(alertjson) })
    console.log('Books Alert RESPONSE', res)

    if(res.data.data[0][0] == "New Alert created successfully!"){
      return res;
    }


  } catch (error) {
    console.log("error", error)

  }
}