import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  isAuth: boolean;
  user_id: string;
  csrf_token: string;
  isLoading: boolean;
  data: string;
  informaticscustomer_id: string;
  session_key: string;
  error: string;
}

const initialState: UserState = {
  isLoading: false,
  data: "",
  session_key: "",
  error: "",

  informaticscustomer_id: "",
  isAuth: false,
  user_id: "",
  csrf_token: "",
};

const getIntialState = (): UserState => {
  const csrfToken = sessionStorage.getItem("csrf_token") || "";
  const session_key = sessionStorage.getItem("session_key") || "";
  const user_id = sessionStorage.getItem("user_id") || "";
  const informaticscustomer_id =
    sessionStorage.getItem("informaticscustomer_id") || "";
  return {
    ...initialState,
    isAuth: !!csrfToken,
    csrf_token: csrfToken,
    user_id: user_id,
    informaticscustomer_id: informaticscustomer_id,
    session_key: session_key,
  };
};

const loginSlice = createSlice({
  name: "login",
  initialState: getIntialState(),
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      state.data = payload.data;
      state.informaticscustomer_id = payload.informaticscustomer_id;
      state.session_key = payload.session_key;
      state.isAuth = payload.isAuth;
      state.user_id = payload.user_id;
      state.csrf_token = payload.csrf_token;
    },
    loginFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.data = payload;
      state.isAuth = false;
    },
    logout: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.data = "";
      state.session_key = "";
      state.error = "";
      state.informaticscustomer_id = "";
      state.isAuth = false;
      state.user_id = "";
      state.csrf_token = "";
    },
    resetpassword: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.data = payload;
      state.user_id = payload;
    },
  },
});

const { reducer, actions } = loginSlice;

export const { loginPending, loginSuccess, loginFail, logout, resetpassword } =
  actions;

export default reducer;
