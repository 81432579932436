import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/FolderOpen";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { Colors } from "../../../utils/constants";
import { useGetExternalFolders } from "../../../api/MyLibrary/Links.api";
import ExternalFolderMenu from "./ExternalFolderMenu";

type ExtFolder = {
  id: string;
  name: string;
  created_date: string;
  no_of_records: any;
};

const ExternalFolders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [myFolders, setMyFolders] = useState<ExtFolder[] | null>(null);
  const [currentFolder, setCurrentFolder] = useState<ExtFolder | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: response } = useGetExternalFolders(selector.user_id);

  useEffect(() => {
    if (response) {
      const newResponse = response?.map((item) => {
        const currKey = Object.keys(item)[0];
        const currValue = Object.values(item)[0];
        return {
          id: currKey,
          name: currValue,
          created_date: item.created_date,
          no_of_records: item.no_of_records,
        };
      });

      setMyFolders(newResponse);
    }
  }, [response]);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: ExtFolder
  ) => {
    event.stopPropagation();
    setCurrentFolder(row);
    setAnchorEl(event.currentTarget);
  };

  const handleTableCellClick = ({ id, name }: ExtFolder) =>
    navigate("/my-library/links/" + id, {
      state: { id: id, folder_name: name },
    });

  const addModal = () =>
    dispatch(
      setModal({
        modalType: "ADD_FOLDER",
        modalProps: {
          show: true,
          title: "Add New Folder",
          isExternalLinks: true,
        },
      })
    );

  return (
    <>
      <Button variant="outlined" sx={{ my: 2, ml: "auto" }} onClick={addModal}>
        Add New Folder
      </Button>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Folder Name</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>No of Links</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {myFolders &&
              myFolders?.map((row, i) => (
                <TableRow hover key={i}>
                  <TableCell onClick={() => handleTableCellClick(row)}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <FolderIcon />
                      <Text fontSize={14} marginLeft={1}>
                        {row.name}
                      </Text>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => handleTableCellClick(row)}>
                    <Text fontSize={14}>{row.created_date}</Text>
                  </TableCell>

                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>{row.no_of_records ?? "N/A"}</Text>

                    <IconButton onClick={(e) => handleOptionClick(e, row)}>
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {currentFolder && (
        <ExternalFolderMenu
          open={open}
          anchor={anchorEl}
          onClose={closeMenu}
          currentFolder={currentFolder}
        />
      )}
    </>
  );
};

export default ExternalFolders;
