import http from "../httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import {
  GetSearchAlertData,
  GetSearchAlertRoot,
} from "../../types/GetSearchAlerts.types";

export const useGetSearchAlerts = (userId: string, enabled?: boolean) => {
  const params: any = {};
  if (userId) params["profileuser_id"] = userId;

  return useQuery(
    ["GetSearchAlerts", userId],
    () =>
      http.get(
        "/api/get_all_saved_subject_alerts_for_profile/get_all_saved_subject_alerts_for_profile",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetSearchAlertRoot>
      ): GetSearchAlertData[] => {
        return res.data.data[0][0];
      },
      enabled: enabled,
    }
  );
};

export const useMutateCreateMyFolder = () => {
  return useMutation(
    (obj: { formData: FormData; csrf: any }) =>
      http.post("/api/CreateProfileFolder/CreateProfileFolder", obj?.formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetMyFolders"]);
        console.log("Success, Folder created");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export async function searchTocAlert(userId: string, keyword: string) {
  try {
    const url = `/api/indexing/solr/resourcecore?page=1&profileuserid=${userId}&resource_name_fz=${
      keyword + "ORresource_shortname_fz:"
    }(${keyword})`;

    // api call
    let res = await http.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
  }
}

// let url ={`${api.baseApi}/api/indexing/solr/resourcecore`?page=1&profileuserid=${
//   profileData.user_id
// }&sessionid=${profileData.session_key}
//     &resource_name_fz=${keyword}+OR+resource_shortname_fz:(${keyword})`}

export async function subscribeToRss(formData: FormData) {
  try {
    const response = await http.post(
      "/api/set_subject_alert_for_profile/set_subject_alert_for_profile",
      formData
    );

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function checkAlertTopicAvailability(topicName: string) {
  const response = await http.get(`/api/check_alert_topic_availabilty/?profile_id=28571&alert_topic=${topicName}`);
  console.log(response, "xyz")
  if(response.data.data === "Success") {
    return "Success"
  } else {
    return "Alert Topic already exists"
  }
}
