import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

interface Props {
  name: any;
  control: any;
  placeholder?: string;
  type?: string;
  isEdit?: boolean;
  required?: boolean;
  size?: "small" | "medium" | undefined;
}

const CustomTextfield = ({
  control,
  name,
  isEdit,
  placeholder,
  type,
  required = false,
  size = undefined,
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: required }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <TextField
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        disabled={!isEdit}
        fullWidth
        size={size}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
        }}
        error={!!error}
      />
    )}
  />
);

export default CustomTextfield;
