import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import { Colors } from "../../../utils/constants";
import { useMutateCreateExternalFolderLink } from "../../../api/MyLibrary/Links.api";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomTextfield from "../../formInputs/CustomTextfield";
import { usagelogApi } from "../../../api/usageReportApi";

interface Props {
  show: boolean;
  title: string;
  folder_id: string;
}

export type Inputs = {
  topic_name: string;
  topic_url: string;
  remarks: string;
};

const AddLinkModal = ({ show, title, folder_id }: Props) => {
  const { handleSubmit, control } = useForm<Inputs>();

  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const createExternalFolderLink = useMutateCreateExternalFolderLink();

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => handleAdd(data);

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleAdd = async ({ topic_name, topic_url, remarks }: Inputs) => {
    const formData = new FormData();
    formData.append("folder_id", folder_id);
    formData.append("topic_name", topic_name);
    formData.append("topic_url", topic_url);
    formData.append("remarks", remarks);

    createExternalFolderLink.mutate({
      formData: formData,
      csrf: selector.csrf_token,
    });
    dispatch(hideModal());
    logBasicSearchUsageData(114);
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AddLinkModal-Title"
      aria-describedby="AddLinkModal-Description"
    >
      <form
        style={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>Title</Text>
          <CustomTextfield
            control={control}
            name="topic_name"
            isEdit={true}
            placeholder={"Enter Title"}
            type="text"
            required={true}
            size="small"
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>Paste Link Title</Text>
          <CustomTextfield
            control={control}
            name="topic_url"
            isEdit={true}
            placeholder={"Paste link here"}
            type="text"
            required={true}
            size="small"
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>Description</Text>
          <CustomTextfield
            control={control}
            name="remarks"
            isEdit={true}
            placeholder={"Enter Description"}
            type="text"
            required={true}
            size="small"
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            type="submit"
          >
            Add Link
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default AddLinkModal;
