import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Tooltip } from "@mui/material";

const DownloadButton = (props: any) => {
  const { setGridDisplay, GridDisplay, resource_id, setGridId, showGrid } =
    props;
  return (
    <Tooltip
      title={
        resource_id?.access_type_id === 6 && resource_id?.embargo_type === 6
          ? `Full text will be available after ${resource_id?.embargo} days`
          : "Open access"
      }
    >
      <LockOpenIcon
        color="secondary"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Tooltip>
  );
};

export default DownloadButton;

const webStyles = {};
