import { useMutation } from "@tanstack/react-query";
import http from "../httpService";

type Props = {
  body: any;
};

const reactivateAccountAPI = ({ body }: Props) => {
  return http.post(
    "/User/activate_deleted_account/activate_deleted_account",
    body
  );
};

export const useReactivateAccount = () => {
  return useMutation({
    mutationFn: reactivateAccountAPI,
    onError: (err) => {
      console.error(err);
    },
  });
};
