import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    tab: any;
}

const initialState:InitialState  = {
    tab: 0
}

const personalLibrary = createSlice({
    name: "personalLibrary",
    initialState,
    reducers: {
        updateTabPersonalLibrary(state, {payload}) {
            state.tab = payload
        }
    }
});

export const { updateTabPersonalLibrary } = personalLibrary.actions;
export default personalLibrary.reducer;
