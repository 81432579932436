import {
  Alert,
  Box,
  Button,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../utils/constants";

import IconButton from "@mui/material/IconButton";

import { MailOutline } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FolderIcon from "@mui/icons-material/Folder";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCiteArticleDetails,
  getCiteDescription,
} from "../../api/citeArticle";
import BrokenLinkIcon from "../../assets/img/broken_image.png";
import ComposeEmail from "../../components/basicSearchTopBar/composeEmail";
import AddToFolder from "../../components/resultsPopups/AddToFolder";
import AbstractView from "../../components/rightDrawer/abstractIndex";
import ScrollToTop from "../../hooks/scrollToTop";
import { useAppSelector } from "../../store/hooks";
// import CiteArticle from "../../components/resultsPopups/CiteArticle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { basicSearchAuthorEmail } from "../../api/Search/BasicSearch.api";
import { getSingleArticleDetails } from "../../api/browseJournal";
import { getDdrStatus } from "../../api/ddrFormApi";
import api from "../../api/endpoints";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { usagelogApi } from "../../api/usageReportApi";
import { profileDetailsAPI } from "../../api/userApi";
import CiteArticle from "../../components/resultsPopups/CiteArticle";
import ReportIssue from "../../components/resultsPopups/ReportIssue";
import HoverOverToolTips from "../../components/tootTips/hoverOverToolTips";
import { setModal } from "../../store/slice/modalSlice";
import { LoginCred } from "../../types/interface";
import { notify } from "../../utils/Notify";
import { getParamsByName, showIsProfileToast } from "../../utils/helper";

const AbstractFullScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [top, setTop] = useState(true);
  const location = useLocation();
  const [dialogueData, setDialogueData] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [resultFrom, setResultFrom] = useState<any>("");
  const [linkToDisplay, setLinkToDisplay] = useState<any>([]);

  const id = getParamsByName("id");

  const searchReducer = useAppSelector((state) => state.searchReducer);
  const [emailModal, setEmailPopUp] = useState(false);
  const [cite, setCite] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [openViewModal, setOpenViewModal] = useState<Boolean>(false);
  const [DDR, setDDR] = useState(false);
  const [findInLibrary, setFindInLibrary] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [citation, setCitation] = useState<string>(
    "APA - American Psychological Association"
  );
  const [tops, setTops] = useState(false);
  const [citeDetailsDropDown, setciteDetailsDropDown] = useState<any>([]);

  const [articleApiDetails, setArticleApiDetails] = useState("");

  const [email, setEmail] = useState("");
  const [reciepientName, setReciepientName] = useState("");

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const allowProfile = customerDetails?.allow_profile;

  const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
  const isDSpace = useAppSelector((state) => state.filterReducer.dspace);

  const [authorEmailData, setAuthorEmailData] = useState([]);
  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);

  const savedArticles = useAppSelector(
    (state) => state.searchReducer?.savedArticles
  );
  const savedArticlesOfAuthorSearch = useAppSelector(
    (state) => state.authorReducer.savedArticles
  );

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder?.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch?.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logPrintUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handndleAuthorEmaiData = async () => {
    const authorData = await basicSearchAuthorEmail(dialogueData?.article_id);
    setAuthorEmailData(authorData);
  };

  const updateDdrStatus = async () => {
    const customerId = sessionStorage.getItem("informaticscustomer_id");
    const status = await getDdrStatus(
      `?customer_id=${customerId}&resourceissue_id=${dialogueData?.resourceissue_id}&article_id=${dialogueData?.article_id}&is_oa_article=&article_doi=${dialogueData?.articledoi}`
    );

    if (status?.data?.data?.button_to_display?.includes("request_article")) {
      setDDR(true);
    } else if (
      status?.data?.data?.button_to_display.includes("find_in_library")
    ) {
      setFindInLibrary(true);
    }
  };

  const handleEmailModal = (e: any) => {
    setEmailPopUp(!emailModal);
  };

  function closeAddToFolder() {
    setOpenFolder(!openFolder);
  }

  const handleCite = async () => {
    setCite(!cite);
    setCitation("APA - American Psychological Association");
    handleCiteApi(1);
    if (!cite) {
      let authorList = "";
      let publisher = "";
      const response = await getCiteArticleDetails();
      setciteDetailsDropDown(response?.data.data[0]);
      // response?.data.data[0][0][0]?.dat_cite_type_master_id
      handleCiteApi(response?.data.data[0][0][0]?.dat_cite_type_master_id);
    }
  };

  const handleOpenViewModal = () => {
    if (allowProfile) {
      setOpenViewModal(true);
    } else {
      notify("error", "Access Denied!");
    }
  };

  const handleCloseViewModal = () => setOpenViewModal(false);

  //print
  // const userid = sessionStorage.getItem("user_id");
  const userid = sessionStorage.getItem("user_id");
  const getUserData = async () => {
    const UserData: any = await profileDetailsAPI({ userid });

    setEmail(UserData.data?.data[0][0].emailid);
    setReciepientName(UserData.data?.data[0][0].firstname);
  };

  let loginId: any = useSelector((state: LoginCred) => state?.login?.user_id);
  let composeData = [
    {
      search_term: searchTerm?.toString(),
      article_id: dialogueData?.article_id,
    },
  ];
  const handlePrint = async (e: any) => {
    e.preventDefault();
    try {
      getUserData();

      const loggedin_customer_id = sessionStorage.getItem("user_id") || loginId;
      let emailData = [
        {
          search_term: searchTerm?.toString(),
          article_id: dialogueData?.article_id,
        },
      ];

      let obj = JSON.stringify(emailData);
      const download_type = 3;
      const selected_type = 1;

      const win: Window | null = window.open(
        `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?&loggedin_customer_id=${loggedin_customer_id}&article_details=${obj}&download_type=${download_type}&selected_type=${selected_type}`
      );
      // const win: Window | null = window.open(printVal);

      if (win) {
        win.onload = () => {
          win.print();
        };
      }
    } catch (error) {
      console.log("error", error);
    }
    logPrintUsageData(99, dialogueData);
  };

  function handleAlert(status: boolean, message: string) {
    setAlertMessage(message);
    setOpen(status);
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleChange = (event: any) => {
    setCitation(event.target.value as string);
    let id;
    if (event.target.value === "APA - American Psychological Association") {
      id = 1;
    } else if (event.target.value === "CHICAGO") {
      id = 2;
    } else if (event.target.value === "HARVARD") {
      id = 3;
    } else if (event.target.value === "MLA- Modern Language Association") {
      id = 4;
    } else if (event.target.value === "VANCOUVER") {
      id = 5;
    }
    handleCiteApi(id);
  };

  const handleCiteApi = async (citeMasterId: any) => {
    let res = await getCiteDescription(dialogueData?.article_id, citeMasterId);
    const citeResult = res?.data?.data[0]?.cite_result;
    setArticleApiDetails(citeResult);
  };

  function findFolderId(article_id: string | number): number | null {
    if (!Array.isArray(savedArticles)) return null;

    let newArr = [...savedArticles];
    if (savedArticlesOfAuthorSearch?.length) {
      newArr = [...newArr, ...savedArticlesOfAuthorSearch];
    }

    let SavedArticle = newArr.filter((obj) => obj?.article_id == article_id);

    if (SavedArticle.length == 1) {
      let id = SavedArticle[0].folder_id;
      if (id) {
        return Number(id);
      }
    }
    return null;
  }

  function openDeleteArticleModal(
    article_id: string,
    folderId: string | number | null
  ) {
    dispatch(
      setModal({
        modalType: "REMOVE_ARTICLE",
        modalProps: {
          isOpen: true,
          article_id,
          folderId,
        },
      })
    );
  }
  const fetchData = async () => {
    if (id) {
      try {
        const res1 = await getSingleArticleDetails(id);
        const cust_id: any = profileData1?.informaticscustomer_id
          ? profileData1?.informaticscustomer_id
          : sessionStorage.getItem("informaticscustomer_id") || "0";
        let formData = new FormData();
        const doc = res1?.data?.data?.docs[0];
        // let x = [
        //   {
        //     customer_id: cust_id,
        //     resource_id: res1?.data?.data?.docs[0]?.resourceissue_id,
        //     article_id: res1?.data?.data?.docs[0].article_id,
        //     resource_type: res1?.data?.data?.docs[0]?.resource_type,
        //     is_oa_article: res1?.data?.data?.docs[0]?.is_oa_article,
        //     access_type: res1?.data?.data?.docs[0]?.access_type,
        //     article_doi: res1?.data?.data?.docs[0]?.article_doi,
        //     resourceissue_id: res1?.data?.data?.docs[0]?.resourceissue_id,
        //   },
        // ];

        let object: any = {
          customer_id: cust_id,
          article_id: doc?.article_id,
          is_oa_article: doc?.fulltext || false,
          access_type: doc?.article_open_status || null,
        };

        if (profileData1?.consortia_virtualid) {
          object.consortia_virtualid = profileData1?.consortia_virtualid;
        }

        if (doc?.articledoi) {
          object.article_doi = doc?.articledoi;
        }
        if (doc?.resourceissue_id) {
          object.resourceissue_id = doc?.resourceissue_id;
        }
        if (doc?.resource_type) {
          object.resource_type = doc?.resource_type;
        }

        let formApiData = { data: [object] };
        formData.append("detailed_json", JSON.stringify(formApiData));
        const res2 = await getBulkLinkRecords(formData);
        const data = res1?.data?.data?.docs[0];
        setDialogueData(data);
        setSearchTerm("");
        setResultFrom("");
        setLinkToDisplay(res2?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setSearchTerm(location.state.searchTerm);
      setResultFrom(location.state.resultFrom);
      setLinkToDisplay(location.state.linkToDisplay);
      setDialogueData(location.state.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id, profileData1]);

  useEffect(() => {
    handndleAuthorEmaiData();
    updateDdrStatus();
  }, [dialogueData]);

  return (
    <Box sx={{ minHeight: "100vh", background: "#F9F8F6" }}>
      <ScrollToTop />

      <Box bgcolor={"#FFF"}>
        <Box
          sx={[
            webStyle.flexBox,
            { width: "95%", marginInline: "auto", paddingBlock: "1%" },
          ]}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                id
                  ? navigate("/initialSearch")
                  : location?.state?.from === "browserJournalSearch"
                  ? navigate(-3)
                  : navigate(-1);
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "1.2rem" }}></ArrowBackIosIcon>
            </IconButton>

            <Typography
              variant="h6"
              alignSelf={"center"}
              fontWeight={"700"}
              fontFamily={"Lora"}
            >
              {/* Abstract Details */}
              Full Record
            </Typography>
            {DDR ? (
              <Button
                onClick={async () => {
                  navigate("DdrScreen", {
                    state: { dialogueData },
                  });
                }}
              >
                DDR
              </Button>
            ) : null}

            {/* {findInLibrary ? (
              <Button
                onClick={async () => {
                  // let dataToDisplay = await findInLibraryApi(
                  //   data
                  // );

                  navigate("findlibrary", {
                    state: { dialogueData },
                  });
                }}
              >
                Find In Article
              </Button>
            ) : null} */}
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>
          </Box>
          <Box sx={{ display: "flex" }}>
            {allowProfile && !isDSpace && (
              <>
                {" "}
                <Tooltip title="Email">
                  <IconButton
                    onClick={() => {
                      if (allowProfile) {
                        setEmailPopUp(true);
                      } else {
                        notify("error", "Access Denied!");
                      }
                    }}
                  >
                    <MailOutline></MailOutline>
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Add to Folder">
              <IconButton
                onClick={() => {
                  
                  if (isProfileUser) {
                    setOpenFolder(true);
                  } else {
                    showIsProfileToast(
                      "Please login as profile user to add the item to folder"
                    );
                  }
                }}
              >
                <FolderIcon></FolderIcon>
              </IconButton>
            </Tooltip> */}
                {!isOpac && !isDSpace && (
                  <>
                    {findFolderId(dialogueData?.article_id) ? (
                      <HoverOverToolTips
                        title="Delete from Folder"
                        handleChange={() =>
                          openDeleteArticleModal(
                            dialogueData?.article_id,
                            findFolderId(dialogueData?.article_id)
                          )
                        }
                        icon={<DeleteIcon />}
                      />
                    ) : (
                      <HoverOverToolTips
                        icon={<FolderIcon />}
                        title="Add to Folder"
                        handleChange={() => {
                          if (isProfileUser) {
                            setOpenFolder(true);
                          } else {
                            showIsProfileToast(
                              "Please login as profile user to add the item to folder"
                            );
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {!isDSpace && (
              <Tooltip title="Print">
                <IconButton
                  // onClick={printData}
                  onClick={handlePrint}
                >
                  <LocalPrintshopIcon></LocalPrintshopIcon>
                </IconButton>
              </Tooltip>
            )}
            {!isDSpace && (
              <Tooltip title="Cite">
                <IconButton onClick={() => handleCite()}>
                  <FormatQuoteIcon></FormatQuoteIcon>
                </IconButton>
              </Tooltip>
            )}

            {/* <IconButton>
            <FileDownloadIcon></FileDownloadIcon>
          </IconButton> */}
            {!isDSpace && (
              <Tooltip title="Report Link Issue">
                <IconButton onClick={handleOpenViewModal}>
                  <img src={BrokenLinkIcon} alt="BrokenLinkIcon" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      <ComposeEmail
        handleEmailModal={handleEmailModal}
        state={emailModal}
        id={composeData}
        articleData={dialogueData}
        resultFrom={resultFrom}
      />

      <AddToFolder
        isOpen={openFolder}
        handleClose={closeAddToFolder}
        articleId={dialogueData?.article_id}
        handleAlert={handleAlert}
        articleData={dialogueData}
        resultFrom={resultFrom}
      />

      <CiteArticle
        setCitation={setCitation}
        citation={citation}
        articleApiDetails={articleApiDetails}
        citeDetailsDropDown={citeDetailsDropDown[0]}
        dialogueData={dialogueData}
        openViewModal={cite}
        handleCloseViewModal={() => handleCite()}
        handleChange={handleChange}
        data={dialogueData}
        composeData={composeData}
        resultFrom={resultFrom}
      />

      <ReportIssue
        openViewModal={openViewModal}
        handleCloseViewModal={handleCloseViewModal}
        id={dialogueData?.article_id}
        vol={dialogueData?.volumefrom}
        issue={dialogueData?.issuenumberfrom}
        articledoi={dialogueData?.articledoi}
        resourcemaster_id={dialogueData?.resourcemaster_id}
      />

      <AbstractView
        dialogueData={dialogueData}
        screen={true}
        linkToDisplay={linkToDisplay}
        authorEmailData={authorEmailData}
        setTop={setTops}
        resultFrom={resultFrom}
      />

      {/* =========POP UP ================ */}
    </Box>
  );
};

export default AbstractFullScreen;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
