import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import { Box } from "@mui/system";
import MainNav from "../components/Nav/MainNav";

// Types
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getRemoteLogUserDetails } from "../api/Auth/RemoteLog.api";
import { customerDetailsById } from "../api/customerDetails";
import getCustomerDetailsByIp from "../api/getCustomerDetailByIp";
import { usagelogApi } from "../api/usageReportApi";
import Footer from "../components/footer";
import { useAppSelector } from "../store/hooks";
import { updateCaptchaStatus } from "../store/slice/captchaVerification";
import {
  clearCustomerDetails,
  updateCustomerDetails,
} from "../store/slice/customerDetails";
import { updateLoadingState } from "../store/slice/filterSlice";
import { loginSuccess } from "../store/slice/loginSlice";
import { setModal } from "../store/slice/modalSlice";
import {
  reset,
  updateDataType,
  updateDefaultDataType,
  updateSubjectL1Type,
  updateSubjectType,
} from "../store/slice/searchSlice";
import { AuthLayout as PropType } from "../types/interface";
import { notify } from "../utils/Notify";
import {
  getSessionCookieValue,
  setSessionCookie,
} from "../utils/sessionCookie";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AuthLayout = (props: PropType) => {
  const navigate = useNavigate();
  const authState = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const location = useLocation();
  const [ipAddress, setIpAddress] = useState<string>("");
  // const customerId =
  //   useSelector((state: any) => state.login?.informaticscustomer_id) ||
  //   sessionStorage.getItem("informaticscustomer_id") ||
  //   0; // "0" for the guest user to get all subject and doc types
  const selector = useAppSelector((state) => state.login);

  const customer_Id = useSelector((state: any) => state.login);
  const customerIdFromSession = sessionStorage.getItem(
    "informaticscustomer_id"
  );

  const customerId =
    customer_Id &&
    customer_Id?.informaticscustomer_id &&
    customer_Id?.informaticscustomer_id !== "undefined"
      ? customer_Id?.informaticscustomer_id
      : customerIdFromSession && customerIdFromSession !== "undefined"
      ? customerIdFromSession
      : "0";

  const customerDetails = useAppSelector(
    (state) => state?.customer?.customerDetails
  );

  const subjectType = useAppSelector(
    (state) => state.searchReducer?.subjectType
  );

  const defaultDocType = useAppSelector(
    (state) => state.searchReducer.defaultDataType
  );

  const isCaptchaVerified = useAppSelector(
    (state) => state.captchaVerification.isCaptchaVerified
  );

  const triggerEvent = useRef(0);

  // Session timeout modal
  let timer: any;

  function resetTimer() {
    if (timer) clearTimeout(timer);
  }

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();

      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      dispatch(
        setModal({
          modalType: "SESSION_TIMEOUT_MODAL",
          modalProps: {
            open: true,
            navigate: navigate,
          },
        })
      );
    }, 15 * 60 * 1000);
  };
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
        sessionStorage.setItem("user_ipv4_address", data?.ip);
      } catch (error) {
        setIpAddress("0");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useLayoutEffect(() => {
    retriveCredsFromSessionCookie();
    fetchRemoteLogUserDetails();
  }, []);

  const logIPBasedUsageData = (usageActionID: any, profileData?: any) => {
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const user_ipv4_address = ipAddress ? ipAddress : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      null,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const remoteLogSearchUsageData = (usageActionID: any, data?: any) => {
    const userMasterId = data?.user_id ? data?.user_id : null;
    const informaticscustomerId = data?.informaticscustomer_id
      ? data?.informaticscustomer_id
      : null;
    const sessionKey = data?.session_key ? data?.session_key : null;
    const ip_4_Address = ipAddress ? ipAddress : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      null,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      ip_4_Address,
      null,
      sessionKey,
      1,
      null
    );
  };

  function retriveCredsFromSessionCookie() {
    // Check the values in the session cookie
    if (
      !sessionStorage.getItem("informaticscustomer_id") &&
      getSessionCookieValue("informaticscustomer_id")
    ) {
      let informaticscustomer_id =
        getSessionCookieValue("informaticscustomer_id") ?? "";
      let session_key = getSessionCookieValue("session_key") ?? "";
      let csrf_token = getSessionCookieValue("csrf_token") ?? "";
      let user_id = getSessionCookieValue("user_id") ?? "";

      // Set the values in the session storage because in app place we have used sessin storage for validation
      sessionStorage.setItem("informaticscustomer_id", informaticscustomer_id);
      sessionStorage.setItem("session_key", session_key);
      sessionStorage.setItem("csrf_token", csrf_token);
      sessionStorage.setItem("user_id", user_id);
      window.location.reload();
    }
  }

  async function fetchRemoteLogUserDetails() {
    if (customerDetails?.informaticscustomer_id) return;
    let customerid = sessionStorage.getItem("informaticscustomer_id");
    if (customerid) return;

    const response = await getRemoteLogUserDetails();
    if (Object.keys(response).length) {
      if (response?.subscription_status === 0) {
        notify("warning", "Subscription is Expired");
        return;
      } else {
        sessionStorage.setItem(
          "informaticscustomer_id",
          response?.informaticscustomer_id?.toString()
        );
        sessionStorage.setItem("isProfileUser", "false");

        sessionStorage.setItem("user_id", response?.user_id?.toString());
        sessionStorage.setItem("session_key", response?.session_key);

        remoteLogSearchUsageData(121, response);

        setSessionCookie(
          "informaticscustomer_id",
          response?.informaticscustomer_id?.toString()
        );

        dispatch(
          loginSuccess({
            informaticscustomer_id: response?.informaticscustomer_id.toString(),
            user_id: response?.user_id.toString(),
            session_key: response?.session_key.toString(),
          })
        );
        dispatch(updateCaptchaStatus(true));
        window.location.reload();
        dispatch(updateCaptchaStatus(true));
      }
    }
  }

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    function openCaptchaModal() {
      dispatch(
        setModal({
          modalType: "CAPTCHA",
          modalProps: {
            open: true,
          },
        })
      );
    }
    if (!isCaptchaVerified) {
      // if (location.pathname === "/search") return;
      if (location.pathname === "/") return;
      if (location.pathname === "/initialSearch") return;
      openCaptchaModal();
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/search" ||
      location.pathname === "/basicSearchScreen" ||
      location.pathname === "/search/" ||
      location.pathname === "/basicSearchScreen/" ||
      location.pathname === "/" ||
      location.pathname === "/abstractFullScreen" ||
      location.pathname.includes("/aboutus") ||
      location.pathname === "/initialSearch"
    )
      return;
    if (!authState?.informaticscustomer_id) {
      setTimeout(() => {
        navigate("/login");
        notify("info", "Please login to access other features");
      }, 3000);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (customerId || customerId === 0) {
      if (
        customerDetails &&
        Object.keys(customerDetails).includes("informaticscustomer_id")
      ) {
        return;
      }
      getDetailsByIp();
      getCustomerDetails();
    }
  }, [selector.informaticscustomer_id, ipAddress]);

  useEffect(() => {
    if (triggerEvent.current) {
      updateSubjectIds();
      updateDocumentType();
    }
  }, [triggerEvent.current]);

  async function getCustomerDetails() {
    dispatch(updateLoadingState(true));
    let data = await customerDetailsById(customerId);
    if (data?.consortia_filter) {
      sessionStorage.setItem("consortia_filter", data?.consortia_filter);
    }

    if (data?.my_library_filter) {
      sessionStorage.setItem("my_library_filter", data?.my_library_filter);
    }
    dispatch(updateCustomerDetails(data));
    dispatch(updateLoadingState(false));
    triggerEvent.current = triggerEvent.current + 1;
  }

  async function getDetailsByIp() {
    if (!ipAddress) return;
    if (
      selector?.informaticscustomer_id &&
      selector?.informaticscustomer_id !== "undefined"
    )
      return;

    const response = await getCustomerDetailsByIp(ipAddress);
    let data = response[0];

    if (Array.isArray(response) && response?.length > 1) {
      notify("success", "IP Login Successful");
      dispatch(
        setModal({
          modalType: "USER_SELECT",
          modalProps: {
            open: true,
            userArray: response,
          },
        })
      );
    } else if (Array.isArray(response) && response?.length === 1) {
      dispatch(clearCustomerDetails());
      dispatch(reset());
      sessionStorage.clear();

      if (data?.subscription_status === 0) {
        notify("warning", "Subscription is Expired");
        let data = await customerDetailsById(0);
        dispatch(updateCustomerDetails(data));
        return;
      } else {
        sessionStorage.setItem(
          "informaticscustomer_id",
          data?.informaticscustomer_id?.toString()
        );
        sessionStorage.setItem("user_id", data?.user_id);
        sessionStorage.setItem("isProfileUser", "false");
        sessionStorage.setItem("isIPLogin", "true");
        sessionStorage.setItem("isFirstIPLogin", "true");
        sessionStorage.setItem("session_key", data?.session_key);

        dispatch(
          loginSuccess({
            informaticscustomer_id: data?.informaticscustomer_id,
            user_id: data?.user_id,
            session_key: data?.session_key,
          })
        );
        logIPBasedUsageData(6, data);
        window.location.reload();
      }
    }
  }
  function updateSubjectIds() {
    if (subjectType?.length) return;

    let subIds =
      Array.isArray(customerDetails?.subject) &&
      customerDetails?.subject?.map((obj) => obj?.datsubjectmasterlevel2_id);

    let l1SubIds: any =
      Array.isArray(customerDetails?.subject) &&
      customerDetails?.subject?.map((obj) => obj?.datsubjectmasterlevel1_id);
    dispatch(updateSubjectType(subIds));
    dispatch(updateSubjectL1Type(l1SubIds));
  }

  function updateDocumentType() {
    if (defaultDocType.length) return;
    if (!Array.isArray(customerDetails?.datatypes)) return;

    let docIds = customerDetails?.datatypes?.map(
      (obj) => obj?.dat_resourcetype_id
    );
    dispatch(updateDefaultDataType(docIds));
    dispatch(updateDataType(docIds));
  }

  let { children, hideFooter } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <MainNav />
      <Box
        sx={{
          flex: 1,
          minHeight: "calc(100vh - 140px)",
        }}
      >
        {children}
      </Box>
      {!hideFooter && (
        <Box sx={{ position: "sticky", bottom: 0, zIndex: 1000 }}>
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default AuthLayout;
