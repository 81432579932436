import http from "../httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import {
  GetMyPreferenceData,
  GetMyPreferenceRoot,
} from "../../types/GetMySubjects.types";
import {
  GetAllSubjects,
  GetAllSubjectsRoot,
} from "../../types/GetAllSubjects.types";

export const useGetAllSubjects = () =>
  useQuery(
    ["useGetAllSubjects"],
    () => http.get("/api/get_subject_level_details/get_subject_level_details"),
    {
      select: (res: AxiosResponse<GetAllSubjectsRoot>): GetAllSubjects[] => {
        return res.data.Subjects;
      },
    }
  );

export const useGetMyPreference = (userId: string) => {
  const params: any = {};
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetMyPreference", userId],
    () =>
      http.get(
        "/api/get_all_saved_personalisation_details/get_all_saved_personalisation_details",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetMyPreferenceRoot>
      ): GetMyPreferenceData => {
        return res.data.data[0][0];
      },
    }
  );
};

//Main Search Screen => Customized Hook
export const useGetMySearchPreference = (
  userId: string,
  isPreference: boolean
) => {
  const params: any = {};
  if (userId) params["profileuserid"] = userId;

  return useQuery(
    ["GetMyPreference", userId],
    () =>
      http.get(
        "/api/get_all_saved_personalisation_details/get_all_saved_personalisation_details",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetMyPreferenceRoot>
      ): GetMyPreferenceData => {
        return res.data.data[0][0];
      },
      enabled: isPreference, //Runs Only When Preference Switch is ON
    }
  );
};

export const useMutateModifyMySubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/Update_personalisation_subjects/Update_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetMyPreference"]);
        console.log("Success, Updated Subject Preference");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateModifyMySources = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/Update_personalisation_resourcetype/Update_personalisation_resourcetype",
        obj?.formData
      ),
    {
      onSuccess: () => {
        console.log("Success, Updated Source Preference");
      },
      onError: (err: any) => {
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["GetMyPreference"]);
      }
    }
  );
};

export const useMutateDeleteMySources = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_personalisation_resourcetype/remove_personalisation_resourcetype",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetMyPreference"]);
        console.log("Success, Deleted Source Preference");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateDeleteMySubjects = () => {
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/remove_personalisation_subjects/remove_personalisation_subjects",
        obj?.formData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["GetMyPreference"]);
        console.log("Success, Deleted Source Preference");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};
