import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";

import AbstractView from "./abstractIndex";
import { useAppSelector } from "../../store/hooks";
import validator from "validator";
import { redirectLink } from "../../utils/helper";
import { MathJax } from "better-react-mathjax";
import { getListOfJournal } from "../../api/browseJournal";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { useNavigate } from "react-router-dom";
import {
  getCardData,
  updateBrowserPage,
} from "../../store/slice/browseJournal";
import { useDispatch } from "react-redux";
import { bowserJournalSearchCard } from "../../api/browseJournalSearch";
import { updateVolumeOfTOC } from "../../store/slice/browserFilterSliceDetails";
interface P {
  handleDawer: (data: any) => void;
  setViewDetails: Dispatch<SetStateAction<boolean>>;
  state: boolean;
  details: any;
  journalHistory: any;
  setDetails: any;
}

const JournalViewDetails: React.FC<P> = ({
  handleDawer,
  setViewDetails,
  state,
  details,
  journalHistory,
  setDetails,
}) => {
  // const [top, setTop] = useState(true);
  const [abstractTop, setAbstractTop] = useState(false);

  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePopUp = () => {
    setAbstractTop(!abstractTop);
  };

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    // setFormats(newFormats);
  };

  const getDataList = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails?.docs
  );

  const handleClick = async (name: string) => {
    let resourceList = "";
    let key = "";
    let strLen = name.split(" ");
    dispatch(updateVolumeOfTOC(""));
    name.split(" ")?.map((ele: any, indexs: number) => {
      console.log("strLen.length", strLen.length, indexs);
      strLen.length - 1 !== indexs
        ? (key += ele?.replace(/^\"+|\“+|\"+|\”$/g, "") + "\\ ")
        : (key += ele);
    });
    resourceList +=
      key?.indexOf(":\\") > -1
        ? `fq__resource_name_tk=${key.split(":\\").join("\\:\\")}* AND `
        : `fq__resource_name_tk=*${key}* AND `;

    resourceList = `&${resourceList.slice(0, -5)}`;

    let apiJournalData: any = {
      page: 1,
      rows: 15,
      profileuserid: userId,
      sessionid: session_key,
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",

      fq__active_flag: true,
    };

    const response = await getListOfJournal(
      "resourcecore?" + objectToQueryString(apiJournalData) + resourceList
    );
    setDetails(response?.data.data.docs[0]);

    // dispatch(updateBrowserPage(1));
    // let obj = { resourcemaster_id: response?.data.data.docs[0].resourcemaster_id };
    // let response2 = await bowserJournalSearchCard(obj);
    // dispatch(getCardData({docs:response2}));
    // console.log("test111", response2);
    // navigate("/BrowseJournalTableSearch/BrowseJournalSearch", {
    //   state: {
    //     item: response?.data.data.docs[0],
    //     // alerts: alerts,
    //     // lock: lock,
    //     from: "BrowseJournalTableSearch",
    //   },
    // });
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleDawer}
        anchor={"right"}
        open={state}
        // open={true}
        PaperProps={{
          sx: {
            width: "65%",
            bgcolor: "#F9F8F6",
            height: "100%",
            paddingBottom: "5%",
          },
        }}
        //   onClose={toggleDrawer(anchor, false)}
      >
        <Box bgcolor={"#FFF"} width={"100%"}>
          <Box
            sx={[
              webStyle.flexBox,
              {
                alignSelf: "center",
                padding: "1%",
                ml: 3,
                margin: "1%",
              },
            ]}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              Journal Details
            </Typography>
            <IconButton aria-label="delete" size="small" onClick={handleDawer}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>

        {/* =========POP UP ================ */}
        <Box sx={webStyle.subContainer}>
          <Typography variant="body2" sx={webStyle.titleText}>
            Journal Name:
          </Typography>
          <Typography variant="body2" style={webStyle.informationText}>
            <MathJax>
              <div
                dangerouslySetInnerHTML={{
                  __html: details?.resource_name_tk,
                }}
              />
            </MathJax>
          </Typography>
        </Box>
        {journalHistory?.previous_resource_name && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Previous Name:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              <MathJax>
                <div
                  dangerouslySetInnerHTML={{
                    __html: journalHistory?.previous_resource_name,
                  }}
                />
              </MathJax>
            </Typography>
          </Box>
        )}

        <Box sx={webStyle.subContainer}>
          <Typography variant="body2" sx={webStyle.titleText}>
            Publisher:
          </Typography>
          <Typography variant="body2" style={webStyle.informationText}>
            <MathJax>
              <div
                dangerouslySetInnerHTML={{
                  __html: details?.publisher_name_tk
                    ? details?.publisher_name_tk
                    : details?.publisher_name[0],
                }}
              />
            </MathJax>
          </Typography>
        </Box>
        {details?.co_publisher ||
          (journalHistory?.co_publisher && (
            <Box sx={webStyle.subContainer}>
              <Typography variant="body2" sx={webStyle.titleText}>
                Co-Publisher:
              </Typography>
              <Typography variant="body2" style={webStyle.informationText}>
                {`${
                  journalHistory?.co_publisher
                    ? journalHistory?.co_publisher
                    : details?.co_publisher
                }`}
                {/* <Link to={}></Link> */}
              </Typography>
            </Box>
          ))}
        {details?.pissn && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              P-ISSN:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {details?.pissn}
            </Typography>
          </Box>
        )}
        {details?.eissn && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              E-ISSN:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {details?.eissn}
            </Typography>
          </Box>
        )}
        {details?.aboutresource || journalHistory?.about_journal ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              About Journal:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {journalHistory?.about_journal
                ? journalHistory?.about_journal
                : details?.aboutresource}
            </Typography>
          </Box>
        ) : null}

        {details?.content_start || details?.content_end ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Content coverage:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {details?.content_start} -{" "}
              {journalHistory?.resource_status
                ? journalHistory?.resource_status == "Active" ||
                  journalHistory?.resource_status == "Behind Schedule"
                  ? "Current"
                  : details?.content_end
                : details?.content_end}
            </Typography>
          </Box>
        ) : null}
        {journalHistory?.resource_status ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Journal Status:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {journalHistory?.resource_status}
            </Typography>
          </Box>
        ) : null}
        {details?.author_url && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Author URL:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {validator?.isURL(details?.author_url) ? (
                <Link
                  href=""
                  onClick={(event) => {
                    redirectLink(details?.author_url,event);
                  }}
                  underline="hover"
                >
                  {details?.author_url}
                </Link>
              ) : (
                details?.author_url
              )}
            </Typography>
          </Box>
        )}

        {journalHistory?.frequency ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Frequency:
            </Typography>
            <Typography variant="body2" style={webStyle.informationText}>
              {journalHistory?.frequency}
            </Typography>
          </Box>
        ) : null}

        {journalHistory?.resource_history && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Journal History:
            </Typography>
            <Box sx={{ width: "70%" }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="medium"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                      <TableCell>Journal Name</TableCell>
                      <TableCell>Year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {journalHistory?.resource_history?.map((item: any) => {
                      console.log("itemitem", item);
                      return (
                        <>
                          <TableRow
                            // key={row}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{ cursor: "pointer" }}
                              sx={{
                                alignSelf: "center",
                                color: Colors.primary,
                                marginLeft: 1,
                                fontFamily: "Helvetica Neue",
                                fontStyle: "normal",
                                fontSize: 14,
                              }}
                              onClick={() => {
                                setViewDetails(false);
                                handleClick(item.resource_name);
                              }}
                              component="th"
                              scope="row"
                            >
                              {item.resource_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.year_from && item.year_from}{" "}
                              {item.year_to && item.year_to !== 0
                                ? ` - ${item.year_to}`
                                : "- Current"}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default JournalViewDetails;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",

    fontStyle: "normal",
    color: "#343332",
    fontSize: "14px",
  },
  informationText: {
    width: "70%",
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#343332",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
