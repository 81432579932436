import http from "../httpService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  GetMySavedSearchData,
  GetMySavedSearchRoot,
} from "../../types/GetSavedSearch.types";

export const useGetMySavedSearch = (userId: string, enabled?: boolean) => {
  const params: any = {};
  if (userId) params["profileuser_id"] = userId;

  return useQuery(
    ["GetMyFolders", userId],
    () =>
      http.get(
        "/api/get_all_saved_search_for_profile/get_all_saved_search_for_profile",
        {
          params,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetMySavedSearchRoot>
      ): GetMySavedSearchData[] => {
        return res.data.data[0][0];
      },
      enabled: enabled
    }
  );
};

// export const useMutateCreateMyFolder = () => {
//   return useMutation(
//     (obj: { formData: FormData; csrf: any }) =>
//       http.post("/api/CreateProfileFolder/CreateProfileFolder", obj?.formData),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(["GetMyFolders"]);
//         console.log("Success, Folder created");
//       },
//       onError: (err: any) => {
//         console.log(err);
//       },
//     }
//   );
// };



