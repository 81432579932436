import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { composeEmailapi } from "../../api/composeEmailapi";
import { useDispatch, useSelector } from "react-redux";
import { LoginCred } from "../../types/interface";
import { useForm, SubmitHandler } from "react-hook-form";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useAppSelector } from "../../store/hooks";
import { notify } from "../../utils/Notify";
import { usagelogApi } from "../../api/usageReportApi";

interface P {
  handleEmailModal: (e: any) => void;
  state: boolean;
  id?: any;
  articleData?: any;
  resultFrom?: any;
  from?: string;
}

type Inputs = {
  yourName: string;
  EmailId: string;
  message: string;
  resultFrom: string;
};

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const ComposeEmail: React.FC<P> = ({
  handleEmailModal,
  state,
  id,
  articleData,
  resultFrom,
  from,
}) => {
  let loginId: any = useSelector((state: LoginCred) => state?.login?.user_id);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state) => state.browseFilterSlice
  );

  const [loading, setLoading] = useState(false);

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logEmailUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    let response = await composeEmailapi(
      data.message,
      data.EmailId,
      data.yourName,
      id,
      searchTerm,
      loginId
    );
    logEmailUsageData(98, articleData);

    if (response?.data.message === "Email sent successfully") {
      setValue("yourName", "");
      setValue("EmailId", "");
      setValue("message", "");
      handleEmailModal(data);
      notify("success", response?.data.message);
    }
    setLoading(false);
  };

  const handleClose = (e: any) => {
    handleEmailModal(e);
    setValue("yourName", "");
    setValue("EmailId", "");
    setValue("message", "");
  };

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <>
      <Dialog open={state} maxWidth="sm" fullWidth>
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" alignSelf={"center"}>
            Compose Email
          </Typography>

          <IconButton onClick={(e) => handleClose(e)}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <InputLabel>Your Name</InputLabel>
            <TextField
              data-test-id={"txtInputfirstName"}
              {...register("yourName", { required: true })}
              placeholder="Your Name"
              fullWidth
              variant="outlined"
              margin="dense"
            />
            {errors.yourName && (
              <span style={{ fontSize: 12, color: "red" }}>
                This field is required
              </span>
            )}

            <InputLabel>Email Id: </InputLabel>
            <TextField
              data-test-id={"txtInputlastName"}
              {...register("EmailId", {
                required: "This field is requried",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please enter proper Email ID",
                },
              })}
              placeholder="Multiple email IDs to be separated by comma ,"
              fullWidth
              variant="outlined"
              margin="dense"
              required
            />

            {errors.EmailId && (
              <span style={{ fontSize: 12, color: "red" }}>
                {/* This field is required */}
                {errors.EmailId?.message}
              </span>
            )}

            <InputLabel>Message</InputLabel>
            <TextField
              multiline={true}
              rows={5}
              data-test-id={"txtInputProductId"}
              {...register("message", { required: true })}
              placeholder="Type your message"
              fullWidth
              variant="outlined"
              margin="dense"
            />
            {errors.message && (
              <span style={{ fontSize: 12, color: "red" }}>
                This field is required
              </span>
            )}

            <DialogActions style={{ justifyContent: "flex-start" }}>
              <Button type="submit" variant="contained">
                {/* Compose Email  */}
                Send Email{" "}
                {loading && from === "vmrPage" && (
                  <CircularProgress size="1.5rem" />
                )}
              </Button>
              <Button
                // color="primary"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default ComposeEmail;
