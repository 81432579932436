import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
  tabSwitch: number;
  browserResult: string[];
  alertFav: string[];
  keyWord: string[];
  containStartingWith: string;
  setAlphabet: string;
  searchKeyword: string[];
  getCardDataDetails: any;
  markedArticleId: string[];
  markedResult: any;
  page: number;
  rows: number;
  loading: boolean;
  knownAlphasearch: any;
  journalLockDisplay: any;
  lastAppliedQuery: any;
  tocSearchCard: any;
  bookResult: string[];
  isBrowseJournalSearch: boolean;
}

const initialState: InitialstateType = {
  tabSwitch: 0,
  browserResult: [],
  alertFav: [],
  getCardDataDetails: [],
  keyWord: [],
  containStartingWith: "Starting With",
  setAlphabet: "",
  searchKeyword: [],
  markedArticleId: [],
  markedResult: [],
  page: 1,
  rows: 15,
  loading: false,
  knownAlphasearch: [],
  journalLockDisplay: [],
  lastAppliedQuery: "",
  tocSearchCard: [],
  bookResult:[],
  isBrowseJournalSearch: false
};

export const browseJournalSearchSlice = createSlice({
  name: "browseJournalSearch",
  initialState,
  reducers: {
    tabSwitchBrowserJournal(state, { payload }) {
      state.tabSwitch = payload;
    },
    browseJournalSearchResult(state, { payload }) {
      state.browserResult = payload;
    },
    alertsAndFav(state, { payload }) {
      state.alertFav = payload || [];
    },
    getCardData(state, { payload }) {
      state.getCardDataDetails = payload;
    },
    getSelectedKeyWord(state, { payload }) {
      state.keyWord = [...state.keyWord, payload];
    },
    getContainOrStartingWIth(state, { payload }) {
      state.containStartingWith = payload;
    },
    setKeyword(state, { payload }) {
      state.setAlphabet = payload;
    },
    setSearchKeyword(state, { payload }) {
      state.searchKeyword = [payload];
    },
    updateMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    updateMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
    updateBrowserRow(state, { payload }) {
      state.rows = payload;
    },

    updateBrowserPage(state, { payload }) {
      state.page = payload;
    },
    updateLoadingState(state, { payload }) {
      state.loading = payload;
    },
    updateknownAlphasearch(state, { payload }) {
      state.knownAlphasearch = payload;
    },
    updateJournalLockDisplay(state, { payload }) {
      state.journalLockDisplay = payload;
    },
    updatelastAppliedQuery(state, { payload }) {
      state.lastAppliedQuery = payload;
    },
    updateTocPageSearch(state, { payload }) {
      state.tocSearchCard = payload;
    },
    bookSearchResult(state, { payload }) {
      state.bookResult = payload;
    },

    updateBrowseJournalSearch(state, {payload}) {
      state.isBrowseJournalSearch = payload
    }
    // lastAppliedQuery
  },
});

export const {
  browseJournalSearchResult,
  alertsAndFav,
  getCardData,
  getSelectedKeyWord,
  getContainOrStartingWIth,
  setKeyword,
  setSearchKeyword,
  updateMarkedArticleId,
  updateMarkedResult,
  tabSwitchBrowserJournal,
  updateBrowserRow,
  updateBrowserPage,
  updateLoadingState,
  updateknownAlphasearch,
  updateJournalLockDisplay,
  updatelastAppliedQuery,
  updateTocPageSearch,
  bookSearchResult,
  updateBrowseJournalSearch
} = browseJournalSearchSlice.actions;

export default browseJournalSearchSlice.reducer;
