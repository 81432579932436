import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Backdrop } from "@mui/material";

type Proptype = {
  isLoading: boolean;
};

export default function Spinner(props: Proptype) {
  const { isLoading } = props;
  return (
    <Backdrop
      open={isLoading}
      sx={{ backdropFilter: "brightness(80%)", background: "transparent" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ width: "70px", height: "70px" }} />
      </Box>
    </Backdrop>
  );
}

interface LoaderProps {
  height: string;
  width: string;
}

export const Loader = ({ height, width }: LoaderProps) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: height,
      width: width,
    }}
  >
    <CircularProgress color="primary" thickness={5} />
  </Box>
);
