import http from "../httpService";

export async function getRemoteLogUserDetails() {
    try {
        const endpoint = "/api/get_remote_log_client_details/get_remote_log_client_details";
        const response = await http.get(endpoint);
        return response.data?.data[0][0][0];
    } catch {
        return null
    }
}