import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import { alertStyles as styles } from "../../../screens/MyLibrary/MyAlerts/Alerts.style";
import InputBase from "@mui/material/InputBase";
import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Colors } from "../../../utils/constants";
import {
  useMutateAddPreprintAlerts,
  useMutateUpdatePreprintAlerts,
} from "../../../api/MyLibrary/Preprints.api";
import { GetPreprintData } from "../../../types/GetAlerts.types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { notify } from "../../../utils/Notify";

interface Props {
  show: boolean;
  alertData: GetPreprintData;
}

const frequency = [
  {
    i: 1,
    freq: "Daily",
  },
  {
    i: 2,
    freq: "Weekly",
  },
  {
    i: 3,
    freq: "Bi-Weekly",
  },
  {
    i: 4,
    freq: "Monthly",
  },
  {
    i: 5,
    freq: "Quarterly",
  },
];

const AddPrePrintAlertModal = ({ show, alertData }: Props) => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [isEmail, setIsEmail] = useState(true);
  const [isRSS, setRSS] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedFreq, setSelectedFreq] = useState<string>(
    frequency[0].i.toString()
  );

  useEffect(() => {
    if (alertData) {
      if (alertData.datalerttypeid === 3) {
        console.log("OOH RAH");

        setIsEmail(true);
        setRSS(true);
      } else if (alertData.datalerttypeid === 2) {
        setRSS(true);
        setIsEmail(false);
      } else if (alertData.datalerttypeid === 1) {
        setRSS(false);
        setIsEmail(true);
      } else if (alertData.datalerttypeid === 0) {
        setRSS(false);
        setIsEmail(false);
      }

      setKeyword(alertData.alert_topic);
      setSelectedFreq(frequency[alertData.alert_frequency - 1].i.toString());
    }
  }, [alertData]);

  const addPreprint = useMutateAddPreprintAlerts();
  const updatePreprint = useMutateUpdatePreprintAlerts();

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFreq(event.target.value);
  };
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmail(event.target.checked);
  };
  const handleRSS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRSS(event.target.checked);
  };

  const handleKeyword = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => setKeyword(currentTarget.value);

  const handleAdd = () => {
    let alertType = 0; //No Alert
    if (isEmail && isRSS) {
      alertType = 3;
    } else if (isEmail && !isRSS) {
      alertType = 1;
    } else if (isRSS && !isEmail) {
      alertType = 2;
    }
    if (alertType == 0) {
      notify("info", "Select any one alert type to set alert");
      return;
    }
    let data = {
      data: [
        {
          search_term: keyword,
          search_name: keyword,
          usermaster_id: selector.user_id,
          informaticscustomer_id: selector.informaticscustomer_id,
          mail_frequency: selectedFreq,
          alert_type_id: alertType,
        },
      ],
    };
    const formData = new FormData();
    formData.append("alertdetailsjson", JSON.stringify(data));

    addPreprint.mutate({ formData: formData });
    dispatch(hideModal());
  };

  const handleUpdate = () => {
    let alertType = 0; //No Alert
    if (isEmail && isRSS) {
      alertType = 3;
    } else if (isEmail && !isRSS) {
      alertType = 1;
    } else if (isRSS && !isEmail) {
      alertType = 2;
    }
    if (alertType == 0) {
      notify("info", "Select any one alert type to set alert");
      return;
    }
    const data = {
      data: [
        {
          alert_id: alertData.alert_id,
          search_name: keyword,
          mail_frequency: selectedFreq,
          alert_type_id: alertType,
        },
      ],
    };

    const formData = new FormData();
    formData.append("alertjson", JSON.stringify(data));

    updatePreprint.mutate({ formData: formData });
    dispatch(hideModal());
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AddPrePrintAlertModal-Title"
      aria-describedby="AddPrePrintAlertModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          // height: "30vh",
          width: "42vw",
          alignItems: "flex-start",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            Add Preprint Alert
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%", mb: 1 }}>
          <Text>Keyword</Text>
          <InputBase
            sx={styles.keyword}
            value={keyword}
            onChange={handleKeyword}
            placeholder="Enter Keyword"
          />
        </Box>

        <Box sx={{ width: "100%", mb: 1 }}>
          <Text>Alert Frequency</Text>
          <Select
            value={selectedFreq}
            onChange={handleChange}
            displayEmpty
            sx={styles.frequency}
            inputProps={{ "aria-label": "Without label" }}
          >
            {frequency?.map((item) => (
              <MenuItem value={item.i} key={item.i}>
                {item.freq}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <FormGroup sx={{ my: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={isEmail} onChange={handleEmail} />}
            label="Email Alerts"
          />
          <FormControlLabel
            control={<Checkbox checked={isRSS} onChange={handleRSS} />}
            label="RSS Alerts"
          />
        </FormGroup>

        <Box sx={{ width: "100%", my: 1 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={() => {
              alertData ? handleUpdate() : handleAdd();
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPrePrintAlertModal;
