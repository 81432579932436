import { createSlice } from "@reduxjs/toolkit";
import { LinkResolverType } from "../../api/linkResolverApi";
import { SaverArticlesType } from "../../types/interface";
import { GetAlertSettingsData } from "../../types/GetAlertSettings.types";

export interface InitialstateType {
  searchResult: any;
  searchTerm: string[];
  page: number;
  rows: number;
  dataType: number[];
  subjectType: number[];
  subjectL1Type: number[]
  subjectName: string[];
  markedResult: any;
  markedArticleId: string[];
  fullText: boolean;
  all: boolean;
  dSpace: boolean;
  newSearchStatus: boolean;
  authorName: string[];
  linkToDisplay?: LinkResolverType[] | null;
  savedArticles?: SaverArticlesType[];
  markedArticleCount: string[];
  isSettled: boolean;
  defaultDataType: number[];
  alertSettingData: GetAlertSettingsData[];
  isNavigatingBack?: boolean;
  opacResult: any;
 

  // Advance search
  advanceSearchQuery: string;

  logSearch: boolean;

  search_type_id: "1" | "2" | "3" | null | undefined;
}

const initialState: InitialstateType = {
  searchResult: { highlighting: {} },
  searchTerm: [],
  page: 1,
  rows: 15,
  dataType: [],
  subjectType: [],
  subjectL1Type: [],
  subjectName: ["All"],
  markedResult: [],
  markedArticleId: [],
  fullText: true,
  all:false,
  dSpace: false,
  newSearchStatus: false,
  authorName: [],
  linkToDisplay: null,
  savedArticles: [],
  markedArticleCount: [],
  isSettled: false,
  defaultDataType: [],
  advanceSearchQuery: "",
  alertSettingData: [],
  logSearch: false,
  search_type_id: null,
  isNavigatingBack:false,
  opacResult: { highlighting: {} },
  
};

export const searchSlice = createSlice({
  name: "searchResult",
  initialState,
  reducers: {
    updateSearchResults(state, { payload }) {
      state.searchResult = payload || { highlighting: {} };
    },
    updateSearchTerm(state, { payload }) {
      if (state.searchTerm.includes(payload)) {
        state.searchTerm = state.searchTerm?.filter((data) => data !== payload);
      }

      if (state.searchTerm.length) {
        state.searchTerm = [...state.searchTerm, payload];
      } else {
        state.searchTerm = [payload];
      }
    },
    clearSearchTerm(state) {
      state.searchTerm = [];
      state.advanceSearchQuery = "";
    },
    sliceSearchTerm(state) {
      state.searchTerm.splice(1);
    },
    removeSearchTerm(state, { payload }) {
      state.searchTerm = state.searchTerm.filter((data) => data !== payload);
    },
    updatePage(state, { payload }) {
      state.page = payload;
    },
    updateRow(state, { payload }) {
      state.rows = payload;
    },
    updateDataType(state, { payload }) {
      state.dataType = payload;
    },
    updateDefaultDataType(state, { payload }) {
      state.defaultDataType = payload;
    },
    updateSubjectType(state, { payload }) {
      state.subjectType = payload;
    },
    updateSubjectL1Type(state, { payload }) {
      state.subjectL1Type = payload;
    },
    updateSubjectName(state, { payload }) {
      if (Array.isArray(payload) && !payload.length) {
        state.subjectName = ["All"];
      } else {
        state.subjectName = payload;
      }
    },
    updateMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
    updateMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    clearMarkedResult(state) {
      state.markedArticleId = [];
      state.markedResult = [];
      state.authorName = [];
    },
    updateFullText(state, { payload }) {
      state.fullText = payload;
    },
    updateAll(state, { payload }) {
      state.all = payload;
    },
    updateDSpace(state, { payload }) {
      state.dSpace = payload;
    },
    updateIsNavigatingBack(state, { payload }) {
      state.isNavigatingBack = payload;
    },
    
    clearDataTypeFilters(state) {
      state.dataType = [];
      state.subjectType = [];
      state.subjectName = [];
      state.authorName = [];
    },
    updateNewSearchStatus(state, { payload }) {
      state.newSearchStatus = payload;
    },
    updateAuthorName(state, { payload }) {
      state.authorName = payload;
    },

    updateLinkToDisplay(state, { payload }) {
      state.linkToDisplay = payload;
    },

    updateSavedArticles(state, { payload }) {
      state.savedArticles = payload;
    },
    updateMarkedArticleCount(state, { payload }) {
      const filteredData: string[] = Array.from(new Set(payload));
      state.markedArticleCount = filteredData;
    },
    updateIsSettled(state, { payload }) {
      state.isSettled = payload;
    },

    updateAdvSearchQuery(state, { payload }) {
      state.advanceSearchQuery = payload;
    },

    updateAlertSettingData(state, { payload }) {
      state.alertSettingData = payload;
    },
    updateLogSearch(state, { payload }) {
      state.logSearch = payload;
    },
    updateSearchType(state, {payload}) {
      state.search_type_id = payload;
    },
    removeSearchTypeId(state, {payload}) {
      state.search_type_id = payload;
    },
    updateOpacResults(state, { payload }) {
      state.opacResult = payload || { highlighting: {} };
    },

    // reset to initial state
    reset: () => initialState,
  },
});

export const {
  updateSearchResults,
  updateSearchTerm,
  clearSearchTerm,
  removeSearchTerm,
  updatePage,
  updateRow,
  updateDataType,
  updateSubjectType,
  updateSubjectName,
  updateMarkedResult,
  updateMarkedArticleId,
  clearMarkedResult,
  updateFullText,
  sliceSearchTerm,
  clearDataTypeFilters,
  updateNewSearchStatus,
  updateAuthorName,
  updateLinkToDisplay,
  updateSavedArticles,
  updateMarkedArticleCount,
  updateIsSettled,
  updateDefaultDataType,
  updateAdvSearchQuery,
  reset,
  updateAlertSettingData,
  updateLogSearch,
  updateSearchType,
  removeSearchTypeId,
  updateAll,
  updateDSpace,
  updateSubjectL1Type,
  updateIsNavigatingBack,
  updateOpacResults
} = searchSlice.actions;
export default searchSlice.reducer;
