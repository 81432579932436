import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Dialog,
  Button,
  Stack,
  DialogActions,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const RSSAlert_Save = ({
  urlLink,
  openViewModal,
  handleCloseViewModal,
}: any) => {
  // console.log("rssalert", urlLink);

  return (
    <>
      <Dialog
        open={openViewModal}
        onClose={handleCloseViewModal}
        maxWidth={false}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign="left"
            fontFamily={"Lora"}
            fontWeight={700}
            fontSize={28}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Alert Saved Successfully
          </Typography>

          <IconButton onClick={handleCloseViewModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ width: 700 }}>
          <Typography fontWeight={500} fontSize={18} mt={2} mb={2}>
            Please add the below url in RSS Reader to receive alerts
          </Typography>
          {/* <Link to={urlLink}>
            
            {urlLink}
          </Link> */}
          <a href={urlLink} target="_blank">
            {" "}
            {urlLink}
          </a>

          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#7852FB",
                },
                textAlign: "left",
                my: 4,
                backgroundColor: "#7852FB",
                color: " #FAF8F5",
                textTransform: "capitalize",
                borderRadius: "8px",
                fontSize: "16px",
                width: "192px",
                height: "51px",
              }}
              onClick={handleCloseViewModal}
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RSSAlert_Save;
