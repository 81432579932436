import axios from "axios";
import api from "./endpoints";
const authorFinderURL = api.baseApi + `/api/indexingGen/solrGeneral/authorcore`;

export async function usageReport(action_id: string) {
  let apiFormData = {
    data: [
      {
        usermaster_id: sessionStorage.getItem("user_id"),
        informaticscustomer_id: sessionStorage.getItem(
          "informaticscustomer_id"
        ),
        consortiamaster_id: null,
        usage_action_id: action_id,
        context_id: "1",
        action_origin_id: null,
        article_id: null,
        resourceissue_id: null,
        publisher_id: null,
        resorucemaster_id: null,
        // ip_v4: "192.168.10.50",
        // ip_v6: null,
        user_session: sessionStorage.getItem("session_key"),
        device_type_id: 1,
        remarks: null,
      },
    ],
  };

  const formData = new FormData();
  formData.append("searchlog", JSON.stringify(apiFormData));
  try {
    const res = await axios.post(
      api.baseApi + "/api/insert_product_usage_log/insert_product_usage_log",
      formData
    );
  } catch (err) {
    return null;
  }
}

export async function usagelogApi(
  usermaster_id: any,
  informaticscustomer_id: any,
  consortiamaster_id: any,
  usage_action_id: any,
  context_id: any,
  action_origin_id: any,
  article_id: any,
  resourceissue_id: any,
  publisher_id: any,
  resorucemaster_id: any,
  ip_v4: any,
  ip_v6: any,
  user_session: any,
  device_type_id: any,
  remarks: any
) {
  const logdetailsjson = {
    data: [
      {
        usermaster_id: usermaster_id,
        informaticscustomer_id: informaticscustomer_id,
        consortiamaster_id: consortiamaster_id,
        usage_action_id: usage_action_id,
        context_id: context_id,
        action_origin_id: action_origin_id,
        article_id: article_id,
        resourceissue_id: resourceissue_id,
        publisher_id: publisher_id,
        resorucemaster_id: resorucemaster_id,
        ip_v4: ip_v4,
        ip_v6: ip_v6,
        user_session: user_session,
        device_type_id: device_type_id,
        remarks: remarks,
      },
    ],
  };
  const formData = new FormData();

  formData.append("searchlog", JSON.stringify(logdetailsjson));
  try {
    const res = await axios.post(
      api.baseApi + "/api/insert_product_usage_log/insert_product_usage_log",
      formData
    );
  } catch (err) {
    return null;
  }
}
