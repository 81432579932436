import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  modalType: string;
  modalProps: JSX.Element | null;
}

const initialState: ModalState = {
  modalType: "",
  modalProps: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setModal: (
      state,
      action: PayloadAction<{ modalType: string; modalProps: any }>
    ) => {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    hideModal: (state) => {
      state.modalType = "";
      state.modalProps = null;
    },
  },
});

export const { setModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
