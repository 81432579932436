import React, { useEffect, useState } from "react";
import Nav from "../Nav/MainNav";
import { Box, Divider, IconButton, Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Colors } from "../../utils/constants";
import { useSelector } from "react-redux";

function Disclaimer({ handleClickOpen, disclaimerDisplay }: any) {
 
  return (
    <div>
      <Dialog
        open={disclaimerDisplay}
        onClose={handleClickOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: Colors.primary }}>
          Disclaimer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This ILL facility is provided in J-Gate to facilitate end-users to
            identify the availability of the article in any library that user
            finds in the search. Informatics takes no responsibility for
            accuracy of the information provided or facilitating article
            delivery by any library to the requesting user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Disclaimer;

const webStyles = {};
