import axios from "axios";

import api from "./endpoints";
let session_key = sessionStorage.getItem("session_key");
let userId = sessionStorage.getItem("user_id");
let informaticscustomer_id = sessionStorage.getItem("informaticscustomer_id");

export async function bowserJournalSearchCard(item: any) {
  try {
    let response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/articlecore2?page=1&customerid=${informaticscustomer_id}&profileuserid=${userId}&datsearchtype_id=1&sessionid=${session_key}&datproductcontrolitem_id=4&datsearch_action_type_id=1&sort=dateofpublication desc&op_mode=and&fq__resourcemaster_id=(${item.resourcemaster_id})`
    );

    return response.data.data.docs;
  } catch (err) {}
}

export async function getbrowserJournalOnSearch(
  item: any,
  valueText: string,
  resourcemaster_id?: string | null
) {
  console.log(item, "item");

  try {
    // let response = await axios.get(
    //   `${api.baseApi}/api/indexing/solr/articlecore2?page=1&json_facet={"yearfrom":{"type":"terms","field":"yearfrom","limit":100,"sort":"index desc"},"monthfrom":{"type":"terms","field":"monthfrom","limit":100},"volumefrom":{"type":"terms","field":"volumefrom","limit":100},"issuenumberfrom":{"type":"terms","field":"issuenumberfrom","limit":100},"resource_status":{"type":"terms","field":"resource_status","limit":100},"primary_publisher_country":{"type":"terms","field":"primary_publisher_country","limit":100},"publisher_name":{"type":"terms","field":"publisher_name","limit":100},"journal_name":{"type":"terms","field":"journal_name","limit":100},"filter_metrix":{"type":"terms","field":"filter_metrix","limit":100}}&fq__subjects_id_l2=( 617 OR 35 OR 34 OR 620 OR 30 OR 21 OR 41 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 39 OR 31 OR 40 OR 36 OR 38 OR 28 OR 24 OR 42 OR 43 OR 25 OR 32 OR 29 )&fq__resource_type=( 1 OR 2 OR 3 OR 4 OR 8 )&customerid=${informaticscustomer_id}&consortiamaster_id=1874&profileuserid=${userId}&datsearchtype_id=1&datproductcontrolitem_id=4&datsearch_action_type_id=1&sort=dateofpublication desc&op_mode=and&fq__resourceissue_id=(${item.resourcemaster_id})&sessionid=${session_key}&titleKeywordsAbs=("${valueText}")`
    // );

    let response = await axios.get(
      `${api.baseApi}/api/indexing/solr/articlecore2?page=1&json_facet={"yearfrom":{"type":"terms","field":"yearfrom","limit":100,"sort":"index desc"},"monthfrom":{"type":"terms","field":"monthfrom","limit":100},"volumefrom":{"type":"terms","field":"volumefrom","limit":100},"issuenumberfrom":{"type":"terms","field":"issuenumberfrom","limit":100},"resource_status":{"type":"terms","field":"resource_status","limit":100},"primary_publisher_country":{"type":"terms","field":"primary_publisher_country","limit":100},"publisher_name":{"type":"terms","field":"publisher_name","limit":100},"journal_name":{"type":"terms","field":"journal_name","limit":100},"filter_metrix":{"type":"terms","field":"filter_metrix","limit":100}}&fq__subjects_id_l2=( 617 OR 35 OR 34 OR 620 OR 30 OR 21 OR 41 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 39 OR 31 OR 40 OR 36 OR 38 OR 28 OR 24 OR 42 OR 43 OR 25 OR 32 OR 29 )&fq__resource_type=( 1 OR 2 OR 3 OR 4 OR 8 )&customerid=${informaticscustomer_id}&consortiamaster_id=1874&profileuserid=${userId}&datsearchtype_id=1&datproductcontrolitem_id=4&datsearch_action_type_id=1&sort=dateofpublication desc&op_mode=and&sessionid=${session_key}&titleKeywordsAbs=${valueText}&fq__resourcemaster_id=(${
        resourcemaster_id ? resourcemaster_id : item.resourcemaster_id
      })`
    );
    return response.data.data.docs;
  } catch (err) {
    console.log(err);
  }
}

export async function getVolumeOrIssueDate(
  year: string,
  resourceMasterId: number
) {
  try {
    let response = await axios.get(
      api.baseApi +
        `/api/indexing/solr/resourceissuecore?page=1&fq__resource_type=( 1 OR 2 OR 3 OR 4 OR 8 )&customerid=${informaticscustomer_id}&consortiamaster_id=1874&profileuserid=${userId}&datsearchtype_id=1&sessionid=${session_key}a1er&datproductcontrolitem_id=4&datsearch_action_type_id=1&sort=dateofpublication desc&op_mode=and&fq__resourcemaster_id=(${resourceMasterId})&fq__yearfrom=${year}`
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function filterBYSource(filteredValues: any) {
  let gotData = filteredValues?.map((e: any) => `"${e}"`);

  console.log("checking data", gotData.join(" OR "));
  // `"${queryObj[d][arrD]}"`

  try {
    let res = await axios.get(
      `${api.baseApi}/api/indexing/solr/resourcecore?page=1&rows=10&profileuserid=${userId}&sessionid=${session_key}&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__subjects_id_l2=(34 OR 30 OR 33 OR 31 OR 32 )&fq__resource_type=(1 )&((resource_name_tk=Journal\\ of* OR resource_shortname_tk=Journal\\ of*))&fq__resource_source_index=(${gotData.join(
        " OR "
      )})`
    );
    // console.log("filteredValues", res);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function filterByCountry(filteredValues: any) {
  let gotData = filteredValues?.map((e: any) => `"${e}"`);
  try {
    let res = axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=10&profileuserid=${userId}&sessionid=${session_key}&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__resource_type=(1 )&primary_publisher_country=(${gotData.join(
          " OR"
        )})`
    );
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function filterBySubject(filteredValues: any) {
  let gotData = filteredValues?.map((e: any) => `"${e}"`);
  // encodeURIComponentPolyfill(gotData);
  console.log("gotData", gotData);

  try {
    let res = axios.get(
      api.baseApi +
        `/api/indexing/solr/resourcecore?page=1&rows=10&profileuserid=${userId}&sessionid=${session_key}&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__resource_type=(1 )&subjects_name_l3=(${gotData.join(
          " OR "
        )})`
    );
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function getDetailsOnSelectiongVolume(issueId: string) {
  console.log("resVol", issueId);
  try {
    let res = await axios.get(
      api.baseApi +
        `/api/indexing/solr/articlecore2?page=1&json_facet={"yearfrom":{"type":"terms","field":"yearfrom","limit":100,"sort":"index desc"},"monthfrom":{"type":"terms","field":"monthfrom","limit":100},"volumefrom":{"type":"terms","field":"volumefrom","limit":100},"issuenumberfrom":{"type":"terms","field":"issuenumberfrom","limit":100},"resource_status":{"type":"terms","field":"resource_status","limit":100},"primary_publisher_country":{"type":"terms","field":"primary_publisher_country","limit":100},"publisher_name":{"type":"terms","field":"publisher_name","limit":100},"journal_name":{"type":"terms","field":"journal_name","limit":100},"filter_metrix":{"type":"terms","field":"filter_metrix","limit":100}}&fq__subjects_id_l2=( 617 OR 35 OR 34 OR 620 OR 30 OR 21 OR 41 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 39 OR 31 OR 40 OR 36 OR 38 OR 28 OR 24 OR 42 OR 43 OR 25 OR 32 OR 29 )&fq__resource_type=( 1 OR 2 OR 3 OR 4 OR 8 )&customerid=1848&consortiamaster_id=1874&profileuserid=11201&datsearchtype_id=1&sessionid=vc3i9jit2q98d9zlh1icuc11s227a1er&datproductcontrolitem_id=4&datsearch_action_type_id=1&sort=dateofpublication desc&op_mode=and&fq__resourceissue_id=${issueId}`
    );
    return res;
  } catch (err) {
    console.log(err);
  }
}

// Books

export async function filterBySubject_Book(filteredValues: any) {
  let gotData = filteredValues?.map((e: any) => `"${e}"`);
  // encodeURIComponentPolyfill(gotData);
  console.log("gotData", gotData);

  try {
    let res = axios.get(
      api.baseApi +
        `/api/indexing/solr/bookscore?page=1&rows=10&profileuserid=${userId}&sessionid=${session_key}&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__resource_type=(12)&subjects_name_l3=(${gotData.join(
          " OR "
        )})`
    );
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function filterByPublisher_Book(filteredValues: any) {
  let gotData = filteredValues?.map((e: any) => `"${e}"`);
  // encodeURIComponentPolyfill(gotData);
  console.log("gotData", gotData);

  try {
    let res = axios.get(
      api.baseApi +
        `/api/indexing/solr/bookscore?page=1&rows=10&profileuserid=${userId}&sessionid=${session_key}&facet_fields=publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix&fq__resource_type=(12)&publisher_name=(${gotData.join(
          " OR "
        )})`
    );
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
  }
}
