import { Alert, Grid, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import logo from "../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "../styles/CheckEmail.css";
import CommonButton from "./CommonButton";
import { Stack } from "@mui/material";
import { getParamsByName } from "../utils/helper";
import { useDispatch } from "react-redux";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { resetPasswordApi } from "../api/userApi";
import { resetpassword } from "../store/slice/loginSlice";
import { useState, useEffect } from "react";
import { usageReport } from "../api/usageReportApi";

export interface State extends SnackbarOrigin {
  open: boolean;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        width: 450,
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const CheckEmail = ({ setModule }: any) => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const emailID = getParamsByName("email");
  console.log("Email Id", emailID);
  const [email, setEmail] = useState(emailID);

  //  console.log('email', emailID)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleResend = async () => {
    setErrorFlag(true);
    console.log("Error Flag Status", errorFlag);

    try {
      console.log(email);
      const resetPassword = await resetPasswordApi({ email });
      usageReport("7");
      console.log("Reset Password", resetPassword);
      dispatch(resetpassword(resetPassword));
      setOpen(true);
      setMessage(`An email has been sent to ${email}`);
    } catch (error: any) {
      setOpen(true);
      console.log(error.message);
      if (error.response.data.data) {
        setMessage(error.response.data.data);
      }
    }
  };

  return (
    <>
      <img
        src={logo}
        alt=""
        className="logo"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/login")}
      />
      {message && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          overflowY: "hidden",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box className="checkemail_container">
          <Item>
            <Typography
              component="h1"
              className="heading_title"
              fontWeight={700}
              fontSize={34}
              fontFamily={"Lora"}
            >
              Check your email
            </Typography>
          </Item>

          <Item>
            <Typography className="resetlink_text">
              We sent a password reset link to{" "}
              <Link className="resend_link" to="#">
                {emailID}
              </Link>
            </Typography>
          </Item>
          <Item>
            {/* <Typography className='resetlink_text'>Didn&apos;t receive the email? <Link to="/" className='resend_link'>Click to resend</Link></Typography> */}
            <CommonButton buttontitle="Resend Link" onClick={handleResend} />
          </Item>
          <Item>
            <Link to="/" className="cancelbtn">
              Cancel
            </Link>
          </Item>
        </Box>
      </Grid>
    </>
  );
};

export default CheckEmail;
