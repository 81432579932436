import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

// Reducers
import searchReducer from "./slice/searchSlice";
import loginReducer from "./slice/loginSlice";
import filterReducer from "./slice/filterSlice";
import authorReducer from "./slice/authorFinderSlice";
import modalSlice from "./slice/modalSlice";
import signupReducer from "./slice/userSlice";
import usertypeReducer from "./slice/userTypeSlice";
import customerDetails from "./slice/customerDetails";
import searchHistoryReducer from "./slice/searchHistorySlice";
import deleteSearchHistoryReducer from "./slice/deleteSearchHistorySlice";
import saveSearchHistoryReducer from "./slice/saveSearchHistorySlice";
import browseJournalSearchSlice from "./slice/browseJournal";
import searchHistoryAlertReducer from "./slice/searchHistoryAlertSlice";
import queryForCountReducer from "./slice/queryForCountSlice";
import browseFilterSlice from "./slice/browseFilterSlice";
import reportIssueReducer from "./slice/reportIssueSlice";
import browserFilterSlicePublisher from "./slice/browserFilterSlicePublisher";
import browserPublisher from "./slice/browserPublisher";
import browseFilterSlicePackage from "./slice/browseFilterSlicePackage";
import browsePackage from "./slice/browsePackage";
import browserDetailsSlice from "./slice/browserFilterSliceDetails";
import appliedQueries from "./slice/appliedQueries";
import captchaVerification from "./slice/captchaVerification";
import setSearch from "./slice/setSearch";
// Presist data in session
// @ts-ignore
import storage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import botListner from "./slice/botListner";
import personalLibrarSlice from "./slice/personalLibrarSlice";

const searchReducerConfig = {
  key: "searchReducer",
  storage,
};
const filterReducerConfig = {
  key: "filterReducer",
  storage,
};
const captchaVerificationConfig = {
  key: "captchaVerification",
  storage,
};
const textCountConfig = {
  key: "textCountConfig",
  storage,
};

const persistedReducer = persistReducer(searchReducerConfig, searchReducer);
const presistedFilterReducer = persistReducer(
  filterReducerConfig,
  filterReducer
);
const presistedCaptchaReducer = persistReducer(
  captchaVerificationConfig,
  captchaVerification
);
const presistedQueryForCountReducer = persistReducer(
  textCountConfig,
  queryForCountReducer
);

export const store = configureStore({
  reducer: {
    searchReducer: persistedReducer,
    login: loginReducer,
    filterReducer: presistedFilterReducer,
    authorReducer,
    modal: modalSlice,
    signup: signupReducer,
    usertype: usertypeReducer,
    customer: customerDetails,
    searchHistory: searchHistoryReducer,
    deleteSearchHistory: deleteSearchHistoryReducer,
    saveSearchHistory: saveSearchHistoryReducer,
    browseJournalSearch: browseJournalSearchSlice,
    searchHistoryAlert: searchHistoryAlertReducer,
    queryForCount: presistedQueryForCountReducer,
    browseFilterSlice,
    reportIssue: reportIssueReducer,
    browserFilterSlicePublisher,
    browserPublisher,
    browseFilterSlicePackage,
    browsePackage,
    browserDetailsSlice,
    appliedQueries,
    captchaVerification: presistedCaptchaReducer,
    botListner: botListner,
    setSearch,
    personalLibrarSlice:personalLibrarSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
