import styled from "@emotion/styled";
import usePagination from "@mui/material/usePagination";
import ArrowBack from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForward from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  IconButton,
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";

// Styles
import "./style.css";
import { useState } from "react";

type PropType = {
  count: number;
  handlePageChange: (val: number) => void;
  getRowsPerPage?: (val: number) => void;
  rowsPerResult?: number;
  currentPage?: number;
  from?: string;
};

const rowsPerPage = [
  { label: "15/Page", value: 15 },
  { label: "25/Page", value: 25 },
  { label: "50/Page", value: 50 },
  { label: "100/Page", value: 100 },
];

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function Pagination(props: PropType) {
  const [rows, setRows] = useState(10);
  const {
    count,
    handlePageChange,
    rowsPerResult,
    getRowsPerPage,
    currentPage,
    from,
  } = props;

  const { items } = usePagination({
    count: count,
    onChange: (e, page) => handleClick(page),
    page: currentPage,
  });

  function handleClick(page: number) {
    if (page) {
      handlePageChange(Number(page));
    }
  }

  function handleRowChange(event: SelectChangeEvent<typeof rows>) {
    const val = event.target.value;
    if (typeof val === "number") {
      setRows(val);
      getRowsPerPage && getRowsPerPage(val);
    }
  }

  return (
    <nav style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <List>
        {items?.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          let selectedx = currentPage == page;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Button className="page-no" disabled>
                ...
              </Button>
            );
          } else if (type === "previous") {
            children = (
              <IconButton
                {...item}
                size="small"
                sx={{ width: "31px", height: "31px" }}
              >
                <ArrowBack sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            );
          } else if (type === "next") {
            children = (
              <IconButton
                {...item}
                size="small"
                sx={{ width: "31px", height: "31px" }}
              >
                <ArrowForward sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            );
          } else if (type === "page") {
            children = (
              <Button
                {...item}
                type="button"
                className="page-no"
                sx={{
                  minWidth: "100%",
                  color: selectedx ? "#7852FB" : "#6B7280",
                  background: selectedx ? "rgba(120, 82, 251, 0.1)" : "",
                  borderRadius: 0,
                  fontWeight: "500",
                  width: "100%",
                  paddingInline: "10px",
                }}
              >
                {page}
              </Button>
            );
          }

          return (
            <li key={index} style={{ border: "1px solid #D1D5DB" }}>
              {children}
            </li>
          );
        })}
      </List>
      {/* Result per page */}
      {from !== "publisherList" && (
        <Select
          onChange={handleRowChange}
          value={rowsPerResult}
          size="small"
          sx={{ height: "32px" }}
          style={{ color: "#6B7280" }}
          MenuProps={{
            PaperProps: {
              style: {
                transform: "translateY(-81px)",
              },
            },
          }}
        >
          {rowsPerPage?.map((row, i) => (
            <MenuItem key={i} value={row.value}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </nav>
  );
}
