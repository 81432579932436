import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Colors } from "../../utils/constants";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { getUserDetails } from "../../api/ddrFormApi";
import { getUserTypes } from "../../api/Profile.api";
import { useMainNav } from "../Nav/MainNav.hook";
import Disclaimer from "./disclaimer";
import { useSelector } from "react-redux";
import { usageReport, usagelogApi } from "../../api/usageReportApi";
import { useAppSelector } from "../../store/hooks";

export type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  college: string;
  department: string;
  profileType: string;
  rollNo: string;
  mobileNum: string;
  regionalStation: string;
  city: string;
  pin: string;
  message: string;
  ddrRequest: string;
  articleDelivery: string;
  admin: string;
  setDDRRequestTo: any;
  otherProfile: string;
  setArticleDeliveryTo: any;
  setSubmitData: any;
  ddrRequestForm: any;
  setDuplicateData: boolean;
};

function DdrForm(props: any) {
  const {
    ddrList,
    validate,
    setDDRRequestTo,
    data,
    setArticleDeliveryTo,
    details,
    resultFrom,
  } = props;

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  console.log(errors);

  const { customerDetails } = useMainNav();

  const [userTypesList, setUserTypesList] = React.useState<any>([]);
  const [profileType, setProfileType] = React.useState<any>("Student");
  const [regionalStation, setRegionalStation] = React.useState("No");
  const [articleDelivery, setArticleDelivery] = React.useState("");
  const [ddrRequest, setDdrRequest] = React.useState("");
  const [userDetailsForm, setUserDetailsForm] = React.useState<any>();
  const [disclaimerDisplay, setDisclaimerDisplay] =
    React.useState<boolean>(false);
  const handleClickOpen = () => {
    setDisclaimerDisplay(!disclaimerDisplay);
  };
  const clgName = useSelector(
    (state: any) => state?.customer?.customerDetails?.customername
  );
  const customerIds = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );

  const adminEmail = useSelector(
    (state: any) => state?.customer?.customerDetails?.librarian_emailid
  );
  const profileData = useAppSelector((state) => state.login);

  const personalDetails = useAppSelector(
    (state) => state.customer.personalDetails
  );

  const userDetails = useAppSelector((state) => state.customer.customerDetails);

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const disableInputField =
    profileStatus === "false" && userDetails?.allow_profile === false; // [ticket: DF-1104] If any one who does not have the option of creating profile "Allow to create Profile User" is disabled, then if the member is of consortia, then they should not be able to enter their email ID / name anywhere

  useEffect(() => {
    const userDetails = async () => {
      props.setDDRRequestTo(customerIds);
      // const data = await getUserDetails();
      const userTypes = await getUserTypes();

      setUserTypesList(userTypes);

      if (data) {
        setValue("college", clgName ?? "");
        setValue("department", props.department ?? "");
        setValue("mobileNum", props.phone ?? "");
        setValue("email", data?.user_email_id ?? "");
        setValue("admin", data?.cc_mail_id ?? "");
        setValue("firstName", personalDetails?.firstname ?? "");
        setValue("lastName", personalDetails?.lastname ?? "");
      }
      if (props.DDRRequestToList.length) {
        setValue("ddrRequest", props.DDRRequestToList[0].value);
      }
    };
    userDetails();
  }, [props.email]);

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom?.resultFrom === "searchResult" ||
    resultFrom?.resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom?.resultFrom ===
        "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logDDRRequestUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      userDetails && userDetails.consortiamaster_id
        ? userDetails.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const onSubmit: SubmitHandler<Inputs> = (datas) => {
    props.setSubmitData(datas);

    if (props.ddrRequestForm?.duplicate_request == false) {
      logDDRRequestUsageData(36, details);
    }
    if (props.ddrRequestForm?.duplicate_request) {
      props.setDuplicateData(true);
    } else {
      validate(datas);
      // usageReport("36")
      reset({
        firstName: "",
        lastName: "",
        department: "",
        rollNo: "",
        mobileNum: "",
        message: "",
      });
      props.setIilcheck(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: "55%", padding: "1%" }}>
        <Typography style={webStyles.heading}>From Details</Typography>
        <Box sx={[webStyles.flexBox, { margin: "3% 0%" }]}>
          {!disableInputField && (
            <Box width={"49%"}>
              <label style={webStyles.inputLabel}>First Name *</label>
              <TextField
                // defaultValue={userDetailsForm}
                {...register("firstName", { required: true })}
                sx={{ marginTop: "2%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                disabled={disableInputField}
              />
            </Box>
          )}

          {!disableInputField && (
            <Box width={"49%"}>
              <label style={webStyles.inputLabel}>Last Name*</label>
              <TextField
                {...register("lastName", { required: true })}
                sx={{ marginTop: "2%", marginLeft: "2%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                disabled={disableInputField}
              />
            </Box>
          )}
        </Box>
        <label style={webStyles.inputLabel}>Email</label>
        <TextField
          {...register("email")}
          sx={{ margin: "2% 0%" }}
          inputProps={{
            style: {
              height: "1.3rem",
            },
          }}
          id="outlined-basic"
          variant="outlined"
          fullWidth
          disabled={disableInputField}
        />

        <label style={webStyles.inputLabel}>
          College/University/Institution*
        </label>
        <Box sx={{ width: "48%" }}>
          <TextField
            {...register("college", { required: true })}
            sx={{ margin: "2% 0%" }}
            inputProps={{
              style: {
                height: "1.3rem",
              },
            }}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            disabled={disableInputField}
          />
        </Box>

        {!disableInputField && (
          <>
            <label style={webStyles.inputLabel}>Department*</label>
            <Box sx={{ width: "48%" }}>
              <TextField
                {...register("department", { required: true })}
                sx={{ margin: "2% 0%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
              />
            </Box>
          </>
        )}
        {userTypesList[0] && (
          <Box>
            <InputLabel style={webStyles.inputLabel}>
              Type of Profile
            </InputLabel>

            <Controller
              name={"profileType"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  sx={[webStyles.inputLabel, { width: "48%", margin: "1% 0%" }]}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setProfileType(e.target.value);
                  }}
                  defaultValue={"Student"}
                  value={value}
                  // displayEmpty
                >
                  {userTypesList?.map((e: any, i: any) => {
                    return <MenuItem value={e[i + 1]}>{e[i + 1]}</MenuItem>;
                  })}
                </Select>
              )}
            />
            {profileType === "Others" && (
              <Box sx={{ width: "48%" }}>
                <TextField
                  {...register("otherProfile", { required: true })}
                  sx={{ margin: "2% 0%" }}
                  inputProps={{
                    style: {
                      height: "1.3rem",
                    },
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            )}
          </Box>
        )}
        {!disableInputField && profileType === "Student" ? (
          <>
            <label style={webStyles.inputLabel}>Roll No/Admin No*</label>
            <Box sx={{ width: "48%" }}>
              <TextField
                {...register("rollNo", {
                  required: profileType == "Student" ? true : false,
                })}
                sx={{ margin: "2% 0%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
              />
            </Box>
          </>
        ) : (
          ""
        )}

        {!disableInputField && (
          <>
            <label style={webStyles.inputLabel}>Mobile/Phone No</label>
            <Box sx={{ width: "48%" }}>
              <TextField
                {...register("mobileNum")}
                sx={{ margin: "2% 0%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
              />
            </Box>
          </>
        )}

        <Box>
          <InputLabel style={webStyles.inputLabel}>
            If you are from Regional Station/Institution/College
          </InputLabel>

          <Controller
            name={"regionalStation"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                sx={[webStyles.inputLabel, { width: "48%", margin: "1% 0%" }]}
                onChange={(e) => {
                  onChange(e.target.value);
                  setRegionalStation(e.target.value);
                }}
                value={regionalStation}
                displayEmpty
              >
                <MenuItem value={"No"}>No</MenuItem>
                <MenuItem value={"Yes"}>Yes</MenuItem>
              </Select>
            )}
          />
        </Box>
        {regionalStation === "Yes" ? (
          <>
            <label style={webStyles.inputLabel}>City</label>
            <Box sx={{ width: "48%" }}>
              <TextField
                {...register("city", { required: true })}
                sx={{ margin: "2% 0%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
              />
            </Box>{" "}
          </>
        ) : null}

        {regionalStation === "Yes" ? (
          <>
            <label style={webStyles.inputLabel}>PIN</label>
            <Box sx={{ width: "48%" }}>
              <TextField
                {...register("pin", { required: true })}
                sx={{ margin: "2% 0%" }}
                inputProps={{
                  style: {
                    height: "1.3rem",
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
              />
            </Box>
          </>
        ) : null}

        <label style={webStyles.inputLabel}>Message</label>

        <TextField
          {...register("message")}
          sx={{ margin: "2% 0%" }}
          multiline
          rows={6}
          maxRows={6}
          id="outlined-basic"
          variant="outlined"
          fullWidth
        />
      </Box>

      <Box sx={{ width: "65%", padding: "1%" }}>
        <Typography style={webStyles.heading}>To Details</Typography>
        <Box sx={[webStyles.flexBox, { marginTop: "2%" }]}>
          <Box width={"49%"}>
            <label style={webStyles.inputLabel}>DDR Request To*</label>

            {props.DDRRequestToList?.length ? (
              <Controller
                name={"ddrRequest"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    sx={[
                      webStyles.inputLabel,
                      { width: "98%", margin: "2% 0%" },
                    ]}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setDdrRequest(e.target.value);
                      setArticleDeliveryTo(e.target.value);
                    }}
                    value={value}
                    displayEmpty
                    defaultValue={props.DDRRequestToList[0]?.value}
                  >
                    {props.DDRRequestToList?.map((e: any, i: any) => {
                      return <MenuItem value={e.value}>{e?.label}</MenuItem>;
                    })}
                  </Select>
                )}
              />
            ) : null}
          </Box>
          <Box width={"49%"}>
            <label style={webStyles.inputLabel}>Article Delivery To*</label>
            <Controller
              name={"articleDelivery"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  sx={[webStyles.inputLabel, { width: "98%", margin: "2% 0%" }]}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setArticleDelivery(e.target.value);
                    props.setDDRRequestTo(e.target.value);
                  }}
                  value={value}
                  defaultValue={customerIds}
                  displayEmpty
                >
                  {[
                    { label: clgName, value: customerIds },
                    ...props.articleDeliveryToList,
                  ]?.map((e: any, i: any) => {
                    return <MenuItem value={e.value}>{e.label}</MenuItem>;
                  })}
                </Select>
              )}
            />
          </Box>
        </Box>
        <Typography style={webStyles.heading}>Cc Details</Typography>

        <label style={webStyles.inputLabel}>Library Admin*</label>
        <Box sx={{ width: "42%" }}>
          <TextField
            {...register("admin", { required: true })}
            inputProps={{
              style: {
                height: "1.3rem",
              },
            }}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            disabled={disableInputField}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox checked={props.iilcheck} />}
          label="ILL - User Declaration*"
          onChange={() => props.setIilcheck(!props.iilcheck)}
        />
      </Box>

      <Typography
        sx={[webStyles.inputLabel, { padding: "1%", color: Colors.gray_600 }]}
      >
        I need this article for my personal study and research only. I agree not
        to copy,modify, loan, sell, distribute or create derivative works based
        on the content in this article either in whole or part. Wherever I use
        any part of the content of this article, I will acknowledge by citing
        the article.
      </Typography>
      <Button onClick={handleClickOpen}>
        <Typography style={{ color: Colors.primary, padding: "0% 1%" }}>
          Disclaimer
        </Typography>
      </Button>
      <Box sx={[webStyles.flexBox, { padding: "1%" }]}>
        <Button type="submit" variant="contained" sx={webStyles.button}>
          Send
        </Button>
        <Button
          sx={webStyles.button}
          onClick={() => {
            props.setIilcheck(false);
            reset({
              firstName: "",
              lastName: "",
              department: "",
              rollNo: "",
              mobileNum: "",
              message: "",
            });
          }}
        >
          Cancel
        </Button>
      </Box>
      <Disclaimer
        handleClickOpen={handleClickOpen}
        disclaimerDisplay={disclaimerDisplay}
      ></Disclaimer>
    </form>
  );
}

export default DdrForm;

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white,
  },
  heading: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "1.375rem",
  },
  text: {
    fontFamily: "Helvetica Neue",
    fontWeight: 400,
    fontSize: "1rem",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "0.875rem",
    fontFamily: "Helvetica Neue",
    marginTop: "0.9%",
  },
  inputLabel: {
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  button: {
    padding: "0.5% 4%",
  },
};
