import axios from "axios";

import http from "./httpService";
import api from "./endpoints";
let userId = sessionStorage.getItem("user_id");
export async function citeArticle() {
  console.log("uerid", userId);

  try {
    const response = await axios.get(
      `http://52.172.188.241/JgateCitationRestApi2/rest/webservices/getdownloadcitation?citeTypeId=1&authors=Baleanu&articlename=A Fractional Differential Equation Model for the COVID-19 Transmission by Using the Caputo–Fabrizio Derivative&journalname=Advances in Difference Equations&pagenumber=&issueyear=2020&volumenumber=2020&issuenumber=1&resourcetype=1&issueMonth=12&publisherName=Springer Nature&bookTitle=&sourceType=`
    );
    console.log(response);
    return "data";
  } catch (error) {
    console.log(error);
  }
}

export async function getCiteArticleDetails() {
  try {
    const response = await axios.get(
      `${api.baseApi}/api/get_dat_cite_type/get_dat_cite_type`
    );

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getCiteDescription(article_id: string, cite_type: string | number) {
  try {
    const response = await axios.get(
      `${api.baseApi}/api/get_citition_details/get_citition_details?article_id=${article_id}&cite_type_id=${cite_type}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}
