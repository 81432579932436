import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { deleteSavedSearch } from "../../../api/searchHistoryapi";
import { GetMySavedSearchData } from "../../../types/GetSavedSearch.types";
import { notify } from "../../../utils/Notify";
import DeleteSearchModal from "../MyAlerts/DeleteSearch.modal";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentAlert: GetMySavedSearchData | undefined;
  refetch: any;
  isSearchHishtory?: boolean;
  multiple: boolean;
  selectedSearches: any;
}

const SavedSearchMenu = ({
  open,
  onClose,
  anchor,
  currentAlert,
  refetch,
  multiple,
  selectedSearches,
}: Props) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const deleteAlert = async () => {
    const search_log_ids = multiple
      ? selectedSearches
          ?.map((item: any) => {
            return item?.search_id;
          })
          ?.join(",")
      : currentAlert?.search_id;
    if (!search_log_ids) return;

    deleteSavedSearch(search_log_ids).then(() => {
      refetch();
      onClose();
      setIsDeleteOpen(false);
      notify("success", "Search Deleted");
    });
  };

  const deleteModal = () => {
    setIsDeleteOpen(true);
  };

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "red" }} onClick={deleteModal}>
          Delete
        </MenuItem>
      </Menu>
      <DeleteSearchModal
        show={isDeleteOpen}
        alertName={currentAlert?.SearchTerms?.split("search_term:")[1] || ""}
        onClose={() => setIsDeleteOpen(false)}
        deleteAlert={deleteAlert}
        infoText="Are you sure you want to permanently delete this search term?"
      />
    </>
  );
};

export default SavedSearchMenu;
