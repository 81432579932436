import { Select, SelectChangeEvent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";

interface PropType {
  name: string;
  control: Control<FieldValues, any>;
  option?: [];
  type?: string;
  rules?: {};
  children: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  handleChange?: (e: SelectChangeEvent<any>, name: string) => void;
  defaultValue?: string;
}

const SelectField = (props: PropType) => {
  const { name, control, rules, children, fullWidth, disabled, handleChange } =
    props;
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              onChange={(e) => {
                onChange(e);
                handleChange && handleChange(e, name);
              }}
              value={value}
              fullWidth={fullWidth}
              disabled={disabled}
              sx={{ backgroundColor: "#FFF" }}
            >
              {children}
            </Select>
            {error ? (
              <Typography sx={{ color: "#F05252" }} fontSize="12px">
                {error.message}
              </Typography>
            ) : null}
          </>
        )}
      />
    </Box>
  );
};

export default SelectField;
