import { useFieldArray, UseFieldArrayAppend, useForm } from "react-hook-form";

export interface QBFormValues {
  myFields: {
    qbIndex: number;
    searchTerm: string;
    conditionField: string;
    criteriaField: string;
    from?: "";
    to?: "";
    start: boolean;
    contain: boolean;
    journalSelected: any[];
    pageIndex: number;
    selectedJournal: any[];
  }[];
}

export type AppendType = UseFieldArrayAppend<QBFormValues, "myFields">;

function useQBFormField() {
  const { control, handleSubmit, watch, setValue } = useForm<QBFormValues>({
    defaultValues: {
      myFields: [
        {
          qbIndex: 0,
          conditionField: "AND",
          criteriaField: "Title/Abstract/Keyword",
          searchTerm: "",
          start: false,
          contain: true,
          journalSelected: [],
          pageIndex: 1,
          selectedJournal: [],
        },
        {
          qbIndex: 1,
          conditionField: "OR",
          criteriaField: "Title/Abstract/Keyword",
          searchTerm: "",
          start: false,
          contain: true,
          journalSelected: [],
          pageIndex: 1,
          selectedJournal: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray<QBFormValues>({
    control,
    name: "myFields",
  });

  return {
    fields,
    control,
    append,
    remove,
    handleSubmit,
    watch,
    setValue,
  };
}

export default useQBFormField;
