import { createSlice } from "@reduxjs/toolkit";

export interface InitialstateType {
    isLoading: boolean;
    data: any[];
    message: string;
    error: string;
}

const initialState: InitialstateType = {
    isLoading: false,
    data: [],
    message: "",
    error: "",
}

export const signupSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
        signupPending: (state) => {
            state.isLoading = true;
        },
        signupSuccess: (state, { payload }) => {
            console.log('Payload signup data', payload)
            state.isLoading = false;
            state.data = payload.data[0][0];
            state.message = payload.message;
            state.error = payload.error;
        },
        signupFail: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data;
            state.error = payload.error;
            state.message = payload.message;
        },
    },
});

const { reducer, actions } = signupSlice;

export const { signupPending, signupSuccess, signupFail } = actions;

export default reducer;



