import { Box, Button, CardContent, Typography } from "@mui/material";
import { Card } from "@mui/material";
import { MathJax } from "better-react-mathjax";
import highlightKeyword from "../../utils/highlightKeyword";

// css
import "./style.css";

interface PropType {
  data: any;
  searchTerm: string[];
}

export default function LibraryCatalogueCard(props: PropType) {
  const { data, searchTerm } = props;

  return (
    <Card sx={{ my: 2, py: 1 }}>
      <CardContent sx={{ padding: "0px 14px" }}>
        {data?.title && (
          <Typography
            fontWeight={600}
            fontSize={"1.125rem"}
            fontFamily={"Lora"}
            sx={{
              textAlign: "left",
              color: "#1A1A1A",
              cursor: "pointer",
            }}
            component={"div"}
          >
            <MathJax>
              <div
                id="title"
                dangerouslySetInnerHTML={{
                  __html: highlightKeyword(data?.title, searchTerm),
                }}
              />
            </MathJax>
          </Typography>
        )}
      </CardContent>

      {/* Author details */}
      {Array.isArray(data?.authors) && data?.authors.length && (
        <CardContent
          sx={{ padding: "6px 14px", display: "flex", flexWrap: "wrap" }}
        >
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            By:{" "}
          </Typography>

          {data?.authors?.map((str: string) => (
            <Box>
              <Typography fontWeight={"500"} className="fs-14">
                {str}
              </Typography>
            </Box>
          ))}
        </CardContent>
      )}

      {/* Series title */}
      {data?.series_title && (
        <CardContent
          sx={{ padding: "6px 14px", display: "flex", flexWrap: "wrap" }}
        >
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Series:{" "}
          </Typography>
          <Typography fontWeight={"500"} className="fs-14">
            {data?.series_title}
          </Typography>
        </CardContent>
      )}

      {/* Material Type, Format, Literary Form */}
      <CardContent
        sx={{ padding: "6px 14px", display: "flex", flexWrap: "wrap", gap: 2 }}
      >
        {/* Material type */}
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Material Type:
          </Typography>
          <Typography fontWeight={"500"} className="fs-14">
            {data?.material_type}
          </Typography>
        </Box>

        {/* Format */}
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Format:
          </Typography>
          <Typography
            fontWeight={"500"}
            className="fs-14"
            sx={{ textTransform: "capitalize" }}
          >
            {data?.result_format}
          </Typography>
        </Box>

        {/* Literary Form */}
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Literary Form:
          </Typography>
          <Typography
            fontWeight={"500"}
            className="fs-14"
            sx={{ textTransform: "capitalize" }}
          >
            {data?.literary_form}
          </Typography>
        </Box>
      </CardContent>

      {/* Publication Details: */}
      {data?.publication_details && (
        <CardContent
          sx={{
            padding: "6px 14px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Publication Details:
          </Typography>
          <Typography
            fontWeight={"500"}
            className="fs-14"
            sx={{ textTransform: "capitalize" }}
          >
            {data?.publication_details}
          </Typography>
        </CardContent>
      )}

      {/* Availability Details: */}
      {data?.availability_data && (
        <CardContent
          sx={{
            padding: "6px 14px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Availability:
          </Typography>
          <Typography
            fontWeight={"500"}
            className="fs-14"
            sx={{ textTransform: "capitalize" }}
          >
            {data?.availability_data}
          </Typography>
        </CardContent>
      )}

      {/* Location Details: */}
      {data?.itemcallnumber && (
        <CardContent
          sx={{
            padding: "6px 14px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ pr: 0.5 }} fontWeight={600} className="fs-14">
            Location(s):
          </Typography>
          <Typography
            fontWeight={"500"}
            className="fs-14"
            sx={{ textTransform: "capitalize" }}
          >
            {data?.itemcallnumber}
          </Typography>
        </CardContent>
      )}

      {/* Button */}
      <CardContent
        sx={{
          padding: "0px 14px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          href={data?.details_url}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          sx={{ color: "#6B7280", border: "1px solid #6B7280"}}
        >
          See Details
        </Button>
      </CardContent>
    </Card>
  );
}
