import Button from '@mui/material/Button';
import '../styles/global.css'

import { Colors } from '../utils/constants';


const CommonButton = ({ buttontitle, onClick, disabled }: any) => {
    return (
        <>
            <Button onClick={onClick} disabled={disabled} className='commonbutton' style={{ backgroundColor: Colors.primary, color: Colors.white, textTransform: "capitalize" }}>{buttontitle}</Button>
        </>
    )
}

export default CommonButton