import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ScrollToTop = ({ onClick }: any) => {
  return (
    <IconButton
      aria-label="Scroll to Top"
      sx={{
        position: "fixed",
        top: "63vh",
        right: "2vw",
        transform: "translateY(-50%)",
        border: "1px solid #D1D5DB",
        backgroundColor: "#FFF",
        color: "primary.main",
        "&:hover": {
          border: "1px solid #D1D5DB",
          backgroundColor: "#FFF",
          color: "primary.main",
          boxShadow: 2,
        },
      }}
      onClick={onClick}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export default ScrollToTop;
