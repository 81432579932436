import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useAppSelector } from "../../store/hooks";
import { usagelogApi } from "../../api/usageReportApi";
import { dataTypes } from "../../utils/constants";

interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterValues?: any;
  applyFilter?: (e: string[]) => void;
  appliedFilterValues?: string[];
}

const ModalOne = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterValues,
    applyFilter,
    appliedFilterValues,
  } = props;
  const [filteredValues, setFilteredvalues] = useState<string[]>([]);
  const consortiamaster_id = useAppSelector(
    (state: any) => state.customer.customerDetails?.consortiamaster_id
  );
  const profileData = useAppSelector((state: any) => state.login);

  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "Consortia Collections"
      ? 2
      : selectedFilters.collectionValue === "My Library Collections"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  useEffect(() => {
    setValues();
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);
  }

  const selectedValues = (data: string) => {
    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
    }
  };

  const handleSubmit = () => {
    if (!filteredValues.length) return;

    // if (filteredValues.includes(dataTypes.journalArticle)) {
    //   logBasicSearchUsageData(44);
    // } else if (filteredValues.includes(dataTypes.dissertationThesis)) {
    //   logBasicSearchUsageData(46);
    // } else if (filteredValues.includes(dataTypes.preprint)) {
    //   logBasicSearchUsageData(48);
    // } else if (filterValues.includes(dataTypes.conferenceProceedings)) {
    //   logBasicSearchUsageData(45);
    // } else if (filterValues.includes(dataTypes.audioVideo)) {
    //   logBasicSearchUsageData(47);
    // } else if (filterValues.includes("Datasets")) {
    //   logBasicSearchUsageData(49);
    // } else if (filterValues.includes(dataTypes.magazine)) {
    //   logBasicSearchUsageData(50);
    // } else {
    //   console.log("filteredValues");
    // }

    //============================

    filteredValues.map((item) => {
      switch (item) {
        case dataTypes.journalArticle:
          logBasicSearchUsageData(44);
          break;
        case dataTypes.dissertationThesis:
          logBasicSearchUsageData(46);
          break;
        case dataTypes.preprint:
          logBasicSearchUsageData(48);
          break;
        case dataTypes.conferenceProceedings:
          logBasicSearchUsageData(45);
          break;
        case dataTypes.audioVideo:
          logBasicSearchUsageData(47);
          break;
        case "Datasets":
          logBasicSearchUsageData(49);
          break;
        case dataTypes.magazine:
          logBasicSearchUsageData(50);
          break;
        default:
        // Handle default case if needed
      }
    });

    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };

  function handleCancel() {
    setFilteredvalues([]);
    handleClose();
  }
  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: 700 }}>
        {Array.isArray(filterValues) &&
          filterValues?.map((data: any) => {
            if (data?.val === "NAAS" && consortiamaster_id !== 1874) return;
            return (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems="center" minWidth={200}>
                  <Checkbox
                    onClick={() => selectedValues(data?.val)}
                    checked={filteredValues?.includes(data?.val)}
                  />

                  <Typography>{data?.val}</Typography>
                </Stack>

                <Typography>({data?.count})</Typography>
              </Stack>
            );
          })}

        {/* Buttons */}
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 2 }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ minWidth: 150 }}
          >
            Apply
          </Button>
          <Button
            onClick={handleCancel}
            sx={{ minWidth: 150, color: "#111928" }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ModalOne;
