import axios from "axios";
import api from "./endpoints";
import http from "./httpService";


const rssAlert = api.baseApi + `/api/set_alert_for_resource/set_alert_for_resource`

export async function rssalertApi({ userjson }: any) {
    try {
        console.log(JSON.stringify(userjson), 'rss alert details')
        const res = await axios.post(rssAlert, { userjson: JSON.stringify(userjson) },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        console.log('RSS Alert RESPONSE', res)

        if (res.data.data[0][0].status == "New Alert created successfully!" ||
            res.data.data[0][0].status == "Alert updated successfully!" || res.data.data[0][0].Message === 'Limit exceed 100') {
            return res;
        }

    } catch (error) {
        console.log("error", error)
    }

}


export async function get_emailnotificationfrequency(): Promise<any[]> {
    try {
        const { data: response } = await http.get(
            "/api/get_emailnotificationfrequency/get_emailnotificationfrequency",
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        console.log("RESPONSE DATA FREQUENCY", response.data[0][0]);
        return response.data[0][0];

    } catch (error) {
        console.log("err", error);
        throw error;
    }
}