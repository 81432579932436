import { createSlice } from "@reduxjs/toolkit";

export type InitialstateType = {
  isSetSearchEnabled: boolean;
  setSearchQuery: string;
  setInNumber: string;
};

const initialState: InitialstateType = {
  isSetSearchEnabled: false,
  setSearchQuery: "",
  setInNumber: "",
};

export const setSearchSlice = createSlice({
  name: "setSearch",
  initialState,
  reducers: {
    updateSetSearchStatus: (state, { payload }) => {
      state.isSetSearchEnabled = payload;
    },
    updateSetSearchQuery: (state, { payload }) => {
      state.setSearchQuery = payload;
    },
    updateSetInNumber: (state, { payload }) => {
      state.setInNumber = payload;
    },

    clearAllSetSearch: (state) => {
      state.isSetSearchEnabled = false;
      state.setInNumber = "";
      state.setSearchQuery = "";
    },
  },
});

export const {
  updateSetInNumber,
  updateSetSearchQuery,
  updateSetSearchStatus,
  clearAllSetSearch,
} = setSearchSlice.actions;
export default setSearchSlice.reducer;
