import React from "react";
import {
  Typography,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useEffect } from "react";
import { usagelogApi } from "../../api/usageReportApi";
import { notify } from "../../utils/Notify";
import { frequencyMap } from "../MyLibrary/MyAlerts/AlertModal";
import { SearchHistory } from "./SearchHistory";
import { useAppSelector } from "../../store/hooks";
import {
  checkAlertTopicAvailability,
  subscribeToRss,
} from "../../api/MyLibrary/SearchAlerts.api";
import { Colors } from "../../utils/constants";

type Props = {
  open: boolean;
  onClose: () => void;
  selectedMenuHistory?: SearchHistory;
  refetch: () => Promise<void>;
};

const SearchHistoryAlert = ({
  open,
  onClose,
  selectedMenuHistory: currentData,
  refetch,
}: Props) => {
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [rssChecked, setRssChecked] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");
  const [selectedFreq, setSelectedFreq] = useState<string>("2");
  const [alertTopic, setAlertTopic] = useState("");
  const [rssURL, setRSSurl] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { user_id, session_key, informaticscustomer_id } = useAppSelector(
    (state) => state.login
  );
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );

  useEffect(() => {
    if (currentData) {
      const alertValue = currentData.datalerttypeid ?? 0;
      const frequency = currentData.dat_emailnotificationfrequency_id ?? 2;

      setEmailChecked(alertValue === 3 || alertValue === 1);
      setRssChecked(alertValue === 3 || alertValue === 2);
      setSelectedFreq(frequency.toString());
      setAlertTopic(currentData?.alert_topic);
    }
  }, [currentData]);

  useEffect(() => {
    return () => {
      setEmailChecked(false);
      setRssChecked(false);
      setAlertTopic("");
      setShowConfirmation(false);
    };
  }, [open]);

  const logAlertUsageData = (emailActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = user_id ? user_id : null;
    const informaticscustomerId = informaticscustomer_id
      ? informaticscustomer_id
      : null;
    const consortiamasterId =
      customerData && customerData.consortiamaster_id
        ? customerData.consortiamaster_id
        : null;
    const sessionKey = session_key ? session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      emailActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFreq(event.target.value);
  };

  const handleSearchHistoryAlert = async (e: any) => {
    e.preventDefault();
    let alertId: any;

    if (!alertTopic) {
      notify("error", "Alert topic is required");
      return;
    }
    console.log(emailChecked, rssChecked, "xy");
    if (emailChecked === false && rssChecked === false) {
      notify("error", "Please select alert type to set alert");
      return;
    }
    if (emailChecked && rssChecked) {
      setAlertType(3);
      console.log("Both", alertType);
      alertId = 3;
    } else if (emailChecked === true) {
      setAlertType(1);
      console.log("Email alert", alertType);
      alertId = 1;
    } else if (rssChecked === true) {
      setAlertType(2);
      console.log("RSS alert", alertType);
      alertId = 2;
    }

    logAlertUsageData(95);

    try {
      const alertjson = {
        data: [
          {
            usermaster_id: user_id,
            resourcetype_id: 1,
            alert_topic: alertTopic ?? null,
            mail_frequency: selectedFreq,
            alert_type_id: alertId,
            usersearchlog_id: currentData?.search_id,
          },
        ],
      };

      const formData = new FormData();
      formData.append("alertjson", JSON.stringify(alertjson));

      // Check the alert topic availability
      const isTopicAvailable = await checkAlertTopicAvailability(alertTopic);
      if (isTopicAvailable === "Alert Topic already exists") {
        notify("error", isTopicAvailable);
        return;
      }

      const res = await subscribeToRss(formData);
      if (res?.data?.message === "Ok") {
        const rssUrl = res?.data?.data[0].rssurl;
        setRSSurl(rssUrl);
        if (rssUrl) {
          setShowConfirmation(true);
        } else {
          onClose();
        }
        notify("success", "Alert Saved Successfully");
        refetch();
      }
    } catch (err) {
      console.log("ERRRRRorrrrrrrrrrrrrr", err);
    }
  };

  const handleEmailCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailChecked(event.target.checked);
  };

  const handleRSSCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRssChecked(event.target.checked);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} id={"search-history-alert-modal"}>
        {!showConfirmation ? (
          <Box>
            <DialogTitle
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                textAlign="left"
                fontFamily={"Lora"}
                fontWeight={700}
                fontSize={28}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Create New Email/RSS Alert
              </Typography>

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  textAlign="left"
                  color="#212121"
                >
                  Alert Topic
                </Typography>

                <TextField
                  margin="normal"
                  id="text"
                  placeholder="Enter topic name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={alertTopic}
                  onChange={(e: any) => setAlertTopic(e.target.value)}
                  sx={{ width: "100%", background: "#fffff" }}
                  fullWidth={true}
                />
              </Box>

              <Box sx={{ width: "100%", my: 1 }}>
                <Typography fontWeight={600}>Email Alert Frequency</Typography>
                <Select
                  value={selectedFreq}
                  defaultValue={"2"}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    width: "50%",
                    border: "1px solid",
                    borderColor: "#D1D5DB",
                    px: "1rem 0.5rem",
                    borderRadius: 2,
                    my: 1,
                    fontSize: "0.875rem",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {frequencyMap?.map((item) => (
                    <MenuItem value={item.i} key={item.i}>
                      {item.freq}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailChecked}
                      onChange={handleEmailCheckboxChange}
                    />
                  }
                  label="Email Alerts"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rssChecked}
                      onChange={handleRSSCheckboxChange}
                    />
                  }
                  label="RSS Alerts"
                />
              </FormGroup>

              <DialogActions style={{ justifyContent: "flex-start" }}>
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#7852FB",
                    },

                    textAlign: "left",
                    backgroundColor: "#7852FB",
                    color: " #FAF8F5",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    fontSize: "16px",
                    width: "192px",
                    height: "51px",
                  }}
                  onClick={handleSearchHistoryAlert}
                  // onClick={openViewModal}
                >
                  Save
                </Button>

                <Button
                  sx={{
                    textAlign: "left",
                    left: "42px",
                    fontWeight: 500,
                    fontSize: "18px",
                    backgroundColor: "#FFFFFF",
                    color: " #111928",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    height: "51px",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </DialogContent>
          </Box>
        ) : (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "450px",
              maxHeight: "77vh",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography
                id="modal-modal-title"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.4rem",
                  textAlign: "center",
                }}
              >
                Alert Saved Successfully
              </Typography>
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography>
              Please add the below url in RSS Reader to receive alerts
            </Typography>
            <a
              style={{
                color: Colors.primary,
                marginBlock: 2,
                textOverflow: "ellipsis",
                width: "400px",
                overflow: "hidden",
                cursor: "copy",
              }}
              href={rssURL}
              target="_blank"
              rel="noreferrer"
            >
              {rssURL}
            </a>

            <Box sx={{ width: "100%", my: 2 }}>
              <Button
                variant="contained"
                sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
                onClick={() => {
                  onClose();
                  setShowConfirmation(false);
                }}
              >
                Done
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default SearchHistoryAlert;
