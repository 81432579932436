import { Box, BoxProps, Button, Stack, Typography } from "@mui/material";
import logo from "../assets/img/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextfield from "../components/formInputs/TextField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import generateNewPassword from "../api/createNewPwd.userApi";
import { notify } from "../utils/Notify";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        width: 450,
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const ChangePassword = () => {
  const { user_id, password_token } = useParams();
  const navigate = useNavigate();
  const { control, watch, setError, handleSubmit, reset } = useForm();
  const newPassword = watch("new_password");

  // in case if they want these checks later... need to add these checks

  // const minLengthCheck = /^.{6,}$/;
  // const lowerCaseCheck = /^(?=.*[a-z])/;
  // const upperCaseCheck = /^(?=.*[A-Z])/;
  // const specialCharacterCheck = /^(?=.*[\W_])/;
  // const numberCheck = /^(?=.*\d)/;

  const whitespaceCheck = /^(?!.*\s)/;

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  async function createNewPassword(data: any) {
    try {
      //this change is needed in order to handle space issues in form (#2137)
      const new_password = data?.new_password.trim();
      const confirm_password = data?.confirm_password.trim();

      if (!password_token || !user_id) {
        setError("new_password", {
          type: "custom",
          message: "Error in fetching user_id and token",
        });
        return;
      }

      if (new_password.length < 8) {
        setError("new_password", {
          type: "custom",
          message: "Password length must be atleast 8 characters",
        });
        return;
      }

      if (new_password !== confirm_password) {
        setError("confirm_password", {
          type: "custom",
          message: "Confirm Password is not match",
        });
        return;
      }

      //whitespace check for confirm password and new password if other checks are needed that needs to be added as well (#2137)
      switch (false) {
        case whitespaceCheck.test(new_password):
          setError("new_password", {
            type: "custom",
            message: "Password must not have whitespace.",
          });
          return;

        case whitespaceCheck.test(confirm_password):
          setError("confirm_password", {
            type: "custom",
            message: "Password must not have whitespace.",
          });
          return;
      }

      const formData = new FormData();
      formData.append("forgot_password_token", password_token);
      formData.append("profileuser_id", user_id);
      formData.append("new_password", new_password);
      formData.append("re_enter_password", confirm_password);
      const response = await generateNewPassword(formData);
      if (response?.err) {
        notify("error", response?.err?.data);
      } else {
        notify("success", "Password Updated Successfully Please login");
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleOldPassword() {
    setShowPassword((prev) => ({
      ...prev,
      oldPassword: !prev.oldPassword,
    }));
  }

  function handleNewPassword() {
    setShowPassword((prev) => ({
      ...prev,
      newPassword: !prev.newPassword,
    }));
  }

  function handleConfirmPassword() {
    setShowPassword((prev) => ({
      ...prev,
      confirmPassword: !prev.confirmPassword,
    }));
  }

  const handleClear = () => {
    const inputFields = ["old_password", "new_password", "confirm_password"];

    reset({
      [inputFields[0]]: "",
      [inputFields[1]]: "",
      [inputFields[2]]: "",
    });

    setShowPassword({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
  };

  return (
    <Box margin={2}>
      <img src={logo} alt="Jgate" />

      {/* Create new password form */}
      <Stack
        direction={"column"}
        height={"80vh"}
        justifyContent={"center"}
        sx={{
          alignItems: { xs: "start", sm: "start", md: "center", lg: "center" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
          <Typography
            fontFamily={"Lora"}
            fontSize={"34px"}
            fontWeight={"700"}
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px", lg: "34px" },
            }}
          >
            Create New Password
          </Typography>

          {/* Form */}
          {/* <Box>
            <Typography>Old Password</Typography>
            <CustomTextfield
              control={control}
              name="old_password"
              fullWidth
              size="medium"
              placeholder="********"
              rules={{ required: "Old Password is required field" }}
              type={showPassword.oldPassword ? "text" : "password"}
              showeyeIcon
              eyeIconStatus={showPassword.oldPassword}
              handleIcon={handleOldPassword}
            />
          </Box> */}
          <Box>
            <Typography>New Password</Typography>
            <CustomTextfield
              control={control}
              name="new_password"
              fullWidth
              size="medium"
              placeholder="********"
              rules={{ required: "New Password is required field" }}
              type={showPassword.newPassword ? "text" : "password"}
              showeyeIcon
              eyeIconStatus={showPassword.newPassword}
              handleIcon={handleNewPassword}
            />
          </Box>
          <Box>
            <Typography>Confirm Password</Typography>
            <CustomTextfield
              control={control}
              name="confirm_password"
              fullWidth
              size="medium"
              placeholder="********"
              disabled={!newPassword}
              rules={{ required: "Confirm Password is required field" }}
              type={showPassword.confirmPassword ? "text" : "password"}
              showeyeIcon
              eyeIconStatus={showPassword.confirmPassword}
              handleIcon={handleConfirmPassword}
            />
          </Box>

          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSubmit(createNewPassword)}
            type="submit"
          >
            Submit
          </Button>
          {/* <Item>
            <Button onClick={handleClear} className="cancelbtn">
              Clear
            </Button>
          </Item> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default ChangePassword;
