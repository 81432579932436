import { createSlice } from "@reduxjs/toolkit";

interface Obj {
  key: string;
  value: string;
}

export interface AppliedQueryType {
  appliedQueries: Obj[];
}

const initialState: AppliedQueryType = {
  appliedQueries: [],
};

const appliedQueriesSlice = createSlice({
  name: "appliedQueries",
  initialState,
  reducers: {
    updateQueries(state, { payload }) {
      if (Array.isArray(payload) && state.appliedQueries.length) {
        state.appliedQueries = [...state.appliedQueries, ...payload];
      } else if (state.appliedQueries.length) {
        state.appliedQueries = [...state.appliedQueries, payload];
      } else {
        state.appliedQueries = [payload];
      }
    },
    resetAppliedQueries: () => initialState,
  },
});

export const { updateQueries, resetAppliedQueries } =
  appliedQueriesSlice.actions;
export default appliedQueriesSlice.reducer;
