import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useMutateDeleteDatasetSubjects } from "../../../api/MyLibrary/Dataset.api";
import { useAppSelector } from "../../../store/hooks";
import DeleteSearchModal from "./DeleteSearch.modal";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentAlert: any;
}

const PatentMenu = ({ open, onClose, anchor, currentAlert }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const selector = useAppSelector((state) => state.login);
  const deletedatasetHandler = useMutateDeleteDatasetSubjects();

  const deleteAlert = (id: number) => {
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("subject_level3_ids", id.toString());
    formData.append("datatype_id", "25");
    deletedatasetHandler.mutate({ formData });

    onClose();
    setIsOpen(false);
  };

  const deleteModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "red" }} onClick={deleteModal}>
          Delete Subject
        </MenuItem>
      </Menu>
      <DeleteSearchModal
        show={isOpen}
        alertName={currentAlert?.subject_name3}
        onClose={() => setIsOpen(!isOpen)}
        deleteAlert={() => deleteAlert(currentAlert?.subject_id3)}
      />
    </>
  );
};

export default PatentMenu;
