export function generateRainbowColors(count: number) {
  const colors: string[] = [];

  for (let i = 0; i < count; i++) {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 51) + 50;
    const lightness = Math.floor(Math.random() * 31) + 50;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    if (!colors.includes(color)) {
      colors.push(color);
    } else {
      i--;
    }
  }

  return colors;
}
