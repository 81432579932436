import * as React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

interface PropType {
  //   data: string;
  //   selected: boolean;
  icon: any;
  title: string;
  handleChange: any;
  handleDownload?: any;
  disable?: boolean
}

const HoverOverToolTips = (props: PropType) => {
  return (
    <Tooltip title={props.title} arrow>
      <IconButton
        aria-label="delete"
        size="medium"
        onClick={props.handleChange}
        disabled={props.disable}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};

export default HoverOverToolTips;
