import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { createExternalFolder } from "../../../api/MyLibrary/Links.api";
import { createFolder } from "../../../api/MyLibrary/MyFolders.api";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { notify } from "../../../utils/Notify";
import { usagelogApi } from "../../../api/usageReportApi";

interface Props {
  show: boolean;
  title: string;
  isExternalLinks: boolean;
}

const AddFolderModal = ({ show, title, isExternalLinks }: Props) => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [name, setName] = useState<string>("");
  const profileData = useAppSelector((state) => state.login);
  const user_ipv4_address = sessionStorage.getItem("user_ipv4_address") || null;
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const userMasterId = profileData.user_id ? profileData.user_id : null;
  const informaticscustomerId = profileData.informaticscustomer_id
    ? profileData.informaticscustomer_id
    : null;
  const consortiamasterId =
    profileData1 && profileData1.consortiamaster_id
      ? profileData1.consortiamaster_id
      : null;
  const sessionKey = profileData.session_key ? profileData.session_key : null;

  const createFolderHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => createFolder(obj),
    onSuccess: ({ data }) => {
      const res = data.data[0][0];
      if (res) {
        notify(res === "Folder Already Exists" ? "info" : "success", res);
        queryClient.invalidateQueries({ queryKey: ["GetMyFolders"] });
        dispatch(hideModal());
      }
    },
    onError: (err: any) => {
      console.log(err.response.data.message);
      if (err?.response?.data?.message === "Not Ok") {
        notify("warning", err?.response?.data.data[0][0]);
      }
    },
  });

  const createExternalFolderHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => createExternalFolder(obj),
    onSuccess: ({ data }) => {
      const res = data.data[0][0];

      if (res) {
        notify(
          res === "Folder Already Exists, Unable to create!!!"
            ? "info"
            : "success",
          res
        );
        queryClient.invalidateQueries({ queryKey: ["GetExternalFolders"] });
        dispatch(hideModal());
      }
    },
  });

  const handleAdd = () => {
    // Folder name should not be greater than 20 char
    if (!name) {
      notify("warning", "Folder Name can't be empty");
      return;
    }
    if (name && name.length > 20) {
      notify("warning", "Folder Name should be less than 20 character");
      return;
    }

    if (isExternalLinks) {
      const formData = new FormData();
      formData.append("folder_name", name);
      formData.append("profileuserId", selector.user_id);
      createExternalFolderHandler.mutate({ formData: formData });
    } else {
      usagelogApi(
        userMasterId,
        informaticscustomerId,
        consortiamasterId,
        114,
        null,
        null,
        null,
        null,
        null,
        null,
        user_ipv4_address,
        null,
        sessionKey,
        1,
        null
      );
      const formData = new FormData();
      formData.append("foldername", name);
      formData.append("profileuserid", selector.user_id);
      createFolderHandler.mutate({ formData: formData });
    }
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AddFolderModal-Title"
      aria-describedby="AddFolderModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>Please Enter Name</Text>
          <TextField
            size="small"
            placeholder="Enter Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFolderModal;
