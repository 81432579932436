import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function CheckMobOrWeb() {
  const location=useLocation();
  const linkRef :any= useRef();

  useEffect(() => {
      linkRef?.current?.click();
  }, [location]);
  
  
  return (
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" ,height:"100vh"}}>
      <>
        <p>Please download the mobile app</p>
        <p>
          Download the app:
        </p>
        <div style={{display:"flex"}}>

          <a href="https://play.google.com/store/apps/details?id=com.informatics.jgate">Android App</a> 
          <p>   |   </p>
          <a href="https://apps.apple.com/us/app/j-gate/id1670549103"> iOS App</a>
        </div>
          <span style={{ marginTop: "5%" }}>
            Do you have the mobile app?{" "}
            <a
            ref={linkRef}
              href={`intent://https://jgatenext.com${
                location?.pathname + location?.search
              }/#Intent;scheme=informaticsglobal;package=com.informatics.jgate;end`}
            >Click here
            </a>
          </span>
        
      </>
    </div>
  );
}

export default CheckMobOrWeb;