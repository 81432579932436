import axios from "axios";
import api from "./endpoints";
// const reportIssue = api.baseApi + `/api/report_product_issue/report_product_issue`
const reportIssue = api.baseApi + `/api/report_dead_link_issue_tracker/report_dead_link_issue_tracker`



export async function reportIssueApi( detailed_json : FormData) {
    // const csrf_token = sessionStorage.getItem('csrf_token')
    const csrf_token = sessionStorage.getItem('csrf_token')
    console.log('Issue Details', { detailed_json: JSON.stringify(detailed_json) })
   
    try {

        console.log('Try block triggered')
        console.log(JSON.stringify(detailed_json),'issue details')
        const res = await axios.post(reportIssue, detailed_json
            // {
            //     headers: {
            //          Accept: 'application/json',
            //          "Content-Type": "multipart/form-data",
            //         'X-CSRFToken': csrf_token,                 
            //     },
            // }
            );

         
        console.log('REPORT ISSUE RESPONSE', res)
        return res?.data

    } catch (error) {
        console.log("error", error)
    }

}