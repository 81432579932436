import { useEffect, useRef, useState } from "react";
import logo from "../assets/img/logo.png";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Typography } from "@mui/material";
import { Colors } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBrowserPage as updateBrowserPagePublisher,
  updatepublishercollection,
} from "../../store/slice/browserFilterSlicePublisher";
import { showIsProfileToast } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updatecollection } from "../../store/slice/browseFilterSlice";
import { useLocation } from "react-router-dom";

//Type
interface PropType {
  jsonFacets?: any;
  data?: any;
  setFilters: any;
  setMyPersonalLibraryPublisher: any;
}

const CollectionsComponentOfPublisher = (props: PropType) => {
  let { jsonFacets, data, setFilters, setMyPersonalLibraryPublisher } = props;
  const [collectionsValue, setcollectionsValue] = useState("");
  const dispatch = useDispatch();
  const [isProfileUser, setIsProfileUser] = useState(false);
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const enable_onos = useAppSelector(
    (state: any) => state.customer?.customerDetails?.is_enable_onos
  );
  const selectedAtoZCollection: any = useSelector(
    (state: any) => state.browseFilterSlice.collectionsValueJournal
  );
  const location = useLocation();
  const previousLocation = useRef(location);
  const userData = useAppSelector((state) => state.customer.customerDetails);
  const collectionFilters = useAppSelector(
    (state: any) => state.browserFilterSlicePublisher?.collectionsValuePublisher
  );
  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    console.log("USERUSER", userId);
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  useEffect(() => {
    if(selectedAtoZCollection == "My Library Collections" || selectedAtoZCollection == "Consortia Collections"){
      dispatch(updatepublishercollection(selectedAtoZCollection));
  
        setcollectionsValue(selectedAtoZCollection);
        generatedFilterString(selectedAtoZCollection);
    }
    else if(previousLocation?.current?.state?.from !== "BrowseJournalTableSearch/PackageArticle" || collectionFilters == ""){
      if (userData?.product_type === "1" || userData?.product_type === "1,7") {
        dispatch(updatepublishercollection("J-GateCollection"));

        setcollectionsValue("J-GateCollection");
        generatedFilterString("J-GateCollection");
      } else if (userData?.product_type === "7") {
        dispatch(updatepublishercollection("Consortia Collections"));

        setcollectionsValue("Consortia Collections");
        generatedFilterString("Consortia Collections");
      }
    }
  }, [userData]);
  const ids = useAppSelector(
    (state) => state.customer.customerDetails?.informaticscustomer_id
  );
  const customerData = localStorage.getItem("informaticscustomer_id") || ids;
  const showJgateCollection = customerDetails?.product_type?.includes("1");

  function handleClearAllCollections() {
    setcollectionsValue("");
    dispatch(updateBrowserPagePublisher(1));
    dispatch(updatepublishercollection(""));
    dispatch(updatecollection(""));
    generatedFilterString("");
  }

  const handleChangeCollections = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("event.target.value", event.target.value);
    if (collectionFilters === event.target.value) {
      setcollectionsValue("");
      dispatch(updatepublishercollection(""));
      dispatch(updatecollection(""));
      generatedFilterString("");
    } else {
      if (event.target.value == "My Library Collections") {
        dispatch(updateBrowserPagePublisher(1));
        setcollectionsValue((event.target as HTMLInputElement).value);
        dispatch(
          updatepublishercollection((event.target as HTMLInputElement).value)
        );
        dispatch(updatecollection((event.target as HTMLInputElement).value));
        generatedFilterString(event.target.value);
      }
      // else if (
      //   event.target.value == "My Library Collections" &&
      //   !isProfileUser
      // ) {
      //   showIsProfileToast(
      //     "There are no configured subjects nor subscription information for your library. Please contact your library administrator"
      //   );
      // }
      else if (
        event.target.value == "MyPersonalLibraryCollection" &&
        isProfileUser
      ) {
        dispatch(updateBrowserPagePublisher(1));
        setcollectionsValue((event.target as HTMLInputElement).value);
        dispatch(
          updatepublishercollection((event.target as HTMLInputElement).value)
        );
        dispatch(updatecollection((event.target as HTMLInputElement).value));
        generatedFilterString(event.target.value);
      } else if (
        event.target.value == "MyPersonalLibraryCollection" &&
        !isProfileUser
      ) {
        showIsProfileToast(
          "Please login as profile user to view this collection"
        );
      }
      if (
        event.target.value === "J-GateCollection" ||
        event.target.value === "Open Access Collections" ||
        event.target.value === "Consortia Collections" ||
        event.target.value === "ONOS Collections"
      ) {
        dispatch(updateBrowserPagePublisher(1));
        setcollectionsValue((event.target as HTMLInputElement).value);
        dispatch(
          updatepublishercollection((event.target as HTMLInputElement).value)
        );
        dispatch(updatecollection((event.target as HTMLInputElement).value));
        generatedFilterString(event.target.value);
      }
    }
  };

  //   const updateFilters: any = useSelector(
  //     (state: any) => state.browseFilterSlice
  //   );

  function generatedFilterString(checkedItem: any) {
    if (checkedItem === "MyPersonalLibraryCollection") {
      setMyPersonalLibraryPublisher(true);
    } else {
      setMyPersonalLibraryPublisher(false);
    }
    const collections =
      checkedItem == "My Library Collections"
        ? `&fq__fulltext_ACL=${customerDetails?.my_library_filter}`
        : checkedItem == "Open Access Collections"
        ? "&fq__fulltext_ACL=(0)"
        : checkedItem == "Consortia Collections"
        ? `&fq__fulltext_ACL=${userData?.consortia_filter}`
        : checkedItem == "ONOS Collections"
        ? "&fq__is_onos_resource=true"
        : "";

    setFilters(collections);
  }

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={{ paddingInline: "7%", paddingBlock: "4%" }}>
          <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
            <Typography sx={webStyle.titleHeader} gutterBottom>
              Collections
            </Typography>
            {/* <Button onClick={handleClearAllCollections}>Clear All</Button> */}
          </Box>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={collectionFilters}
            onChange={handleChangeCollections}
          >
            {showJgateCollection && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="J-GateCollection"
                control={<Radio />}
                label={`J-Gate Collection`}
              />
            )}
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="My Library Collections"
              control={<Radio />}
              label={`${
                userData?.customershortname != null
                  ? userData?.customershortname + " " + "Subscribed"
                  : "My Library Collection"
              }`}
            />
            {/* for -DF-1651 */}
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="MyPersonalLibraryCollection"
              control={<Radio />}
              label={`My Personal Library Collection`}
              disabled={true}
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="Open Access Collections"
              control={<Radio />}
              label="Open Access"
            />
            {consortiamaster_id && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="Consortia Collections"
                control={<Radio />}
                label={`Consortia Subscriptions`}
              />
            )}
            {enable_onos && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="ONOS Collections"
                control={<Radio />}
                label="ONOS Collections"
              />
            )}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default CollectionsComponentOfPublisher;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
