import http from "./httpService";

export async function getAllUserTypes(): Promise<any[]> {
  try {
    const { data: response } = await http.get(
      "/User/get_usertype_list/get_usertype_list",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("RESPONSE DATA", response.data[0]);

    return response.data[0];
  } catch (error) {
    console.log("err", error);
    throw error;
  }
}
