import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { GetMyFoldersData } from "../../../types/GetMyFolders.types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { useMutateUnshareMyFolder } from "../../../api/MyLibrary/MyFolders.api";
import { notify } from "../../../utils/Notify";
import { useGetUserProfile } from "../../../api/Profile.api";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  currentFolder: GetMyFoldersData;
}

const FolderMenu = ({ open, onClose, anchor, currentFolder }: Props) => {
  const dispatch = useAppDispatch();
  const unshareFolder = useMutateUnshareMyFolder();
  const userId = useAppSelector((state) => state.login.user_id);
  const { data: userData } = useGetUserProfile(userId, false);


  const shareModal = () => {
    if (currentFolder.folder_name === "") {
      notify("info", "Cannot share folder with no name");
      return;
    }
    if (!currentFolder.no_of_records) {
      notify("info", "Cannot share folder with no records");
      return;
    }

    dispatch(
      setModal({
        modalType: "SHARE_FOLDER",
        modalProps: {
          show: true,
          title: "Share Folder",
          folderId: currentFolder.userfolder_id,
          foldername: currentFolder.folder_name,
        },
      })
    );
    onClose();
  };

  const unshareModal = () => {
    dispatch(
      setModal({
        modalType: "UNSHARE_FOLDER",
        modalProps: {
          show: true,
          title: "Unshare Folder",
          folderId: currentFolder.userfolder_id,
          foldername: currentFolder.folder_name,
        },
      })
    );
    onClose();
  };

  const renameModal = () => {
    dispatch(
      setModal({
        modalType: "RENAME_FOLDER",
        modalProps: {
          show: true,
          title: "Rename Folder",
          folderId: currentFolder.userfolder_id,
          foldername: currentFolder.folder_name,
          isExternalLinks: false,
        },
      })
    );
    onClose();
  };
  const deleteModal = () => {
    dispatch(
      setModal({
        modalType: "DELETE_FOLDER",
        modalProps: {
          show: true,
          title: "Delete Folder",
          folderId: currentFolder.userfolder_id,
          itemName: currentFolder.folder_name,
          type: "internal",
        },
      })
    );
    onClose();
  };

  const unShareAndDelete = () => {
    dispatch(
      setModal({
        modalType: "DELETE_FOLDER",
        modalProps: {
          show: true,
          title: "Delete Folder",
          folderId: currentFolder.userfolder_id,
          itemName: currentFolder.folder_name,
          email: userData?.emailid,
          type: "externalDelete",
        },
      })
    );
    onClose();
  };

  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
          style: {
            transform: "translateY(-81px)"
        }
      }}
    >
      <MenuItem sx={{ minWidth: "150px" }} onClick={shareModal}>
        Share
      </MenuItem>
      <MenuItem onClick={unshareModal}>Unshare</MenuItem>
      <MenuItem onClick={renameModal}>Rename</MenuItem>
      <Divider />
      <MenuItem
        sx={{ color: "red" }}
        onClick={() =>
          currentFolder.shared_with_me ? unShareAndDelete() : deleteModal()
        }
      >
        Delete Folder
      </MenuItem>
    </Menu>
  );
};

export default FolderMenu;
