import {
  GetSharedToData,
  GetSharedToRoot,
} from "../../types/GetFoldersSharedTo.types";
import { IsArticleExist } from "../../types/GetMyFolders.types";
import http from "../httpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { queryClient } from "../../App";
import { useAppDispatch } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { notify } from "../../utils/Notify";
import api from "../endpoints";

export const getMyFolders = async (userId: string) =>
  http.get("/api/GetFolderdetailsforprofile/GetFolderdetailsforprofile", {
    params: {
      profileuserid: userId,
    },
  });

export const createFolder = async (obj: { formData: FormData }) =>
  http.post("/api/CreateProfileFolder/CreateProfileFolder", obj?.formData);

export const useMutateCreateMyFolder = () => {
  return useMutation(
    (obj: { formData: FormData; csrf: any }) =>
      http.post("/api/CreateProfileFolder/CreateProfileFolder", obj?.formData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["GetMyFolders"]);
        if (data.data.message === "Ok") {
          if (
            data.data.data &&
            Array.isArray(data.data.data) &&
            data.data.data.length
          )
            notify("success", data.data.data[0][0]);
        }
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const getFolderArticles = async (
  userId: string,
  folderId: number,
  page: number,
  pageSize: number,
  enableSearch: string,
  search?: string
) => {
 
  let params: any = {
    // titleKeywordsAbs: "*",
     // fq__active_flag: true,
    // search_tearm: "*",
    page: page,
    rows: pageSize,
    // fq__userfolder_id: folderId,
    userfolder_id: folderId,
  };


  if (enableSearch) {
    params = {
      ...params,
      // titleKeywordsAbs: search,
      search_tearm: search,
      // resource_name_fz: `"${search}"`,
      // resource_shortname_fz: `OR(${search})`,
    };
  }

  // return http.get("/api/indexing/solr/personalcore?facet_fields=folder_name", {
  //   params,
  // });
  return http.get("/api/get_folder_articles_by_folder_id/get_folder_articles_by_folder_id?", {
    params,
  });
};

export const shareFolder = async (obj: { formData: FormData }) =>
  http.post(
    "/api/SaveSharedFolderDetails/SaveSharedFolderDetails",
    obj?.formData
  );

export const useGetSharedToUsers = (userId: string, folderId: string) => {
  const params: any = {};
  if (userId) params["profileuser_id"] = userId;
  if (folderId) params["user_folder_id"] = folderId;

  return useQuery(
    ["GetSharedToUsers", userId, folderId],
    () =>
      http.get(
        "/api/get_shared_folder_details_by_folderid/get_shared_folder_details_by_folderid",
        {
          params,
        }
      ),
    {
      select: (res: AxiosResponse<GetSharedToRoot>): GetSharedToData[] => {
        return res.data.data[0][0];
      },
    }
  );
};

export const useMutateUnshareMyFolder = () => {
  const dispatch = useAppDispatch();
  return useMutation(
    (obj: { formData: FormData }) =>
      http.post(
        "/api/unshare_folder_details/unshare_folder_details",
        obj?.formData
      ),
    {
      onSuccess: () => {
        notify("success", "Folder Unshared Successfully");
        dispatch(hideModal());
        queryClient.invalidateQueries(["GetSharedToUsers"]);
        queryClient.invalidateQueries(["GetMyFolders"]);
        console.log("Success, Folder unshared");
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );
};

export const useMutateRenameMyFolder = () => {
  return useMutation(
    (obj: { formData: FormData; csrf: any }) =>
      http.post(
        "/api/rename_profile_folder/rename_profile_folder",
        obj?.formData
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["GetMyFolders"]);
        if (data.data.message === "Ok") {
          if (
            data.data.data &&
            Array.isArray(data.data.data) &&
            data.data.data.length
          )
            notify(
              data.data.data[0][0] === "Folder already exists"
                ? "error"
                : "success",
              data.data.data[0][0]
            );
        }
      },
      onError: (err: any) => {
        console.log(err, "err");
        if (err?.response?.data?.message === "Not Ok") {
          notify("error", err.response.data.data[0][0]);
        }
      },
    }
  );
};

export const deleteFolder = async (obj: { formData: FormData }) =>
  http.post("/api/DeleteProfileFolder/DeleteProfileFolder", obj?.formData);

export const removeArticles = async (obj: { formData: FormData }) =>
  http.post(
    "/api/remove_article_from_folder/remove_article_from_folder",
    obj?.formData
  );

// Add article to folder
export async function addArticleToFolder(formData: FormData) {
  try {
    const { data: response } = await http.post(
      `${api.baseApi}/api/AddArticletoFolder/AddArticletoFolder`,
      formData
    );

    if (response?.message === "Ok") return response.data[0][0];
  } catch (e) {
    console.log(e);
  }
}

export async function createFolderAndSave(formData: FormData) {
  try {
    const { data: response } = await http.post(
      `${api.baseApi}/api/create_folder_add_article/create_folder_add_article`,
      formData
    );

    if (response?.message === "Ok") return response.data[0][0];
  } catch (e: any) {
    console.log(e);
    if (e?.response?.data) {
      return e.response?.data;
    }
  }
}

// To check the article already saved in folders
export async function isArticleExist(
  formData: FormData
): Promise<IsArticleExist | undefined> {
  try {
    const { data: res } = await http.post(
      `${api.baseApi}/api/check_article_existance_in_folder/check_article_existance_in_folder`,
      formData
    );
    if (res?.message === "Ok") return res.data[0][0][0];
  } catch (e) {
    console.log(e);
  }
}

export async function checkMultipleArticleExistence(
  formData: FormData
): Promise<IsArticleExist | undefined> {
  try {
    const { data: res } = await http.post(
      `${api.baseApi}/api/check_article_existance_in_folder/check_article_existance_in_folder`,
      formData
    );
    if (res?.message === "Ok") return res.data[0][0];
  } catch (e) {
    console.log(e);
  }
}

export async function removeArticleFromFolder(formData: FormData) {
  try {
    const { data: res } = await http.post(
      "/api/remove_article_from_folder/remove_article_from_folder",
      formData
    );

    return res?.data;
  } catch (e) {
    console.log(e);
  }
}
