import api from "../api/endpoints";

const downloadFile = (content: string, fileName: string) => {
  const blob = new Blob([content], { type: "application/vnd.ms-excel" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName + ".xls");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up the DOM
};
//function to download file from front end without API calls 
export const downloadCitation = (array: any[], title: string) => {
  let rows = `
    <tr>
      <th>Index</th>
      <th>Title</th>
      <th>Year</th>
      <th>URL</th>
      <th>Source</th>
      <th>Authors</th>
    </tr>
  `;

  array.forEach((item, index) => {
    rows += `
      <tr>
        <td>${index + 1}</td>     
        <td>${item?.article_title || ""}</td>
        <td>${item?.year || ""}</td>
        <td>${item?.article_url || ""}</td>
        <td>${item?.source_name ?? ""}${
      item?.volume ? `, ${item.volume}` : ""
    }${item?.issue ? `(${item.issue})` : ""}</td>
        <td>${item?.authors || ""}</td>
        </tr>
    `;
  });

  const content = `
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <style>
        table, th, td {
          border: 1px solid black;
          border-collapse: collapse;
        }
        th, td {
          padding: 5px;
        }
      </style>
    </head>
    <body>
      <table>
        ${rows}
      </table>
    </body>
    </html>
  `;

  downloadFile(content, title);
};

export const DownloadFromApi = (openAlexId:any,count:any)=>{
  const win: Window | null = window.open(
    `${api.baseApi}/api/download_all_cited_records/download_all_cited_records?openalex_id=${openAlexId}&cited_count=${count}`
  );

}

export const downloadDatasetApi = (id:any) =>{
  const win:Window | null = window.open(`${api.baseApi}/api/download_all_datasets_records/download_all_datasets_records?article_id=${id}`)
}
