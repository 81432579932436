import http from "./httpService";
import { CustomerDetailsType } from "../types/interface";

export async function customerDetailsById(customerId: number): Promise<CustomerDetailsType | undefined> {
  try {
    const response = await http.get(
      `/api/CustomerDetailsbyID/CustomerDetailsbyID?customerId=${customerId}`
    );
    let data;
    if(response?.data && Array.isArray(response.data?.data)) {
        data = response.data?.data[0][0];
    }
    return data;

  } catch (err) {
    console.log(err);
  }
}
