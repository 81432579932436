export function validateSearchTerm(searchTerm: string): boolean {
  if (!searchTerm) return false;

  // check trailing spaces
  // if(/\s+$/.test(searchTerm)) return false;

  // check search term only contains numbers
  if (/^[0-9]+$/.test(searchTerm)) return false;

  // check the search term only contains special char
  if (/^[^a-zA-Z0-9\s]+$/.test(searchTerm)) return false;

  if (/[a-zA-Z]+/.test(searchTerm) === false) return false;

  return true;
}
