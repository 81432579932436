import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Tooltip } from "@mui/material";

const DownloadONOSButton = (props: any) => {
  const { setGridDisplay, GridDisplay, resource_id, setGridId, showGrid } =
    props;
  return (
    <Tooltip title={"ONOS access"}>
      <LockOpenIcon
        style={{ color: "#8934eb" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Tooltip>
  );
};

export default DownloadONOSButton;
