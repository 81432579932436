import React, { useRef } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setSearchHistoryAlert } from "../../api/searchHistoryapi";
import { searchHistoryAlertSuccess } from "../../store/slice/searchHistoryAlertSlice";
import { useEffect } from "react";
import { markFav, resourceSettingAlert } from "../../api/browseJournal";
import { alertsAndFav } from "../../store/slice/browseJournal";
import { SelectChangeEvent } from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import axios from "axios";
import api from "../../api/endpoints";
import RSSAlert_Save from "./RSSAlert_Save";
import { useAppSelector } from "../../store/hooks";
import { updateAlertSettingData } from "../../store/slice/searchSlice";
import { usagelogApi } from "../../api/usageReportApi";
import { notify } from "../../utils/Notify";
import { useLocation } from "react-router-dom";
interface Props {
  openViewModal: any;
  handleCloseViewModal: any;
  id: any;
  setJournalResults?: any;
  setNotification_set?: any;
  alerts: any;
  setMessage: any;
  basicSearch?: any;
  notification?: any;
}

const RSSAlert = ({
  openViewModal,
  handleCloseViewModal,
  id,
  setJournalResults,
  setNotification_set,
  alerts,
  setMessage,
  basicSearch,
  notification,
}: Props) => {
  const urlLink = useRef("");
  let userId = sessionStorage.getItem("user_id");
  let urlAlert;
  const [limit, setLimit] = useState<any>("Daily");
  const alertUpdate: any = useSelector(
    (state: any) => state.browseJournalSearch.browserResult
  );

  const loginDetails: any = useSelector((state: any) => state.login);

  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [rssChecked, setRssChecked] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");

  const [alertTopic, setAlertTopic] = useState("");
  const [rssSavedSuccessfully, setrssSavedSuccessfully] = useState(false);
  const search_log_ids = id;
  const [alertFrequency, setAlertFrequency] = useState("");
  const [alertId, setAlertId] = useState([1]);
  const [open, setOpen] = useState(false);
  const { user_id } = useSelector((state: any) => state.login);
  const { csrf_token } = useSelector((state: any) => state.login);
  const profileData = useAppSelector((state) => state.login);
  const location = useLocation();
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts?.alert_type === 1) {
      setEmailChecked(true);
    } else if (alerts?.alert_type === 2) {
      setRssChecked(true);
    } else if (alerts?.alert_type === 3) {
      setRssChecked(true);
      setEmailChecked(true);
    }
    return () => {
      setRssChecked(false);
      setEmailChecked(false);
    };
  }, [alerts]);

  const handleSavedResponse = () => {
    setrssSavedSuccessfully(!rssSavedSuccessfully);
  };
  const [age, setAge] = React.useState("");

  const handleChangeFrequency = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
  };
  let searchResults: any = useAppSelector(
    (state) => state.searchReducer.alertSettingData
  );
  let searchResultss: any = useAppSelector((state) => state.searchReducer);

  const handleSearchHistoryAlert = async (e: any) => {
    e.preventDefault();
    let alertId: any;
    if (
      location.pathname == "/BrowseJournalTableSearch" ||
      location.pathname == "/BrowseJournalTableSearch/PackageArticle"
    ) {
      if (!emailChecked && !rssChecked && alerts?.alert_type == 0) {
        notify("info", "Select any one alert type to set alert");
        return;
      }
    } else if (
      location.pathname == "/BrowseJournalTableSearch/BrowseJournalSearch"
    ) {
      if (!emailChecked && !rssChecked && notification == false) {
        notify("info", "Select any one alert type to set alert");
        return;
      }
    }
    if (rssChecked && emailChecked) {
      alertId = 3;
    } else if (emailChecked) {
      alertId = 1;
    } else if (rssChecked) {
      alertId = 2;
    } else if (!emailChecked && !rssChecked) {
      alertId = 0;
    }
    // console.log(alertId);

    let mailFrequency;
    if (limit == "Daily") {
      mailFrequency = 1;
    } else if (limit == "Weekly") {
      mailFrequency = 2;
    } else if (limit == "Bi-weekly") {
      mailFrequency = 3;
    } else if (limit == "Monthly") {
      mailFrequency = 4;
    } else if (limit === "Quarterly") {
      mailFrequency = 5;
    }

    //console.log("id.resourcemaster_id", id.resourcemaster_id);
    // id.resourcemaster_id == 8 ? logAlertUsageData(104, 105) : null;
    // id.resourcemaster_id == 24 ? logAlertUsageData(106, 107) : null;
    // id.resourcemaster_id == 4 ? logAlertUsageData(108, 109) : null;
    const apiFormData = {
      data: [
        {
          usermaster_id: loginDetails.user_id || userId,
          resourcemaster_id: id.resourcemaster_id,
          resourcetype_id: id.resource_type,
          alerttype_id: alertId,
          // mail_frequency: mailFrequency,
        },
      ],
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(apiFormData));

    try {
      const response = await axios.post(
        api.baseApi + `/api/set_alert_for_resource/set_alert_for_resource`,
        formData
      );
      handleCloseViewModal();

      if (
        response.data.data[0][0].status == "New Alert created successfully!" ||
        response.data.data[0][0].status == "Alert updated successfully!"
      ) {
        // if (alertId !== undefined) {
        //   console.log("logging function", alertId);
        //   setNotification_set && setNotification_set(true);
        // } else {
        //   setNotification_set(false);
        // }
        let successMsg = response.data.data[0][0].status;
        if (successMsg) notify("success", successMsg);

        if (setNotification_set !== undefined) {
          if (alertId == undefined || alertId == 0) {
            setNotification_set(false);
          } else if (alertId == 1 || alertId == 2 || alertId == 3) {
            setNotification_set(true);
          }
        }

        let resourceIdList: string[] = [];

        setJournalResults();
        if (basicSearch?.from === "basicSearch") {
          let x = searchResults?.map((item: any) => {
            if (item.resource_id == id.resourcemaster_id) {
              return {
                ...item,
                notification_set: !item.notification_set,
                alert_type: alertId,
              };
            }
            return item;
          });
          dispatch(updateAlertSettingData(x));
        }
      }

      if (response.data.data[0][0].Message === "Limit exceed 100") {
        setMessage({
          messages:
            "Reached maximum alerts, Please delete the existing to continue",
          open: true,
        });

        setEmailChecked(false);
        setRssChecked(false);
      } else if (response.data.data[0][0].rssurl !== null) {
        handleSavedResponse();

        urlLink.current = response.data.data[0][0].rssurl;
      }
    } catch (err) {
      console.log("error of", err);
    }
  };

  const handleEmailCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailChecked(event.target.checked);
  };

  const handleRSSCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRssChecked(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={openViewModal}
        onClose={handleCloseViewModal}
        id={id}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign="left"
            fontFamily={"Lora"}
            fontWeight={700}
            fontSize={28}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Email/RSS Alert
          </Typography>

          <IconButton onClick={handleCloseViewModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            fontSize={16}
            fontWeight={500}
            textAlign="left"
            color="#212121"
          >
            {id?.resource_name_tk}
          </Typography>
          {/* <Select
            onChange={handleChangeFrequency}
            sx={{ width: "300px" }}
            // displayEmpty
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={limit}
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Bi-weekly">Bi-weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
          </Select> */}

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailChecked}
                  onChange={handleEmailCheckboxChange}
                />
              }
              label="Email Alerts"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rssChecked}
                  onChange={handleRSSCheckboxChange}
                />
              }
              label="RSS Alerts"
            />
          </FormGroup>

          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#7852FB",
                },

                textAlign: "left",
                backgroundColor: "#7852FB",
                color: " #FAF8F5",
                textTransform: "capitalize",
                borderRadius: "8px",
                fontSize: "16px",
                width: "192px",
                height: "51px",
              }}
              onClick={handleSearchHistoryAlert}
              // onClick={openViewModal}
            >
              Save
            </Button>

            <Button
              sx={{
                textAlign: "left",
                left: "42px",
                fontWeight: 500,
                fontSize: "18px",
                backgroundColor: "#FFFFFF",
                color: " #111928",
                textTransform: "capitalize",
                borderRadius: "8px",
                height: "51px",
              }}
              onClick={handleCloseViewModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <RSSAlert_Save
        urlLink={urlLink.current}
        openViewModal={rssSavedSuccessfully}
        handleCloseViewModal={handleSavedResponse}
      ></RSSAlert_Save>
    </>
  );
};

export default RSSAlert;
