import { styled, SxProps } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Colors } from "../../../utils/constants";

const searchType: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

const searchTypeOptions: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

const query: SxProps = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  p: 2,
  borderRadius: 1,
  border: `1px solid ${Colors.gray_400}`,
};

const titleQuery: SxProps = {
  fontSize: "1.4rem",
  fontWeight: 600,
  mb: 2,
  mt: 4,
};

export const searchStyles = {
  searchType,
  searchTypeOptions,
  query,
  titleQuery,
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
