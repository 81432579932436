
import { createSlice } from "@reduxjs/toolkit";
import { LinkResolverType } from "../../api/linkResolverApi";
import { SaverArticlesType } from "../../types/interface";
import { GetAlertSettingsData } from "../../types/GetAlertSettings.types";

export interface InitialstateType {
  authorsearchResult: any;
  searchTerm: string[];
  page: number;
  rows: number;
  authorname: string[];
  firstname: string[];
  lastname: string[];
  dataType: number[];
  subjectType: number[];
  subjectName: string[];
  markedResult: any;
  markedArticleId: string[];
  fullText: boolean;
  newSearchStatus: boolean;
  subjectFilter: string[];
  institutions: string[];
  preferenceStatus:boolean;
  sort: string;
  selectedTag:string;
  abstractAuthorStatus:boolean;
  authorInitial: any;
  linkToDisplay?: LinkResolverType[] | null;
  isSettled: boolean;
  isAuthorAnalytics:boolean;
  enabledTags:any;
  buttonStatus:boolean;
  savedArticles?: SaverArticlesType[];
  tempSearchTerm: string[];
  abstractDrawer:boolean;
  isAuthorResult:boolean;
  isInitialResult:boolean;
  alertSettingData: GetAlertSettingsData[],
  tempTags: any[];
  noResult: boolean,


}

const initialState: InitialstateType = {
  authorsearchResult: {},
  searchTerm: [],
  page: 1,
  rows: 10,
  authorname: [],
  firstname: [],
  lastname: [],
  dataType: [],
  subjectType: [],
  subjectName: ["All"],
  markedResult: [],
  markedArticleId: [],
  fullText: true,
  newSearchStatus: false,
  subjectFilter: [],
  institutions: [],
  preferenceStatus:false,
  sort: "asc",
  selectedTag:"",
  abstractAuthorStatus:false,
  authorInitial: '',
  linkToDisplay: null,
  isSettled: false,
  isAuthorAnalytics:false,
  enabledTags:[],
  buttonStatus:false,
  savedArticles: [],
  tempSearchTerm:[],
  abstractDrawer:false,
  isAuthorResult: false,
  isInitialResult:false,
  alertSettingData: [],
  tempTags: [],
  noResult: false,

};

export const authorsearchSlice = createSlice({
  name: "authorsearchResult",
  initialState,
  reducers: {
    updateAuthorSearchResults(state, { payload }) {
      state.authorsearchResult = payload;
    },
    updateAuthorSearchTerm(state, { payload }) {
      if (Array.isArray(payload)) {
        state.searchTerm = payload;
        return;
      }
      if (state.searchTerm.includes(payload)) {
        state.searchTerm = state.searchTerm?.filter(data => data !== payload)
      }

      if (state.searchTerm.length) {
        state.searchTerm = [...state.searchTerm, payload]
      } else {
        state.searchTerm = [payload]
      }
    },
    updateTempSearchTerm(state, { payload }) {
      state.tempSearchTerm = payload;
    },

    clearAuthorSearchTerm(state) {
      state.searchTerm = [];
    },
    sliceAuthorSearchTerm(state) {
      state.searchTerm.splice(1)
    },
    removeAuthorSearchTerm(state, { payload }) {
      console.log('Triggered removeauthorsearchterm')
    
    state.searchTerm = state.searchTerm.filter(data => data !== payload);
    },





    updateAuthorPage(state, { payload }) {
      state.page = payload;
    },
    updateAuthorRow(state, { payload }) {
      state.rows = payload;
    },

    updateAuthorName(state, { payload }) {
      state.authorname = payload;
    },
    updateAuthorFirstName(state, { payload }) {
      state.firstname = payload;
    },
    updateAuthorLastName(state, { payload }) {
      state.lastname = payload;
    },



    updateAuthorFullName(state, { payload }) {
      state.firstname = payload;
      state.lastname = payload;
    },
    updateAuthorSortResults(state, { payload }) {
      state.authorsearchResult = payload;
    },

    updateAuthorSortDataResults(state, { payload }) {
      state.authorsearchResult = payload;
    },





    updateAuthorDataType(state, { payload }) {
      state.dataType = payload;
    },
    updateAuthorSubjectType(state, { payload }) {
      state.subjectType = payload;
    },

    updateAuthorSubjectName(state, { payload }) {
      if (Array.isArray(payload) && !payload.length) {
        state.subjectName = ["All"];
      } else {
        state.subjectName = payload;
      }
    },
    updateAuthorMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
    updateAuthorMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    clearAuthorMarkedResult(state) {
      state.markedArticleId = [];
      state.markedResult = [];
      state.authorname = [];
    },
    updateAuthorFullText(state, { payload }) {
      state.fullText = payload;
    },
    clearAuthorDataTypeFilters(state) {
      state.dataType = [];
      state.subjectType = [];
      state.subjectName = []
      state.authorname = [];
    },
    updateAuthorNewSearchStatus(state, { payload }) {
      state.newSearchStatus = payload;
    },

    // Filters
    updateSubjectFilter(state, { payload }) {
      state.subjectFilter = payload;
    },
    updateInstitutionFilter(state, { payload }) {
      state.institutions = payload;
    },
    clearAllFilters(state) {
      state.subjectFilter = [];
      state.institutions = [];
    },
    updatePreferenceState(state, { payload }) {
      state.preferenceStatus = payload;
    },
    updateAuthorSort(state, { payload }) {
      state.sort = payload;
    },
    updateAuthorSelectedTag(state, { payload }) {
      state.selectedTag = payload;
    },
    clearAuthorSelectedTag(state) {
      state.selectedTag = "";
    },
    updateAbastractAuthor(state, { payload }) {
      state.abstractAuthorStatus = payload;
    },
    updateAuthorInitial(state, { payload }) {
      state.authorInitial = payload;
    },
    updateAuthorLinkToDisplay(state, { payload }) {
      state.linkToDisplay = payload;
    },
    updateIsAuthorSettled(state, {payload}) {
      state.isSettled = payload;
    },
    updateIsAuthorAnalytics(state, {payload}) {
      state.isAuthorAnalytics = payload;
    },
    updateEnabledTagList(state, {payload}) {
      state.enabledTags = payload;
    },
    updateButtonStatus(state, {payload}) {
      state.buttonStatus = payload;
    },
    updateAuthorSavedArticles(state, { payload }) {
      state.savedArticles = payload;
    },
    updateAbstractDrawer(state, { payload }) {
      state.abstractDrawer = payload;
    },
    updateIsAuthorResult(state, {payload}){
      state.isAuthorResult = payload;
    },
    updateIsInitialResult(state, {payload}){
      state.isInitialResult = payload;
    },
    updateAuthorAlertSettingData(state, { payload }) {
      state.alertSettingData = payload;
    },
    updateTempTags(state, { payload }) {
       state.tempTags = payload;
     },
     updateNoResult(state, {payload}){
      state.noResult = payload;
     },
      
      
  },
});

export const { updateAuthorSearchResults,
  updateAuthorSearchTerm,
  clearAuthorSearchTerm,
  removeAuthorSearchTerm,
  updateAuthorPage,
  updateAuthorRow,
  sliceAuthorSearchTerm,
  updateAuthorName,
  updateAuthorFullName,
  updateAuthorFirstName,
  updateAuthorLastName,

  updateAuthorDataType,
  updateAuthorSubjectType,
  updateAuthorSubjectName,
  updateAuthorMarkedResult,
  updateAuthorMarkedArticleId,
  clearAuthorMarkedResult,
  updateAuthorFullText,
  clearAuthorDataTypeFilters,
  updateAuthorNewSearchStatus,
  updateSubjectFilter,
  updateInstitutionFilter,
  clearAllFilters,
  updatePreferenceState,
  updateAuthorSort,
  updateAuthorSelectedTag,
  updateAbastractAuthor,
  updateAuthorInitial,
  updateAuthorLinkToDisplay,
  updateIsAuthorSettled,
  updateIsAuthorAnalytics,
  updateEnabledTagList,
  updateButtonStatus,
  updateAuthorSavedArticles,
  updateTempSearchTerm,
  updateAbstractDrawer,
  updateIsAuthorResult,
  updateIsInitialResult,
  updateAuthorAlertSettingData,
  updateTempTags,
  updateNoResult,
  clearAuthorSelectedTag
} = authorsearchSlice.actions;
export default authorsearchSlice.reducer;
