import { useEffect, useState } from "react";
import { useMutateDeleteMySubjects } from "../../../api/Preferences/Subjects.api";
import {  useAppSelector } from "../../../store/hooks";
import { GetMyPreference2Detail, GetMyPreferenceData } from "../../../types/GetMySubjects.types";
import { Box, Button, Chip } from "@mui/material";
import { prefernecesStyles as styles } from "../../../screens/Preferences/Preferences.styles";
import FilterSubjectModal from "./AddSubject.modal";
import { Add } from "@mui/icons-material";
import Text from "@mui/material/Typography";

interface Props {
  l1l2Data: GetMyPreferenceData;
}

export default function SubjectPreferenceTab({ l1l2Data }: Props) {
  const selector = useAppSelector((state) => state.login);

  const deleteSubjectHandler = useMutateDeleteMySubjects();
  const [isOpen, setIsOpen] = useState(false)

  // Chip data
  const [myL2Subjects, setMyL2Subjects] = useState<GetMyPreference2Detail[]>(
    []
  );

  useEffect(() => {
    if (l1l2Data) {
      let array: any[] = [];
      l1l2Data.Subjects.forEach((l1) => {
        l1.subject2_details.forEach((l2) => {
          array.push(l2);
        });
      });
      setMyL2Subjects(() => array);
    }
  }, [l1l2Data]);

  const handleAddSubjectsModal = async () => {
    setIsOpen(true)
  };

  const handleSubjectDelete = (id: number) => {
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("subject_level2_ids", id.toString());
    deleteSubjectHandler.mutate({ formData });
  };

  function clearAllSubject() {
    let ids = myL2Subjects?.map((obj) => obj?.subject_id2);
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("subject_level2_ids", ids.toString());
    deleteSubjectHandler.mutate({ formData });
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Button sx={styles.addSubjects} onClick={handleAddSubjectsModal}>
          <Add /> <Text> Add Subjects</Text>
        </Button>
      </Box>

      <FilterSubjectModal show={isOpen} mySubjects={myL2Subjects} onClose={() => setIsOpen(false)}  />

      {/* Display Chips */}
      {myL2Subjects.length > 0 ? (
        <Box sx={styles.chipsWrapper}>
          <Button sx={styles.chipClearAll} onClick={clearAllSubject}>
            Clear All
          </Button>

          {myL2Subjects
            .filter((y) => y.is_favorite)
            ?.map((x, i) => (
              <Chip
                key={i}
                label={x.subject_name2}
                variant="outlined"
                onDelete={() => handleSubjectDelete(x.subject_id2)}
              />
            ))}
        </Box>
      ) : (
        <p>Add a Subject</p>
      )}
    </>
  )
}
