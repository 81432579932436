import type { SxProps } from "@mui/material";

const root: SxProps = {
  width: "100%",
  px: "48px",
  py: 2,

  gap: 3,
};

const heading: SxProps = {
  fontSize: "24px",
  fontWeight: 700,
};

const historyTable: SxProps = {
  width: "100%",
  gap: 1,
};

const tableHead: SxProps = {
  height: "64px",
  background: "#F9FAFB",
  //   maxWidth: "1460px",
  //   border: "1px solid #D1D5DB",
  borderRadius: "8px 8px 0px 0px",
};

const actionBar: SxProps = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 2,
};

const card: SxProps = {
  borderRadius: 1,
  p: 2,

  display: "flex",
  flexDirection: "column",
  gap: 2,
};

const fieldArray: SxProps = {};

const BorderStyles  = { borderRight: '1px solid #ddd'};

export const searchHistoryStyles = {
  root,
  heading,
  historyTable,
  tableHead,
  actionBar,
  card,
  fieldArray,
  BorderStyles
};
