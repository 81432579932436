import React from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import HelpIcon from "@mui/icons-material/Help";
import { Colors } from "../../utils/constants";

interface P {
  handlePopUp: () => void;
  state: boolean;
  dialogueData: any;
  screen: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const AbstractDialog: React.FC<P> = ({
  handlePopUp,
  state,
  dialogueData,
  screen,
}) => {
  return (
    <div>
      <Box sx={screen ? webStyle.fullScreen : webStyle.drawer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" alignSelf={"center"}>
            Scite_
          </Typography>
          <Button
            variant="outlined"
            sx={{ paddingLeft: "4%", borderRadius: 3 }}
          >
            View Citations
          </Button>
        </Box>

        <Box>
          <Typography>Smart Citations</Typography>
          <Box
            style={{ display: "flex", flexDirection: "row", marginTop: "4%" }}
          >
            <TextSnippetIcon />
            <Typography pl={"1%"} pr={"1%"}>
              {dialogueData.sjr_rank}
            </Typography>
            <Typography>Ranking</Typography>
          </Box>
          <Box
            style={{ display: "flex", flexDirection: "row", marginTop: "4%" }}
          >
            <CheckCircleOutlineIcon color="secondary" />
            <Typography pl={"1%"} pr={"1%"}>
              2
            </Typography>
            <Typography>Supporting</Typography>
          </Box>
          <Box
            style={{ display: "flex", flexDirection: "row", marginTop: "4%" }}
          >
            <FormatAlignRightIcon />

            <Typography pl={"1%"} pr={"1%"}>
              3
            </Typography>
            <Typography>Source Index</Typography>
          </Box>
          <Box
            style={{ display: "flex", flexDirection: "row", marginTop: "4%" }}
          >
            <HelpIcon color="primary" />

            <Typography pl={"1%"} pr={"1%"}>
              4
            </Typography>
            <Typography>Source Index</Typography>
          </Box>
          <Typography sx={{ marginTop: "4%" }}>
            See how this article has been cited at scite.ai scite shows how a
            scientific paper has been cited by providing the context of the
            citation, a classification describing whether it supports, mentions,
            or contrasts the cited claim, and a label indicating in which
            section the citation was made.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default AbstractDialog;

const webStyle = {
  fullScreen: {
    width: "40%",
    backgroundColor: Colors.white,
    marginLeft: "2%",
    zIndex: 100,
    position: "absolute",
    padding: "2%",
    borderWidth: 1,
    borderColor: Colors.black,
    borderRadius: 2,
    marginTop: "6%",
    boxShadow: 1,
  },
  drawer: {
    width: "40%",
    backgroundColor: Colors.white,
    marginLeft: "22%",
    zIndex: 100,
    position: "absolute",
    padding: "2%",
    borderWidth: 1,
    borderColor: Colors.black,
    borderRadius: 2,
    marginTop: "6%",
    boxShadow: 1,
  },
};
