import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import SignoutIcon from "../../assets/img/log-out.png";
import { useDispatch } from "react-redux";
import { clearCustomerDetails } from "../../store/slice/customerDetails";
import { logout } from "../../store/slice/loginSlice";
// import { useMutateLogout } from "../../api/Auth/Logout.api";
import { userLogout } from "../../api/userLogoutApi";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { setSearchKeyword } from "../../store/slice/browseJournal";
import { reset } from "../../store/slice/searchSlice";
import { setModal } from "../../store/slice/modalSlice";
import { resetPasswordApi } from "../../api/userApi";
import { removeSessionCookie } from "../../utils/sessionCookie";

interface MenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  closeMenu: () => void;
  profileDetail?: any;
}

const ProfileMenu = ({
  open,
  anchorEl,
  closeMenu,
  profileDetail,
}: MenuProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isProfileUser, setIsProfileUser] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [passsword_token, setPasssword_token] = useState("");

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const allowProfileSignup = customerDetails?.allow_profile;
  const profileStatus = sessionStorage.getItem("isProfileUser");
  // const logoutHandler = useMutateLogout();

  // const handleLogout = async () => {
  //   logoutHandler.mutate();

  //   if (logoutHandler.isSuccess) {
  //     dispatch(logout(false)); // error:false
  //     dispatch(clearCustomerDetails());
  //     navigate("/");
  //   } else {
  //     dispatch(logout(true)); // error:true
  //   }
  // };

  useEffect(() => {
    fetchDetails();
    changeuserPassword();
  }, [profileDetail?.email, open]);
  useEffect(() => {
    fetchDetails();
  }, []);

  function fetchDetails() {
    if (!profileDetail) return;
    if (profileDetail?.user_type === 2) {
      sessionStorage.setItem("isProfileUser", "true");
      setIsProfileUser(true);
    } else {
      sessionStorage.setItem("isProfileUser", "false");
    }
  }

  const changeuserPassword = async () => {
    const userID = sessionStorage.getItem("user_id");
    setUser_id(userID || "");
  };

  const handleLogout = async () => {
    try {
      dispatch(
        setSearchKeyword({
          searchType: "",
          words: "",
        })
      );
      dispatch(reset());
      if (sessionStorage.getItem("isIPLogin") === "true") {
        navigate("/thankYou");
        const querylogout: any = await userLogout();
        dispatch(logout(querylogout));

        dispatch(clearCustomerDetails());
        sessionStorage.clear();
        sessionStorage.clear();
        removeSessionCookie("session_key");
        removeSessionCookie("user_id");
        removeSessionCookie("csrf_token");
        removeSessionCookie("informaticscustomer_id");
        return;
      }
      const querylogout: any = await userLogout();
      dispatch(logout(querylogout));
      dispatch(clearCustomerDetails());
      sessionStorage.clear();
      sessionStorage.clear();
      removeSessionCookie("session_key");
      removeSessionCookie("user_id");
      removeSessionCookie("csrf_token");
      removeSessionCookie("informaticscustomer_id");
      navigate("/login");
    } catch (error) {
      console.log("Error State", error);
    }
  };

  function createProfile() {
    navigate("/signup");
  }

  function onLoginClick() {
    closeMenu();
    dispatch(
      setModal({
        modalType: "SHOW_LOGIN",
        modalProps: {
          open: true,
          isProfileUser: profileStatus,
        },
      })
    );
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={closeMenu}
      sx={{ top: "12px" }}
      disableScrollLock
    >
      {isProfileUser && (
        <>
          <MenuItem
            onClick={() => {
              navigate("/profile");
              closeMenu();
            }}
            sx={{ gap: 1, minWidth: "200px" }}
          >
            <PersonOutlineOutlinedIcon />
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/preferences");
              closeMenu();
            }}
            sx={{ gap: 1, minWidth: "200px" }}
          >
            <PersonOutlineOutlinedIcon />
            Preferences
          </MenuItem>

          <MenuItem
            sx={{ gap: 1 }}
            onClick={() => {
              navigate("/my-library/folders");
              closeMenu();
            }}
          >
            <FolderOutlinedIcon />
            Personal Library
          </MenuItem>

          <MenuItem
            sx={{ gap: 1 }}
            onClick={() => {
              navigate(`/profilechangepassword/${user_id}`);
              closeMenu();
            }}
          >
            <PersonOutlineOutlinedIcon />
            Change Password
          </MenuItem>
        </>
      )}
      {(allowProfileSignup && profileStatus !== "true") ||
      customerDetails?.customer_id === 0 ? (
        <MenuItem onClick={onLoginClick} sx={{ gap: 1 }}>
          <PersonOutlineOutlinedIcon />
          Login
        </MenuItem>
      ) : null}

      {(allowProfileSignup && !isProfileUser) ||
      customerDetails?.customer_id === 0 ? (
        <MenuItem onClick={createProfile} sx={{ gap: 1 }}>
          <PersonOutlineOutlinedIcon />
          Register
        </MenuItem>
      ) : null}
      {customerDetails?.customer_id !== 0 ? (
        <MenuItem onClick={handleLogout} sx={{ gap: 1, color: "#E02424" }}>
          <img src={SignoutIcon} alt="sign out form jgate" />
          Logout
        </MenuItem>
      ) : null}
    </Menu>
  );
};

export default ProfileMenu;
