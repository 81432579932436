import Text from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alertStyles as styles } from "./Alerts.style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { useGetThesisSubjects } from "../../../api/MyLibrary/Thesis.api";
import { useEffect, useState } from "react";
import {
  GetSubjectAlertData,
  GetUserL2L3Subject3Detail,
} from "../../../types/GetUserL2L3Subjects";
import { Button, IconButton, Paper, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Colors } from "../../../utils/constants";
import MoreIcon from "@mui/icons-material/MoreVert";
import RSSIcon from "@mui/icons-material/RssFeed";

import ThesisMenu from "./ThesisMenu";
import AlertModal from "./AlertModal";

const RESOURCE_ID = "4";

const Thesis = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [currentAlert, setCurrentAlert] = useState<GetSubjectAlertData | null>(
    null
  );

  const [selectedL3, setSelectedL3] = useState<GetUserL2L3Subject3Detail[]>([]);

  const { data: thesisSubjects, refetch } = useGetThesisSubjects(
    selector.user_id
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, updateSelectedRow] = useState();
  const [openRss, setOpenRss] = useState(false);
  const [rssData, setRssData] = useState();
  const [subjectId, setSubjectId] = useState("");
  const [favSubId, setFavSubId] = useState("");
  const [alertTopic, setAlertTopic] = useState("");
  const [freqData, setFreqData] = useState("");
  const [tempData, setTempData] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (thesisSubjects) {
      let selected: GetUserL2L3Subject3Detail[] = [];

      thesisSubjects.forEach((l2) =>
        l2.subject3_details.forEach((l3) => {
          if (l3.is_favorite) selected.push(l3);
        })
      );

      setSelectedL3(() => selected);
    }
  }, [thesisSubjects]);

  function closeRssModal() {
    setOpenRss(false);
  }

  function openMenu(event: React.MouseEvent<HTMLElement>, row: any) {
    setAnchorEl(event.currentTarget);
    updateSelectedRow(row);
  }

  const closeMenu = () => {
    setAnchorEl(null);
  };

  function openRssMenu(row: any) {
    setOpenRss(true);
    setRssData(row);
    setSubjectId(row?.subject_id3);
    setCurrentAlert(row);
    setTempData(row.alert_type);
    setAlertTopic(row.alert_topic);
    setFreqData(row.frequency);

    setFavSubId(row?.fav_subject_id);
  }

  const handleAddSubjectsModal = () =>
    dispatch(
      setModal({
        modalType: "ADD_AV_THESIS",
        modalProps: {
          show: true,
          title: "Subjects",
          type: "thesis",
          l2Subjects: thesisSubjects,
          selectedL3: selectedL3,
        },
      })
    );

  return (
    <>
      <Button
        variant="outlined"
        sx={styles.addButton}
        onClick={handleAddSubjectsModal}
      >
        {/* <Add /> */}
        Add Subjects
      </Button>

      <TableContainer component={Paper} sx={{ marginTop: "2%" }}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>#</TableCell>
              <TableCell sx={{ textAlign: "left" }}>Subjects</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedL3?.map((subject: any, index) => (
              <TableRow
                key={subject.subject_id3}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <Text fontSize="0.875rem">{index + 1}</Text>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ textAlign: "left" }}
                >
                  <Text fontSize="0.875rem">{subject.subject_name3}</Text>
                </TableCell>

                <TableCell component="th" scope="row" sx={styles.addManageCell}>
                  <Text fontSize="0.875rem">&nbsp;</Text>
                  <Box>
                    <Tooltip
                      title={subject?.alert_type ? "Modify Alert" : "Set Alert"}
                    >
                      <IconButton
                        aria-label="rss"
                        sx={{
                          "&:hover": {
                            color: "#F48120",
                          },
                          color: subject?.alert_type ? "#F48120" : "",
                        }}
                        onClick={() => openRssMenu(subject)}
                      >
                        <RSSIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={(e) => openMenu(e, subject)}>
                      <MoreIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ThesisMenu
          open={open}
          onClose={closeMenu}
          anchor={anchorEl}
          currentAlert={selectedRow}
        />
        {currentAlert && (
          <AlertModal
            show={openRss}
            onClose={closeRssModal}
            alertData={rssData}
            subjectID={subjectId}
            resourceId={RESOURCE_ID}
            currentAlert={currentAlert}
            refetch={refetch}
            alertValue={tempData}
            alertTopicData={alertTopic}
            frequencyData={freqData}
            favSubId={favSubId}
          />
        )}
      </TableContainer>
    </>
  );
};

export default Thesis;
