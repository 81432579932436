import http from "../httpService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  GetCustomerByIDRoot,
  GetCustomerByIDData,
} from "../../types/GetCustomerByID.types";

export const useGetCustomerByID = (customerId: string) => {
  const params: any = {};
  if (customerId) params["customerId"] = customerId;

  return useQuery(
    ["GetCustomerByID", customerId],
    () =>
      http.get("/api/CustomerDetailsbyID/CustomerDetailsbyID", {
        params,
      }),
    {
      select: (
        res: AxiosResponse<GetCustomerByIDRoot>
      ): GetCustomerByIDData => {
        return res.data.data[0][0];
      },
    }
  );
};

export const json_facet = {
  Collections_OpenAccess: {
    type: "query",
    q: "(fulltext_ACL:0 OR ishybridopenaccess:true)",
  },
  data_type: { type: "terms", field: "data_type", limit: 30 },
  subjects_name_l3: {
    type: "terms",
    field: "subjects_name_l3",
    limit: 100,
  },
  yearfrom: {
    type: "terms",
    field: "yearfrom",
    limit: 100,
    sort: "index desc",
  },
  publisher_name: { type: "terms", field: "publisher_name", limit: 100 },
};

export async function advancedSearch(query: any): Promise<any> {
  try {
    const { data: response } = await http.get(
      `/api/indexing/solr/articlecore2?rows=20&json_facet=${JSON.stringify(
        json_facet
      )}`,
      {
        params: {
          ...query,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("err", error);
  }
}
