import http from "./httpService";
import api from "./endpoints";
export async function composeEmailapi(
  message: string,
  email: string,
  name: string,
  id: any,
  searchTerm: any,
  loginId: number
) {
  let userId = sessionStorage.getItem("user_id") || loginId;
  const result = groupArticlesBySearchTerm(id);
  let obj = JSON.stringify(result);
  // {api}/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=1848&receipient_mail=sri@gmail.com&download_type=3&receipient_name=yeshwanth&remarks=testing&article_details=[{"search_term":"cow","article_id":"700086513"}]&selected_type=2
  try {
    const data = await http.get(
      api.baseApi +
        `/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=${userId}&article_details=${obj}&receipient_mail=${email}&receipient_name=${name}&selected_type=2&remarks=${message}`
    );
    return data;
  } catch (error) {}
}


interface Article {
  search_term: string | string[];
  article_id: string;
}

interface GroupedArticle {
  search_term: string;
  article_id: string;
}

export const groupArticlesBySearchTerm = (articles: Article[]): GroupedArticle[] => {
  // Group articles by searchTerm and concatenate article_ids
  const groupedArticles = articles.reduce((acc: any, curr: Article) => {
    const searchTerm = Array.isArray(curr?.search_term) ? curr?.search_term[0] : curr?.search_term;

    // Initialize or update the accumulator for the searchTerm
    if (!acc[searchTerm]) {
      acc[searchTerm] = {
        search_term: searchTerm,
        article_id: []
      };
    }

    // Concatenate article_id
    acc[searchTerm].article_id.push(curr.article_id);

    return acc;
  }, {});

  // Convert the grouped result back into an array of objects
  return Object.values(groupedArticles).map((item: any) => ({
    search_term: item.search_term,
    article_id: item.article_id.join(",") // Join article_ids with commas
  }));
};

