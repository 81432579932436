import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import { Colors } from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useMutateDeletePreprintAlerts } from "../../../api/MyLibrary/Preprints.api";

interface Props {
  show: boolean;
  alertName: string;
  alertId: number;
}

const PreprintDeleteModal = ({ show, alertName, alertId }: Props) => {
  const dispatch = useAppDispatch();

  const deletePreprint = useMutateDeletePreprintAlerts();

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append("alert_id", alertId.toString());
    deletePreprint.mutate({ formData });
    dispatch(hideModal());
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="PreprintDeleteModal-Title"
      aria-describedby="PreprintDeleteModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "35vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            Delete Alert - {alertName}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Text sx={{}}>
          Are you sure you want to permanently delete this alert?
        </Text>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleDelete}
          >
            Delete Alert
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreprintDeleteModal;
